import Icon from "@ant-design/icons";

const SVGIcon = () => (
  <svg
    width="14"
    height="22"
    viewBox="0 0 14 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.8448 2.88964C6.93059 2.79981 7.06968 2.79981 7.15548 2.88964L9.92035 5.7846C10.0587 5.9295 9.96073 6.17727 9.76501 6.17727H4.23526C4.03954 6.17727 3.94153 5.9295 4.07992 5.7846L6.8448 2.88964Z"
      fill="#AEAFB2"
    />
    <path
      d="M7.15548 19.1099C7.06968 19.1997 6.93059 19.1997 6.8448 19.1099L4.07992 16.2149C3.94153 16.07 4.03954 15.8223 4.23526 15.8223H9.76501C9.96073 15.8223 10.0587 16.07 9.92035 16.2149L7.15548 19.1099Z"
      fill="#AEAFB2"
    />
  </svg>
);

const SortIcon = (props: any) => <Icon component={SVGIcon} {...props} />;

export default SortIcon;
