import { Avatar, Button, Card, Col, Dropdown, Image, Row, Tag } from "antd";
import { Fragment } from "react/jsx-runtime";
import {
  Leaf3,
  Leaf4,
  Leaf6,
  Leaf8,
  Leaf10,
  Leaf12,
  Leaf14,
  Leaf18,
  Leaf24,
} from "../../../../atoms/CustomIcons";
import { RoleType } from "../../../../organisms/MyJob/ducks/types";

type PropsType = {
  data: RoleType[];
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  activeData: RoleType;
  activeNumber: number;
  jobName: string;
  departmentName: string;
};

export default function JobTabs(props: PropsType) {
  const {
    data,
    activeTab,
    setActiveTab,
    activeData,
    activeNumber,
    jobName,
    departmentName,
  } = props;

  const flowerMapGen: {
    [key: number]: {
      class: string;
      petalShape: React.ReactElement;
    };
  } = {
    1: {
      class: "flower_leaf_3",
      petalShape: <Leaf3 />,
    },
    2: {
      class: "flower_leaf_3",
      petalShape: <Leaf3 />,
    },
    3: {
      class: "flower_leaf_3",
      petalShape: <Leaf3 />,
    },
    4: {
      class: "flower_leaf_4",
      petalShape: <Leaf4 />,
    },
    5: {
      class: "flower_leaf_6",
      petalShape: <Leaf6 />,
    },
    6: {
      class: "flower_leaf_6",
      petalShape: <Leaf6 />,
    },
    7: {
      class: "flower_leaf_8",
      petalShape: <Leaf8 />,
    },
    8: {
      class: "flower_leaf_8",
      petalShape: <Leaf8 />,
    },
    9: {
      class: "flower_leaf_10",
      petalShape: <Leaf10 />,
    },
    10: {
      class: "flower_leaf_10",
      petalShape: <Leaf10 />,
    },
    11: {
      class: "flower_leaf_12",
      petalShape: <Leaf12 />,
    },
    12: {
      class: "flower_leaf_12",
      petalShape: <Leaf12 />,
    },
    13: {
      class: "flower_leaf_14",
      petalShape: <Leaf14 />,
    },
    14: {
      class: "flower_leaf_14",
      petalShape: <Leaf14 />,
    },
    15: {
      class: "flower_leaf_18",
      petalShape: <Leaf18 />,
    },
    16: {
      class: "flower_leaf_18",
      petalShape: <Leaf18 />,
    },
    17: {
      class: "flower_leaf_18",
      petalShape: <Leaf18 />,
    },
    18: {
      class: "flower_leaf_18",
      petalShape: <Leaf18 />,
    },
    19: {
      class: "flower_leaf_24",
      petalShape: <Leaf24 />,
    },
    20: {
      class: "flower_leaf_24",
      petalShape: <Leaf24 />,
    },
    21: {
      class: "flower_leaf_24",
      petalShape: <Leaf24 />,
    },
    22: {
      class: "flower_leaf_24",
      petalShape: <Leaf24 />,
    },
    23: {
      class: "flower_leaf_24",
      petalShape: <Leaf24 />,
    },
    24: {
      class: "flower_leaf_24",
      petalShape: <Leaf24 />,
    },
  };

  const items = [
    {
      key: "1",
      label: "Edit",
    },
    {
      key: "2",
      label: "Delete",
    },
  ];

  const createMarkup = (text: string) => {
    return { __html: text };
  };

  return (
    <Row gutter={16}>
      <Col span={12}>
        <div className="flower_wrapper finalizedFlower">
          <div className={`flower ${flowerMapGen[data.length].class}`}>
            {data?.map((e: any, i: number) => (
              <Fragment key={i + 1}>
                <div
                  className={`leaf leaf-${i + 1} ${
                    parseInt(activeTab) === i + 1 && "active"
                  }`}
                  onClick={() => setActiveTab((i + 1).toString())}
                >
                  <div className="petal_svg">
                    {flowerMapGen[data.length].petalShape}
                  </div>
                  <div className="leaf_text">
                    <div className="leaf_detail">
                      <h4>{e?.name}</h4>
                      <div
                        dangerouslySetInnerHTML={createMarkup(
                          e?.purpose_statement as string
                        )}
                        className="leaf_paragraph"
                      />
                    </div>
                    <div className="leaf_number">{i + 1}</div>
                  </div>
                </div>
                <div className="flower_position">{jobName}</div>
              </Fragment>
            ))}
          </div>
        </div>
      </Col>
      <Col span={12}>
        <div className="flower_tab_wrapper small_tags">
          <div className="flower_tab_heading">
            <div className="active_state">
              <h6 className={`tagNumber_${activeNumber}`}> {activeNumber} </h6>
              {activeData?.name}
            </div>

            <div className="ft_edit">
              <Dropdown
                menu={{ items }}
                trigger={["click"]}
                placement="bottomRight"
              >
                <Button>
                  <Image
                    src="/images/vertical_dots.svg"
                    width={3}
                    height={15}
                    alt="vertical dots"
                    preview={false}
                  />
                </Button>
              </Dropdown>
            </div>
          </div>

          <div className="inline_widgets">
            <div className="flower_tab_para">
              <Card className="card_role">
                <p className="role_title">Coached by:</p>
                <div
                  className="role_name"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    Executive coach <img src="/images/visit_icon.svg" />
                  </div>
                  <div>
                    <Avatar.Group
                      className="avatarGroup"
                      size="small"
                      max={{
                        count: 3,
                        style: {
                          color: "#161616",
                          backgroundColor: "#fff",
                          cursor: "pointer",
                          fontSize: "10px",
                          fontWeight: "500",
                        },
                      }}
                    >
                      <Avatar>AR</Avatar>
                      <Avatar src="/images/avatar_1.jpg" />
                      <Avatar>SN</Avatar>
                    </Avatar.Group>
                  </div>
                </div>
              </Card>
            </div>
            <div className="flower_tab_para">
              <Card className="card_role">
                <p className="role_title"> Department </p>
                <div className="role_name department_name">
                  {departmentName ?? "-"}
                </div>
              </Card>
            </div>
          </div>

          <div className="flower_tab_para full_width mb-20">
            <h4>Purpose statement</h4>
            {activeData?.purpose_statement ? (
              <div
                dangerouslySetInnerHTML={createMarkup(
                  activeData?.purpose_statement as string
                )}
              />
            ) : (
              "-"
            )}
          </div>

          <div className="inline_widgets mb-20">
            <div className="flower_tab_para">
              <h4>Objectives</h4>
              {activeData?.objectives ? (
                <div
                  dangerouslySetInnerHTML={createMarkup(
                    activeData?.objectives as string
                  )}
                />
              ) : (
                "-"
              )}
            </div>

            <div className="flower_tab_para">
              <h4>Key results</h4>
              {activeData?.key_results?.length > 0
                ? activeData?.key_results?.map((e, i) => (
                    <Fragment key={i}>
                      <Tag>{e}</Tag>
                    </Fragment>
                  ))
                : "-"}
            </div>
          </div>

          <div className="inline_widgets mb-20">
            <div className="flower_tab_para">
              <h4>Procedures/WIs/Policies</h4>
              {activeData?.procedures ? (
                <div
                  dangerouslySetInnerHTML={createMarkup(
                    activeData?.procedures as string
                  )}
                />
              ) : (
                "-"
              )}
            </div>

            <div className="flower_tab_para">
              <h4>Workflows</h4>
              {activeData?.workflows?.length > 0
                ? activeData?.workflows?.map((e: string, i: number) => (
                    <Fragment key={i}>
                      <Tag>{e}</Tag>
                    </Fragment>
                  ))
                : "-"}
            </div>
          </div>

          <div className="inline_widgets mb-20">
            <div className="flower_tab_para">
              <h4>Skills</h4>
              {activeData?.skills?.length > 0
                ? activeData?.skills?.map((e: string, i: number) => (
                    <Fragment key={i}>
                      <Tag>{e}</Tag>
                    </Fragment>
                  ))
                : "-"}
            </div>

            <div className="flower_tab_para">
              <h4>Technologies</h4>
              {activeData?.technologies?.length > 0
                ? activeData?.technologies?.map((e: string, i: number) => (
                    <Fragment key={i}>
                      <Tag>{e}</Tag>
                    </Fragment>
                  ))
                : "-"}
            </div>
          </div>

          <div className="inline_widgets mb-20">
            <div className="flower_tab_para">
              <h4>Teams</h4>
              {activeData?.teams?.length > 0
                ? activeData?.teams?.map((e: string, i: number) => (
                    <Fragment key={i}>
                      <Tag>{e}</Tag>
                    </Fragment>
                  ))
                : "-"}
            </div>

            <div className="flower_tab_para">
              <h4>Entities</h4>
              {activeData?.entities ? (
                <div
                  dangerouslySetInnerHTML={createMarkup(
                    activeData?.entities as string
                  )}
                />
              ) : (
                "-"
              )}
            </div>
          </div>

          <div className="inline_widgets mb-20">
            <div className="flower_tab_para">
              <h4>Meetings</h4>
              {activeData?.meetings?.length > 0
                ? activeData?.meetings?.map((e: string, i: number) => (
                    <Fragment key={i}>
                      <Tag>{e}</Tag>
                    </Fragment>
                  ))
                : "-"}
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}
