import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const Leaf3 = () => (
  <svg
    width="302"
    height="302"
    viewBox="0 0 302 302"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M270.914 230.894L151.224 300.759L31.197 230.907C-1.00257 212.168 -8.51908 168.921 15.4692 140.416L120.794 15.2606C136.815 -3.77724 166.145 -3.72383 182.097 15.3723L286.714 140.61C310.522 169.11 302.985 212.174 270.914 230.894Z"
      fill="currentColor"
      stroke="#ECEDF3"
      strokeWidth="1.62816"
    />
  </svg>
);

const LeafIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={Leaf3} {...props} />
);

export default LeafIcon;
