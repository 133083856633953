import { Button, Card, Col, Image, Radio, RadioChangeEvent, Row } from "antd";
import {
  updatePetalById,
  updateSessionById,
} from "../../../../../../../organisms/MyJob/ducks/services";
import {
  clearFlowerDataBySessionID,
  getFlowerDataBySessionID,
} from "../../../../../../../organisms/MyJob/ducks/actions";
import { useAppDispatch } from "../../../../../../../config/hooks";
import SessionFlowerFinalized from "../SessionFlowerFinalized";
import { SessionTypeResponse } from "../../../../../../../organisms/MyJob/ducks/types";

type PropsType = {
  data: any;
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  activeData?: any;
  activeNumber: number;
  sessionId: string;
  petalTeams: any;
  sessionData: SessionTypeResponse;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function SelectFpSessionRole(props: PropsType) {
  const {
    data,
    activeTab,
    setActiveTab,
    activeData,
    setCurrent,
    activeNumber,
    petalTeams,
    sessionId,
    sessionData,
    setLoading,
  } = props;
  const dispatch = useAppDispatch();

  const submitSFNames = () => {
    setLoading(true);
    const payloadScreen = {
      last_session_screen: 7,
    };
    updateSessionById(payloadScreen, sessionId).then(() => {
      setLoading(false);
      dispatch(clearFlowerDataBySessionID());
      setCurrent(7);
    });
  };

  const finalizedNames = ({ target: { value } }: RadioChangeEvent) => {
    const payload = {
      finalized_name: value,
    };
    updatePetalById(activeData?.id, payload).then(() => {
      dispatch(getFlowerDataBySessionID(sessionId));
    });
  };
  return (
    <Row>
      <Col span={13}>
        <div className="session_flower finalizedFlower">
          <div
            className="flower_wrapper"
            style={{ height: "calc(100vh - 130px)" }}
          >
            <SessionFlowerFinalized
              data={data}
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              setCurrent={setCurrent}
              activeData={activeData}
              petalTeams={petalTeams}
            />
            <div className="flower_position">{sessionData?.job?.name}</div>
          </div>
        </div>
      </Col>
      <Col span={11}>
        <Card className="session_fp_card fp_session_role session_inner_tab">
          <h4> Select the best role names </h4>
          <p>
            With the entire group, present and debate each role name and select
            the one you agree is the best.
          </p>
          <div className="content_section">
            <div className="active_state" style={{ marginBottom: "16px" }}>
              <h6 className={`tagNumber_${activeNumber}`}> {activeNumber} </h6>
              Role
            </div>
            {petalTeams?.map((e: any) => (
              <div className="inputRadio">
                <Radio.Group
                  name="radiogroup"
                  onChange={finalizedNames}
                  defaultValue={activeData?.finalized_name}
                >
                  <div className="finalized_div">
                    <label className="label"> Serious name: </label>
                    <Radio
                      value={
                        e?.contributions.find(
                          (item1: any) => activeData?.id === item1.petal_id
                        )?.serious_name
                      }
                    >
                      <div className="radio_uncheck">
                        <Image
                          src="/images/session/unchecked.svg"
                          preview={false}
                          width={20}
                          height={20}
                        />
                      </div>
                      <div className="radio_check">
                        <Image
                          src="/images/session/checked-filled.svg"
                          preview={false}
                          width={20}
                          height={20}
                        />
                      </div>
                      {
                        e?.contributions.find(
                          (item1: any) => activeData?.id === item1.petal_id
                        )?.serious_name
                      }
                    </Radio>
                  </div>

                  <div className="finalized_div">
                    <label className="label"> Funny name: </label>
                    <Radio
                      value={
                        e?.contributions.find(
                          (item1: any) => activeData?.id === item1.petal_id
                        )?.funny_name
                      }
                    >
                      <div className="radio_uncheck">
                        <Image
                          src="/images/session/unchecked.svg"
                          preview={false}
                          width={20}
                          height={20}
                        />
                      </div>
                      <div className="radio_check">
                        <Image
                          src="/images/session/checked-filled.svg"
                          preview={false}
                          width={20}
                          height={20}
                        />
                      </div>
                      {
                        e?.contributions.find(
                          (item1: any) => activeData?.id === item1.petal_id
                        )?.funny_name
                      }
                    </Radio>
                  </div>
                </Radio.Group>
              </div>
            ))}

            <div className="role_description">
              <h4> Role descriptors: </h4>
              <ul>
                {activeData?.descriptors?.map((e: { descriptor: string }) => (
                  <li>{e?.descriptor}</li>
                ))}
              </ul>
            </div>
          </div>

          <div className="move_buttons">
            <Button
              htmlType="button"
              className="icon_grey_btn"
              type="primary"
              size="large"
              disabled={activeTab === "1"}
              onClick={() => {
                setActiveTab((+activeTab - 1).toString());
              }}
            >
              Previous Role
            </Button>
            <Button
              htmlType="button"
              className="icon_grey_btn"
              type="primary"
              size="large"
              disabled={
                +activeTab === data?.length || !activeData?.finalized_name
              }
              onClick={() => {
                setActiveTab((+activeTab + 1).toString());
              }}
            >
              Next Role
            </Button>
          </div>
          <div className="steps_footer">
            <div className="steps_btn_wrapper">
              <Button
                htmlType="button"
                className="icon_grey_btn"
                type="primary"
                size="large"
                onClick={() => setCurrent(5)}
              >
                Back
              </Button>
              <Button
                htmlType="button"
                className="icon_dark_btn"
                type="primary"
                size="large"
                onClick={submitSFNames}
                disabled={
                  (+activeTab !== data?.length ||
                    !activeData?.finalized_name) &&
                  sessionData?.last_session_screen < 7
                }
              >
                Continue
              </Button>
            </div>
          </div>
        </Card>
      </Col>
    </Row>
  );
}
