import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const Leaf12 = () => (
  <svg
    width="124"
    height="316"
    viewBox="0 0 124 316"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.62121 92.3796L62.0157 313.854L121.387 92.2828C126.251 74.1319 121.346 54.7575 108.432 41.1074L76.7292 7.59853C68.7182 -0.868868 55.2357 -0.858416 47.2379 7.62138L15.5164 41.2543C2.6409 54.9056 -2.23946 74.2547 2.62121 92.3796Z"
      fill="currentColor"
      stroke="#ECEDF3"
    />
  </svg>
);

const LeafIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={Leaf12} {...props} />
);

export default LeafIcon;
