import axios from "axios";
// import store from "@/redux/store";
// import { logout } from "@/views/Login/ducks/actions";
import { BASE_URLS, ENVIRONMENT_MODE } from "./constants";
import store from "./store";
import { getUserDetails } from "../organisms/MyAccount/ducks/actions";

const baseUrl = BASE_URLS[ENVIRONMENT_MODE].REACT_APP_API_BASE_URL;

const authInterceptors = axios.create({
  baseURL: baseUrl,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept",
    "Content-Type": "application/json",
  },
});

authInterceptors.interceptors.request.use(
  (config) => {
    // if (controller) {
    //   controller.abort();
    // }
    // controller = new AbortController();
    // config.signal = controller.signal;

    const jsonToken = localStorage.getItem("@fpToken");
    if (jsonToken) {
      const parsedToken: { refreshToken: string; accessToken: string } =
        JSON.parse(jsonToken);

      // const refreshToken = token?.refreshToken
      // if (token) {
      config.headers.Authorization = `Bearer ${parsedToken.accessToken}`;
      config.headers.Accept = "application/x-www-form-urlencoded;charset=UTF-8";
      // }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

authInterceptors.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    // eslint-disable-next-line no-underscore-dangle
    console.log("error.code", error.code);

    // if (axios.isCancel(error) || error.code === "ERR_CANCELED") {
    //   console.log("Request canceled:", error.message);
    //   return Promise.resolve(); // Suppress the cancellation error
    // }

    if (error?.response?.status === 401 && !originalRequest._dretry) {
      // eslint-disable-next-line no-underscore-dangle
      originalRequest._retry = true;
      const jsonToken = localStorage.getItem("@fpToken");

      if (jsonToken) {
        const parsedToken: { refreshToken: string; accessToken: string } =
          JSON.parse(jsonToken);

        const postData = {
          refreshToken: parsedToken.refreshToken,
        };
        authInterceptors
          .post(`${baseUrl}refresh`, postData, {
            headers: {
              "content-Type": "application/json",
            },
          })
          .then(async (res) => {
            if (res && res.data && res.data.accessToken) {
              store.dispatch(getUserDetails());
              const val = {
                accessToken: res.data.accessToken,

                refreshToken: parsedToken.refreshToken,
              };
              localStorage.setItem("@fpToken", JSON.stringify(val));
              originalRequest.headers.Authorization = `Bearer ${
                JSON.parse(localStorage.getItem("@fpToken") as string)
                  .accessToken
              }`;
              return axios(originalRequest);
            }
            return undefined;
          })
          .catch(() => {
            localStorage.clear();
            setTimeout(() => {
              window.location.assign("/login");
            }, 1500);
          });
      }
    } else if (error?.response?.status === 401) {
      // onLogout();
      // store.dispatch(setSignedIn(false));
      localStorage.clear();
      setTimeout(() => {
        window.location.assign("/login");
      }, 1500);
    } else if (error?.response?.status === 403) {
      localStorage.clear();
      setTimeout(() => {
        window.location.assign("/login");
      }, 1500);
    } else if (error?.response?.status === 502) {
      localStorage.clear();
      setTimeout(() => {
        window.location.assign("/login");
      }, 1500);
    }

    // if (error?.response && error?.response?.status === 401) {
    //   localStorage.clear()
    //   setTimeout(() => {
    //     window.location.assign('/')
    //   }, 500)
    // }
    return Promise.reject(error);
  }
);
export default authInterceptors;
