import { BASE_URLS, ENVIRONMENT_MODE } from "../../../config/constants";
import axios from "../../../config/axiosInterceptor";
import {
  AddPetalsBySessionIdSchema,
  AddRoleDescriptorSchema,
  AddRoleDescriptorsSchema,
  createPetalTeamsBySessionIdSchema,
  CreateSessionType,
  SessionPetalsSchema,
  UpdateRoleDescriptorSchema,
  UpdateSessionSchema,
} from "./types";
import { Id } from "../../../molecules/KanbanBoard/types";

const baseURL = BASE_URLS[ENVIRONMENT_MODE].REACT_APP_API_BASE_URL;

export const createSession = (payload: CreateSessionType) => {
  return axios.post(`${baseURL}createSession`, payload);
};

export const addRoleDescriptorsInBulk = (payload: AddRoleDescriptorsSchema) => {
  return axios.post(`${baseURL}addRoleDescriptorsInBulk`, payload);
};

export const addRoleDescriptor = (payload: AddRoleDescriptorSchema) => {
  return axios.post(`${baseURL}addRoleDescriptor`, payload);
};

export const updateRoleDescriptor = (payload: UpdateRoleDescriptorSchema) => {
  return axios.patch(`${baseURL}updateRoleDescriptor`, payload);
};

export const updateSessionById = (
  payload: UpdateSessionSchema,
  sessionId: string
) => {
  return axios.patch(`${baseURL}updateSessionById/${sessionId}`, payload);
};

export const updatePetalInfoByTeamId = (teamId: string, payload: any) => {
  return axios.patch(`${baseURL}updatePetalInfoByTeamId/${teamId}`, payload);
};

export const updatePetalById = (
  petalId: string,
  payload: SessionPetalsSchema
) => {
  return axios.patch(`${baseURL}updatePetalById/${petalId}`, payload);
};

export const completeSessionById = (sessionId: string) => {
  return axios.patch(`${baseURL}completeSessionById/${sessionId}`);
};

export const addPetalsBySessionId = (payload: AddPetalsBySessionIdSchema) => {
  return axios.post(`${baseURL}addPetalsBySessionId`, payload);
};

export const createPetalTeamsBySessionId = (
  payload: createPetalTeamsBySessionIdSchema
) => {
  return axios.post(`${baseURL}createPetalTeamsBySessionId`, payload);
};

export const deleteRoleDescriptorById = (id: Id) => {
  return axios.delete(`${baseURL}deleteRoleDescriptorById/${id}`);
};
