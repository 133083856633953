import { Card, Button, Row, Col, Alert, Avatar } from "antd";
import { SessionTypeResponse } from "../../../../../../organisms/MyJob/ducks/types";
import { updateSessionById } from "../../../../../../organisms/MyJob/ducks/services";
import { getSessionById } from "../../../../../../organisms/MyJob/ducks/actions";
import { useAppDispatch } from "../../../../../../config/hooks";
import InputField from "../../../../../../molecules/FormItems/InputField";
import { useForm } from "react-hook-form";

const { Meta } = Card;

type PropsType = {
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  sessionData: SessionTypeResponse;
  sessionId: string;
};

export default function Step2(props: PropsType) {
  const { setCurrent, sessionData, sessionId } = props;
  const dispatch = useAppDispatch();
  const { control } = useForm();
  const changeStatus = () => {
    const payload = {
      last_session_screen: 1.5,
    };
    updateSessionById(payload, sessionId).then(() => {
      setCurrent(1);
      dispatch(getSessionById(sessionId));
    });
  };

  console.log("sessionData", sessionData);
  const updateLastScreen = () => {
    const payload = {
      last_session_screen: 2,
    };
    updateSessionById(payload, sessionId).then(() => {
      setCurrent(2);
      dispatch(getSessionById(sessionId));
    });
  };
  return (
    <Row>
      <Col span={12}>
        <Card className="fp_layout_content link_card">
          <div className="copy_link_content">
            <div className="session2_content">
              <h2>
                Generate the list <span>*</span>
              </h2>
              <p>Share the link below with each participant.</p>
              <p>
                Ask them to take 2 to 3 quiet minutes to create a list. Ask them
                to write down everything that{" "}
                <b>
                  {`${sessionData?.facilitator?.first_name ?? ""} ${
                    sessionData?.facilitator?.last_name ?? ""
                  }`}{" "}
                </b>
                is thinking about or doing, or should be thinking about or doing
                every second, minute, hour, day, week, month, year to fulfill
                the objective of their Job.
              </p>
              <div style={{ position: "relative", marginTop: 24 }}>
                <InputField
                  fieldName="url"
                  label=""
                  control={control}
                  initValue={`https://flowerpower.darkhorsesolutions.co/fp-session/generate-list/${sessionId}`}
                  iProps={{
                    placeholder: `https://flowerpower.darkhorsesolutions.co/fp-session/generate-list/${sessionId}`,
                  }}
                />
                <Button className="copyLinkBtn">
                  <img src="/images/attachment_icon.svg" alt="" /> Copy link
                </Button>
              </div>
            </div>

            <Alert
              message="Wait until everyone submits their list. Then, click Continue."
              // icon={<SuccessIcon />}
              type="warning"
              showIcon
            />
          </div>
          <div className="steps_footer">
            <div className="steps_btn_wrapper">
              <Button
                htmlType="button"
                className="icon_grey_btn"
                type="primary"
                size="large"
                onClick={() => setCurrent(0)}
              >
                Back
              </Button>
              <Button
                htmlType="button"
                className="icon_dark_btn"
                type="primary"
                size="large"
                onClick={updateLastScreen}
                disabled={sessionData?.last_session_screen < 1.5}
              >
                Continue
              </Button>
            </div>
          </div>
        </Card>
      </Col>
      <Col span={12}>
        <div className="status_card_step2">
          {sessionData?.last_session_screen > 1 ? (
            <Card className="status_card">
              <Meta
                avatar={
                  <Avatar src="/images/session/submitted-icon.svg" size={26} />
                }
                title="Status: Submitted"
                description="Click to update"
              />
            </Card>
          ) : (
            <Button onClick={changeStatus}>
              <Card className="status_card">
                <Meta
                  avatar={
                    <Avatar src="/images/session/pending-icon.svg" size={26} />
                  }
                  title="Status: Pending"
                  description="Click to update"
                />
              </Card>
            </Button>
          )}
        </div>

        <div className="step2_role">
          <img src="/images/session/step2-role.svg" alt="" />
        </div>
      </Col>
    </Row>
  );
}
