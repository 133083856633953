import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const Leaf8 = () => (
  <svg
    width="201"
    height="324"
    viewBox="0 0 201 324"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.9131 124.975L100.446 321.625L185.45 116.31C194.299 94.9347 187.994 70.2843 169.968 55.7835L126.655 20.9404C111.178 8.49003 89.1254 8.47514 73.6316 20.9046L38.3209 49.2314C15.7291 67.3549 7.82048 98.2202 18.9131 124.975Z"
      fill="currentColor"
      stroke="#ECEDF3"
      strokeWidth="1.62816"
    />
  </svg>
);

const LeafIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={Leaf8} {...props} />
);

export default LeafIcon;
