import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const EditSvg = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.00503 14.25H4.89262C5.87415 14.25 6.7802 13.8775 7.45975 13.207L13.5 7.0977L12.443 6.05465L10.4798 4.04305L9.87585 3.52152L9.3473 3L3.30704 9.10925C2.62751 9.7798 2.25 10.6739 2.25 11.6424V13.5049C2.25 13.952 2.55201 14.25 3.00503 14.25Z"
      fill="#070707"
    />
    <path
      d="M11.5173 3.09121L13.4066 5.00675L14.4239 6L14.9326 5.5034C16.0225 4.43919 16.0225 2.66554 14.9326 1.60135C14.4239 1.03378 13.6972 0.75 12.9706 0.75C12.2439 0.75 11.5173 1.03379 11.0086 1.5304L10.5 2.02702L11.0086 2.52365L11.5173 3.09121Z"
      fill="#070707"
    />
    <path
      d="M15.75 15.75H2.25C1.8 15.75 1.5 16.05 1.5 16.5C1.5 16.95 1.8 17.25 2.25 17.25H15.75C16.2 17.25 16.5 16.95 16.5 16.5C16.5 16.05 16.2 15.75 15.75 15.75Z"
      fill="#070707"
    />
  </svg>
);

const EditIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={EditSvg} {...props} />
);

export default EditIcon;
