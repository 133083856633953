import { Fragment, useEffect, useState } from "react";
import { Button, Card, Col, Image, Radio, RadioChangeEvent, Row } from "antd";
import { SessionTypeResponse } from "../../../../../../organisms/MyJob/ducks/types";
import FpSessionReady from "../Components/FpSessionReady";
import { useAppDispatch, useAppSelector } from "../../../../../../config/hooks";
import {
  createPetalTeamsBySessionIdD,
  getFlowerDataBySessionID,
} from "../../../../../../organisms/MyJob/ducks/actions";
import { updateSessionById } from "../../../../../../organisms/MyJob/ducks/services";
// import { createPetalTeamsBySessionId } from "../../../../../../organisms/MyJob/ducks/services";

type PropsType = {
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  sessionData: SessionTypeResponse;
  sessionId: string;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function Step5(props: PropsType) {
  const { setCurrent, sessionId, sessionData, setLoading } = props;
  const [divideData, setDivideData] = useState(null);
  const dispatch = useAppDispatch();
  const flowerData = useAppSelector(
    (state) => state?.jobDetail?.sessionFlowerData
  );

  useEffect(() => {
    if (sessionId) {
      dispatch(getFlowerDataBySessionID(sessionId));
    }
  }, [sessionId]);

  const radioData = [
    {
      label: "Don’t split",
      value: "1",
    },
    {
      label: "2 teams",
      value: "2",
    },
    {
      label: "3 teams",
      value: "3",
    },
  ];

  const onChange = (e: RadioChangeEvent) => {
    setDivideData(e.target.value);
  };

  const makeTeams = () => {
    setLoading(true);
    const payload = {
      session_id: sessionId,
      teamNumber: [
        {
          name: "team1",
        },
      ],
    };

    dispatch(createPetalTeamsBySessionIdD(payload));
    setTimeout(() => {
      const payloadScreen = {
        last_session_screen: 5,
      };
      updateSessionById(payloadScreen, sessionId).then(() => {
        setLoading(false);
        setCurrent(5);
      });
    }, 1000);
  };

  return (
    <Row>
      <Col span={13}>
        <div className="session_flower finalizedFlower">
          <div
            className="flower_wrapper"
            style={{ height: "calc(100vh - 130px)" }}
          >
            {flowerData?.sessionPetals && (
              <>
                <FpSessionReady
                  data={flowerData?.sessionPetals}
                  setCurrent={setCurrent}
                  activeTab="1"
                />
                <div className="flower_position">{sessionData?.job?.name}</div>
              </>
            )}
          </div>
        </div>
      </Col>
      <Col span={11}>
        <Card
          className="session_fp_card fp_session_role"
          style={{ paddingTop: 120 }}
        >
          <div className="centered_div">
            <h4>
              Divide participants into teams
              <span style={{ color: "#8558EA", fontSize: "20px" }}>*</span>
            </h4>
            <p style={{ marginBottom: "10px" }}>
              Each team will need to come up with a serious and funny name for
              each role.
            </p>
            <p style={{ marginBottom: "16px" }}>
              Then, the entire group debate each role name and select the one
              you agree is the best. Yes, it is like a competition, meant to be
              fun.
            </p>
            <div className="participants_card">
              <Radio.Group buttonStyle="solid" onChange={onChange}>
                {radioData?.map((e: { label: string; value: string }) => (
                  <Fragment key={e?.value}>
                    <Radio.Button value={e?.value}>
                      <div className="participant_info">
                        <h6>{e?.label}</h6>
                      </div>
                      <div className="participant_check">
                        <Image
                          src="/images/session/checked-icon.svg"
                          preview={false}
                          width={18}
                          height={18}
                        />
                      </div>
                    </Radio.Button>
                  </Fragment>
                ))}
              </Radio.Group>
            </div>
          </div>
          <div className="steps_footer">
            <div className="steps_btn_wrapper">
              <Button
                htmlType="button"
                className="icon_grey_btn"
                type="primary"
                size="large"
                onClick={() => setCurrent(3)}
              >
                Back
              </Button>
              <Button
                htmlType="button"
                className="icon_dark_btn"
                type="primary"
                size="large"
                onClick={makeTeams}
                disabled={!divideData}
              >
                Continue
              </Button>
            </div>
          </div>
        </Card>
      </Col>
    </Row>
  );
}
