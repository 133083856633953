import { Fragment } from "react/jsx-runtime";
import {
  Leaf3,
  Leaf4,
  Leaf6,
  Leaf8,
  Leaf10,
  Leaf12,
  Leaf14,
  Leaf18,
  Leaf24,
} from "../../../../../../../atoms/CustomIcons";
import { RoleType } from "../../../../../../../organisms/MyJob/ducks/types";

type PropsType = {
  data: RoleType[];
  activeTab?: string;
  setActiveTab?: React.Dispatch<React.SetStateAction<string>>;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  activeData?: RoleType;
  petalTeams: any;
};

export default function SessionFlower(props: PropsType) {
  const { data, activeTab, setActiveTab, petalTeams } = props;

  const flowerMapGen: {
    [key: number]: {
      class: string;
      petalShape: React.ReactElement;
    };
  } = {
    1: {
      class: "flower_leaf_3",
      petalShape: <Leaf3 />,
    },
    2: {
      class: "flower_leaf_3",
      petalShape: <Leaf3 />,
    },
    3: {
      class: "flower_leaf_3",
      petalShape: <Leaf3 />,
    },
    4: {
      class: "flower_leaf_4",
      petalShape: <Leaf4 />,
    },
    5: {
      class: "flower_leaf_6",
      petalShape: <Leaf6 />,
    },
    6: {
      class: "flower_leaf_6",
      petalShape: <Leaf6 />,
    },
    7: {
      class: "flower_leaf_8",
      petalShape: <Leaf8 />,
    },
    8: {
      class: "flower_leaf_8",
      petalShape: <Leaf8 />,
    },
    9: {
      class: "flower_leaf_10",
      petalShape: <Leaf10 />,
    },
    10: {
      class: "flower_leaf_10",
      petalShape: <Leaf10 />,
    },
    11: {
      class: "flower_leaf_12",
      petalShape: <Leaf12 />,
    },
    12: {
      class: "flower_leaf_12",
      petalShape: <Leaf12 />,
    },
    13: {
      class: "flower_leaf_14",
      petalShape: <Leaf14 />,
    },
    14: {
      class: "flower_leaf_14",
      petalShape: <Leaf14 />,
    },
    15: {
      class: "flower_leaf_18",
      petalShape: <Leaf18 />,
    },
    16: {
      class: "flower_leaf_18",
      petalShape: <Leaf18 />,
    },
    17: {
      class: "flower_leaf_18",
      petalShape: <Leaf18 />,
    },
    18: {
      class: "flower_leaf_18",
      petalShape: <Leaf18 />,
    },
    19: {
      class: "flower_leaf_24",
      petalShape: <Leaf24 />,
    },
    20: {
      class: "flower_leaf_24",
      petalShape: <Leaf24 />,
    },
    21: {
      class: "flower_leaf_24",
      petalShape: <Leaf24 />,
    },
    22: {
      class: "flower_leaf_24",
      petalShape: <Leaf24 />,
    },
    23: {
      class: "flower_leaf_24",
      petalShape: <Leaf24 />,
    },
    24: {
      class: "flower_leaf_24",
      petalShape: <Leaf24 />,
    },
  };

  console.log("petalTeams", petalTeams);

  const createMarkup = (text: string) => {
    return { __html: text };
  };

  return (
    <div className={`flower flowerReady ${flowerMapGen[data.length].class}`}>
      {data?.map((e: any, i: number) => (
        <Fragment key={i + 1}>
          <div
            className={`leaf leaf-${i + 1} ${
              activeTab ? parseInt(activeTab) === i + 1 && "active" : ""
            }`}
            onClick={() =>
              setActiveTab ? setActiveTab((i + 1).toString()) : null
            }
          >
            <div className="petal_svg">
              {flowerMapGen[data.length].petalShape}
            </div>
            <div className="leaf_text">
              <div className="leaf_detail">
                <h4>
                  {petalTeams?.length > 0 &&
                  petalTeams[0]?.contributions?.length > 0
                    ? `S: ${
                        petalTeams[0]?.contributions?.find(
                          (item1: any) => e.id === item1.petal_id
                        )?.serious_name
                      }`
                    : e?.serious_name
                    ? `S: ${e?.serious_name ?? ""}`
                    : "Role names..."}
                </h4>
                <h4>
                  {petalTeams?.length > 0 &&
                  petalTeams[0]?.contributions?.length > 0
                    ? `F: ${
                        petalTeams[0]?.contributions?.find(
                          (item1: any) => e.id === item1.petal_id
                        )?.funny_name
                      }`
                    : e?.funny_name
                    ? `F: ${e?.funny_name}`
                    : ""}
                </h4>
                {e?.purpose_statement && (
                  <div
                    dangerouslySetInnerHTML={createMarkup(
                      e?.purpose_statement as string
                    )}
                    className="leaf_paragraph"
                  />
                )}
              </div>
              <div className="leaf_number">{i + 1}</div>
            </div>
          </div>
        </Fragment>
      ))}
    </div>
  );
}
