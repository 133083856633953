import { Action, Dispatch } from "redux";
import axios from "../../../config/axiosInterceptor";
import { BASE_URLS, ENVIRONMENT_MODE } from "../../../config/constants";
import * as action_types from "./constants";

const baseURL = BASE_URLS[ENVIRONMENT_MODE].REACT_APP_API_BASE_URL;

export const getUserDetails = () => {
  return async (dispatch: Dispatch<Action>) => {
    const { data } = await axios.get(`${baseURL}getUserDetails`);
    dispatch({
      type: action_types.ACCOUNT_DATA,
      data,
    });
  };
};
