import { Fragment, useEffect, useState } from "react";
import { Card, Button, Avatar, Image, Radio, RadioChangeEvent } from "antd";
import { useLocation } from "react-router-dom";
import {
  Participants,
  SessionTypeResponse,
} from "../../../../../../organisms/MyJob/ducks/types";
import { getInitials } from "../../../../../../config/constants";
import { updateSessionById } from "../../../../../../organisms/MyJob/ducks/services";

type PropsType = {
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  sessionData: SessionTypeResponse;
};

export default function Step1(props: PropsType) {
  const { setCurrent, setLoading, sessionData } = props;
  const [participantId, setParticipantId] = useState("");
  const url = useLocation();
  const sessionId = url?.pathname.split("/")[2];

  const onChange = (e: RadioChangeEvent) => {
    setParticipantId(e.target.value);
  };

  const participantSelected = () => {
    setLoading(true);
    const payload = {
      center_person: participantId,
      last_session_screen: 1,
    };
    updateSessionById(payload, sessionId).then(() => {
      setLoading(false);
      setCurrent(1);
    });
  };

  useEffect(() => {
    if (sessionData?.center_person) {
      setParticipantId(sessionData?.center_person);
    }
  }, [sessionData]);

  const selectRandom = () => {
    setParticipantId(sessionData?.participants[0]?.id);
  };

  return (
    <Card className="fp_layout_content session_card">
      <div className="cardContent">
        <div className="select_individual_head">
          <div className="individual_left">
            <h4>
              Select one individual from the participants <span>*</span>
            </h4>
            <p>Decide who you put in the center of the flower.</p>
          </div>
          <div className="individual_right">
            <Button className="icon_grey_btn" onClick={selectRandom}>
              <Image src="/images/session/magic-icon.svg" preview={false} />
              Select randomly
            </Button>
          </div>
        </div>

        <div className="participants_card">
          <Radio.Group
            buttonStyle="solid"
            onChange={onChange}
            value={participantId}
          >
            {sessionData?.participants?.map((e: Participants) => (
              <Fragment key={e?.id}>
                <Radio.Button value={e?.id}>
                  {e?.image ? (
                    <div className="participant_avatar">
                      <Avatar src={e?.image?.url} />
                    </div>
                  ) : (
                    <div className="participant_avatar">
                      <Avatar>
                        {getInitials(e?.first_name, e?.last_name)}
                      </Avatar>
                    </div>
                  )}
                  <div className="participant_info">
                    <h6>{`${e?.first_name ?? ""} ${e?.last_name ?? ""}`}</h6>
                    <p>{e?.job?.name}</p>
                  </div>
                  <div className="participant_check">
                    <Image
                      src="/images/session/checked-icon.svg"
                      preview={false}
                      width={18}
                      height={18}
                    />
                  </div>
                </Radio.Button>
              </Fragment>
            ))}
          </Radio.Group>
          <Button
            htmlType="button"
            className="icon_dark_btn"
            type="primary"
            size="large"
            onClick={() => participantSelected()}
            disabled={!participantId}
          >
            Continue
          </Button>
        </div>
      </div>
    </Card>
  );
}
