import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  Row,
  Col,
  Table,
  TableColumnsType,
  Button,
  Select,
  Image,
  Avatar,
  Tag,
  Dropdown,
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardTemplate from "../../../templates/Dashboard";
import { DropdownIcon, SortIcon } from "../../../atoms/CustomIcons";
import EmptyCard from "../../../molecules/EmptyCard";
import { useAppSelector, useAppDispatch } from "../../../config/hooks";
import { Popup } from "../../../atoms/Popup";
import ConfirmDelete from "../../../atoms/ConfirmDelete";
import { getRolesFilters, getRolesListing } from "../ducks/actions";
import { getInitials } from "../../../config/constants";
import { deleteRole } from "../ducks/services";
import { AxiosError } from "axios";
import ErrorMessage from "../../../atoms/Toasts/ErrorMessage";
import Header from "../Components/Header";
import CompanyLinks from "../Components/CompanyLinks";

interface DataType {
  key: React.Key;
  id: string;
  name: string;
  jobs: string[];
  coaches: { id: string; name: string };
  holders: string;
}

export default function Roles() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [pagination, setPagination] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [tableRecord, setTableRecord] = useState<DataType>();
  const url = useLocation();
  const companyId = url?.pathname.split("/")[3];
  const urlType = url?.pathname.split("/")[2];
  const rolesFiltersOptions = useAppSelector(
    (state) => state?.companyDetails?.rolesFiltersOptions
  );
  const rolesListData = useAppSelector(
    (state) => state?.companyDetails?.rolesList
  );

  const items = [
    {
      key: "1",
      label: (
        <Button
          className="noBorder"
          style={{ padding: "0px" }}
          onClick={() => navigate(`/edit-role/${tableRecord?.id}/${companyId}`)}
        >
          Edit
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Button
          onClick={() => setIsDeleteModalOpen(true)}
          className="noBorder"
          style={{ padding: "0px" }}
        >
          Delete
        </Button>
      ),
    },
  ];

  const columns: TableColumnsType<DataType> = [
    {
      title: "Role Name",
      dataIndex: "name",
      sorter: (a, b) => a?.name?.localeCompare(b?.name),
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Jobs",
      dataIndex: "jobs",
      // sorter: (a, b) => a?.jobs?.localeCompare(b?.jobs),
      sortIcon: () => <SortIcon />,
      render: (text) =>
        text?.length > 0
          ? text?.map((e: { id: string; name: string }) => (
              <Tag
                className="defaultTag white_tag"
                style={{ marginRight: "2px", marginLeft: "2px" }}
                key={e?.id}
              >
                {e?.name}
              </Tag>
            ))
          : "-",
    },
    {
      title: "Coached by",
      dataIndex: "coaches",
      sortIcon: () => <SortIcon />,
      render: (text) => (text?.name ? text?.name : "-"),
    },
    {
      title: "Holders",
      dataIndex: "jobs",
      sortIcon: () => <SortIcon />,
      render: (text) =>
        text?.length > 0 && (
          <Avatar.Group
            className="avatarGroup"
            size="small"
            max={{
              count: 2,
              style: {
                color: "#161616",
                backgroundColor: "#fff",
                cursor: "pointer",
                fontSize: "10px",
                fontWeight: "500",
              },
            }}
          >
            {text?.map(
              (e: {
                employees: {
                  id: string;
                  first_name: string;
                  last_name: string;
                  image: { url: string };
                }[];
              }) =>
                e?.employees?.length > 0 &&
                e?.employees?.map(
                  (emp: {
                    id: string;
                    first_name: string;
                    last_name: string;
                    image: { url: string };
                  }) => (
                    <Fragment key={emp?.id}>
                      {emp?.image ? (
                        <Avatar src={emp?.image?.url} />
                      ) : (
                        <Avatar>
                          {getInitials(emp?.first_name, emp?.last_name)}
                        </Avatar>
                      )}
                    </Fragment>
                  )
                )
            )}
          </Avatar.Group>
        ),
    },
    {
      title: "",
      dataIndex: "action",
      width: 52,
      render: (_text, record) => (
        <Dropdown
          menu={{ items }}
          trigger={["click"]}
          placement="bottomRight"
          className="actionButton"
        >
          <Button onClick={() => setTableRecord(record)}>
            <Image
              src="/images/vertical_dots.svg"
              width={3}
              height={15}
              alt="vertical dots"
              preview={false}
            />
          </Button>
        </Dropdown>
      ),
    },
  ];

  const handleDeleteJob = async () => {
    setDeleteLoading(true);
    deleteRole(tableRecord?.id as string)
      .then(() => {
        const payload = {
          offset: pagination > 1 ? limit * (pagination - 1) : pagination - 1,
          limit: limit,
          companyId: companyId,
        };
        dispatch(getRolesListing(payload));
        setIsDeleteModalOpen(false);
        setDeleteLoading(false);
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setDeleteLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setDeleteLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  const deleteConfirmationPopup = {
    visibility: isDeleteModalOpen,
    class: "delete_confirmation_modal",
    content: (
      <ConfirmDelete
        title="Confirm Deletion"
        heading="Are you sure you want to delete this role?"
        onClose={() => setIsDeleteModalOpen(false)}
        btnText="Delete role"
        btnApi={handleDeleteJob}
        loading={deleteLoading}
      />
    ),
    width: 520,
    onCancel: () => setIsDeleteModalOpen(false),
  };

  useEffect(() => {
    if (companyId) {
      const payload = {
        offset: pagination > 1 ? limit * (pagination - 1) : pagination - 1,
        limit: limit,
        companyId: companyId,
        status: undefined,
        roles: undefined,
        departments: undefined,
        employees: undefined,
      };
      dispatch(getRolesListing(payload));
    }
  }, [pagination, limit, dispatch, companyId]);

  useEffect(() => {
    if (companyId) {
      dispatch(getRolesFilters(companyId));
    }
  }, [dispatch, companyId]);

  const onPageSize = useCallback((currentPage: number, pageSize: number) => {
    setPagination(currentPage);
    setLimit(pageSize);
  }, []);

  const onPageChange = useCallback((pageNumber: number, pageSize: number) => {
    setPagination(pageNumber);
    setLimit(pageSize);
  }, []);

  return (
    <DashboardTemplate loading={rolesListData?.loading}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Header companyId={companyId} />
        </Col>
        <Col span={24} className="invoice_filter">
          <Row
            gutter={[16, 16]}
            justify="space-between"
            className="filter_btns"
          >
            <Col span={24}>
              <div className="top_filter">
                <CompanyLinks companyId={companyId} urlType={urlType} />
                <div className="filter_right">
                  <div
                    className="filter_btn"
                    style={{ display: "flex", gap: "6px" }}
                  >
                    <span className="filterTitle"> Filter: </span>

                    <Select
                      placeholder="Job"
                      suffixIcon={<DropdownIcon />}
                      popupMatchSelectWidth={false}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      maxTagCount="responsive"
                      options={rolesFiltersOptions?.job}
                    />
                    <Select
                      placeholder="Coached by"
                      suffixIcon={<DropdownIcon />}
                      popupMatchSelectWidth={false}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      maxTagCount="responsive"
                      options={rolesFiltersOptions?.coach}
                    />
                    <Select
                      placeholder="Holder"
                      suffixIcon={<DropdownIcon />}
                      popupMatchSelectWidth={false}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      maxTagCount="responsive"
                      options={rolesFiltersOptions?.employee}
                    />
                    <Image src="/images/filter_btn.svg" preview={false} />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          {rolesListData?.data?.length > 0 && (
            <Table
              className="dashboard_table"
              columns={columns}
              dataSource={rolesListData?.data}
              pagination={{
                defaultPageSize: limit,
                total: rolesListData?.count,
                current: pagination,
                onChange: onPageChange,
                onShowSizeChange: onPageSize,
              }}
              rowKey="id"
            />
          )}

          {rolesListData?.data?.length === 0 && (
            <EmptyCard
              image="/images/empty/no-results.svg"
              title="There are no roles"
              description='Click the "Create roles" button to add a new roles.'
              buttonText="Create roles"
              navigateTo={`/create-role/${companyId}`}
            />
          )}
        </Col>
      </Row>

      <Popup {...deleteConfirmationPopup} />
    </DashboardTemplate>
  );
}
