import { Row, Col, Button, Card, Form } from "antd";
import { useForm } from "react-hook-form";
import UploadPhoto from "../../../molecules/FormItems/UploadPhoto";
import InputField from "../../../molecules/FormItems/InputField";
import { useState } from "react";
import { uploadUserImage } from "../../../organisms/MyAccount/ducks/services";
import { useAppDispatch } from "../../../config/hooks";
import { getUserDetails } from "../../../organisms/MyAccount/ducks/actions";
import ErrorMessage from "../../../atoms/Toasts/ErrorMessage";
import SuccessMessage from "../../../atoms/Toasts/SuccessMessage";
import FullPageLayout from "../../../templates/FullPageLayout";
import { AxiosError, AxiosResponse } from "axios";
import { CloseIcon } from "../../../atoms/CustomIcons";
import { useNavigate } from "react-router-dom";
import RadioGroup from "../../../molecules/FormItems/RadioGroup";
import { createCompany } from "../ducks/services";
import { CreateCompanyType } from "../ducks/types";

export default function CreateCompany() {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [checklistFile, setChecklistFile] = useState<any>({});
  const [uploadModal, setUploadModal] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<{
    file: string;
    imagePreviewUrl: any;
  }>({ file: "", imagePreviewUrl: null });
  const [modalPreviewImage, setModalPreviewImage] = useState<{
    file: string;
    imagePreviewUrl: any;
  }>({ file: "", imagePreviewUrl: null });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateCompanyType>();

  const onSubmit = (val: CreateCompanyType) => {
    setLoading(true);
    const payload = {
      name: val?.name,
      location: val?.location,
      labelPosition: val?.labelPosition,
    };
    createCompany(payload)
      .then((res: AxiosResponse) => {
        if (checklistFile?.uid && res && res?.data?.id) {
          uploadUserImage({
            owner_id: res?.data?.id,
            image: checklistFile?.uid ? checklistFile : undefined,
            image_type: "company_image",
          })
            .then((res) => {
              if (res) {
                SuccessMessage("Company created.");
                dispatch(getUserDetails());
                setLoading(false);
                setUploadModal(false);
                navigate("/companies");
              }
            })
            .catch((e: unknown) => {
              if (e instanceof AxiosError) {
                setLoading(false);
                ErrorMessage(
                  e?.response?.data?.error?.message ?? "Something went wrong"
                );
              } else {
                setLoading(false);
                ErrorMessage("Something went wrong");
              }
            });
        } else {
          navigate("/companies");
          setLoading(false);
          SuccessMessage("Company created.");
        }
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  const saveUpload = () => {
    setLoading(true);
    setPreviewImage({ ...modalPreviewImage });
    setLoading(false);
    setUploadModal(false);
  };

  const radioData: Array<{ label: string; value: string }> = [
    {
      label: "Job",
      value: "Job",
    },
    {
      label: "Seat",
      value: "Seat",
    },
  ];

  return (
    <FullPageLayout loading={loading}>
      <Card className="fp_layout_title">
        <div className="mainTitle">
          <Button
            className="icon_grey_btn"
            icon={<CloseIcon />}
            onClick={() => navigate(-1)}
          >
            Close
          </Button>
          <h3>Create company</h3>
        </div>
      </Card>
      <Card className="fp_layout_content">
        <div className="cardContent">
          <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
            <Row gutter={[0, 32]}>
              <Col span={24}>
                <Row gutter={21}>
                  <Col flex="0 0 273px">
                    <h4
                      style={{
                        marginBottom: "8px",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      Logo
                    </h4>
                  </Col>
                  <Col>
                    <div className="leftCardDiv">
                      <UploadPhoto
                        uploadAvatar="/images/upload-avatar.svg"
                        width={88}
                        height={88}
                        saveUpload={saveUpload}
                        loading={loading}
                        uploadModal={uploadModal}
                        setUploadModal={setUploadModal}
                        onImageDelete={() => null}
                        previewImage={previewImage}
                        checklistFile={checklistFile}
                        modalPreviewImage={modalPreviewImage}
                        setChecklistFile={setChecklistFile}
                        setPreviewImage={setPreviewImage}
                        setModalPreviewImage={setModalPreviewImage}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={21}>
                  <Col flex="0 0 533px">
                    <InputField
                      fieldName="name"
                      label="Name"
                      control={control}
                      initValue=""
                      iProps={{
                        placeholder: "Specify name",
                      }}
                      isRequired={true}
                      rules={{
                        required: "Company name is required.",
                      }}
                      validate={errors.name && "error"}
                      validMessage={errors.name && errors.name.message}
                    />
                  </Col>
                  <Col flex="0 0 533px">
                    <InputField
                      fieldName="location"
                      label="Location"
                      control={control}
                      initValue=""
                      iProps={{
                        placeholder: "Specify location",
                      }}
                      isRequired={true}
                      rules={{
                        required: "Location is required.",
                      }}
                      validate={errors.location && "error"}
                      validMessage={errors.location && errors.location.message}
                    />
                  </Col>
                  <Col flex="0 0 533px" style={{ marginBottom: "30px" }}>
                    <div className="radioTags">
                      <RadioGroup
                        fieldName="labelPosition"
                        label="Label positions as:"
                        control={control}
                        optionsData={radioData}
                        initValue="Job"
                        onChangeRadio={() => null}
                      />
                    </div>
                  </Col>
                  <Col className="fp_footer">
                    <div>
                      <Button
                        htmlType="submit"
                        type="primary"
                        className="icon_dark_btn"
                      >
                        Create company
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      </Card>
    </FullPageLayout>
  );
}
