import { Form, Button, Row, Col, Checkbox, Image } from "antd";
import { useForm } from "react-hook-form";
import TextEditor from "../TextEditor";
import PrevIcon from "../../../atoms/CustomIcons/PrevIcon";
import NextIcon from "../../../atoms/CustomIcons/NextIcon";
import { DropdownIcon } from "../../../atoms/CustomIcons";
import SelectField from "../SelectField";
import { UpdateJob } from "../../../organisms/CompanyDetails/ducks/types";
import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import {
  getCompanyFieldsByIdDrop,
  getDropdownsByCompany,
  getJobFilters,
} from "../../../organisms/CompanyDetails/ducks/actions";
import { updateJobById } from "../../../organisms/CompanyDetails/ducks/services";
import { useLocation } from "react-router-dom";
import { AxiosError } from "axios";
import ErrorMessage from "../../../atoms/Toasts/ErrorMessage";
import SuccessMessage from "../../../atoms/Toasts/SuccessMessage";

export default function EditJobForm() {
  const { handleSubmit, control, setValue } = useForm<UpdateJob>();
  const dispatch = useAppDispatch();
  const url = useLocation();
  const jobId = url?.pathname.split("/")[2];
  const companyId = url?.pathname.split("/")[3];
  const settingData = useAppSelector(
    (state) => state?.companyDetails?.fieldsDropdown
  );
  const jobDetailData = useAppSelector(
    (state) => state?.jobDetail?.jobDetailData
  );

  const dropdownData = useAppSelector(
    (state) => state?.companyDetails?.roleDropdown
  );
  const jobsFilterOptions = useAppSelector(
    (state) => state?.companyDetails?.jobFiltersOptions
  );

  const onSubmit = (val: UpdateJob) => {
    const payload = {
      name: jobDetailData?.detailData?.name,
      jobHolders: val?.jobHolders,
      department_name: val?.department_name,
      purpose_statement: val?.purpose_statement,
      objectives: val?.objectives,
      key_results: val?.key_results,
      procedures: val?.procedures,
      workflows: val?.workflows,
      skills: val?.skills,
      technologies: val?.technologies,
      teams: val?.teams,
      entities: val?.entities,
      meetings: val?.meetings,
      reports_to: val?.reports_to,
      mentored_by: val?.mentored_by,
      company_id: companyId,
      status: "pending_approval",
    };

    updateJobById(payload, jobId)
      .then(() => {
        SuccessMessage("Job updated.");
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          ErrorMessage("Something went wrong");
        }
      });
  };

  const optionsForDropdown = [
    { value: "Option 1", label: "Option 1" },
    { value: "Option 2", label: "Option 2" },
    { value: "Option 3", label: "Option 3" },
  ];

  useEffect(() => {
    if (companyId) {
      const payload = {
        companyId: companyId,
      };
      const dropdownPayload = {
        companyId: companyId,
        sendRoles: true,
        sendUsers: true,
        sendJobs: true,
      };
      dispatch(getCompanyFieldsByIdDrop(payload));
      dispatch(getDropdownsByCompany(dropdownPayload));
      dispatch(getJobFilters(companyId));
    }
  }, [dispatch, companyId]);

  const arrayDropdown = useCallback(
    (moduleName: string) => {
      const filteredArray = settingData?.filter(
        (e: { module: string; name: string; value: string }) =>
          e?.module === moduleName
      );
      return filteredArray?.map((e: { name: string; value: string }) => ({
        label: e?.name,
        value: e?.value,
      }));
    },
    [settingData]
  );

  useEffect(() => {
    if (jobDetailData) {
      setValue("department_name", jobDetailData?.detailData?.department_name);
      setValue(
        "jobHolders",
        jobDetailData?.detailData?.employees?.map((e: { id: string }) => e?.id)
      );
      setValue(
        "purpose_statement",
        jobDetailData?.detailData?.purpose_statement
      );
      setValue("objectives", jobDetailData?.detailData?.objectives);
      setValue("key_results", jobDetailData?.detailData?.key_results);
      setValue("procedures", jobDetailData?.detailData?.procedures);
      setValue("workflows", jobDetailData?.detailData?.workflows);
      setValue("skills", jobDetailData?.detailData?.skills);
      setValue("technologies", jobDetailData?.detailData?.technologies);
      setValue("entities", jobDetailData?.detailData?.entities);
      setValue("teams", jobDetailData?.detailData?.teams);
      setValue("meetings", jobDetailData?.detailData?.meetings);
      setValue("reports_to", jobDetailData?.detailData?.reports_to);
      setValue("mentored_by", jobDetailData?.detailData?.mentored_by);
    }
  }, [jobDetailData]);

  return (
    <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
      <div className="job_card">
        <Row>
          <Col span={24}>
            <SelectField
              fieldName="jobHolders"
              label="Job holders"
              control={control}
              selectOption={jobsFilterOptions?.employee}
              mode="multiple"
              iProps={{
                placeholder: "Job holders",
                suffixIcon: <DropdownIcon />,
              }}
            />
          </Col>
          <Col span={24}>
            <SelectField
              fieldName="department_name"
              label="Department"
              control={control}
              selectOption={arrayDropdown("DEPARTMENT")}
              iProps={{
                placeholder: "Department",
                suffixIcon: <DropdownIcon />,
              }}
            />
          </Col>
          <Col span={24}>
            <h4 style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                Role purpose statement <span className="red_color">*</span>
              </div>
              <div>
                <h4 style={{ display: "flex", gap: "6px" }}>
                  <Image
                    src="/images/MagicWand.svg"
                    alt="Generate with AI"
                    width={16}
                    height={16}
                    preview={false}
                  />
                  Generate with AI
                </h4>
              </div>
            </h4>
            <TextEditor
              fieldName="purpose_statement"
              control={control}
              initValue=""
              // label="Role purpose statement"
              // tooltipTitle=""
              iProps={{
                placeholder: "Explain the Role purpose statement",
              }}
              isRequired
              rules={{
                required: "Error message",
              }}
            />
          </Col>
          <Col span={24}>
            <h4 style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                Objectives <span className="red_color">*</span>
              </div>
              <div>
                <h4>
                  <Checkbox style={{ marginRight: "4px" }} /> N/A
                </h4>
              </div>
            </h4>
            <TextEditor
              fieldName="objectives"
              control={control}
              initValue=""
              iProps={{
                placeholder:
                  "Example: Increase market share by developing and implementing effective sales strategies and fostering strong customer relationships.",
              }}
              isRequired
              rules={{
                required: "Error message",
              }}
            />
          </Col>
          <Col span={24}>
            <h4 style={{ position: "absolute", right: "0", top: "-2px" }}>
              <Checkbox style={{ marginRight: "4px" }} /> N/A
            </h4>

            <SelectField
              fieldName="key_results"
              label="Key Results"
              control={control}
              selectOption={arrayDropdown("KEY_RESULT")}
              mode="multiple"
              iProps={{
                placeholder: "Key Results",
                suffixIcon: <DropdownIcon />,
              }}
            />
          </Col>
          <Col span={24}>
            <h4 style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                Procedures/WIs/Policies <span className="red_color">*</span>
              </div>
              <div>
                <h4>
                  <Checkbox style={{ marginRight: "4px" }} /> N/A
                </h4>
              </div>
            </h4>
            <TextEditor
              fieldName="procedures"
              control={control}
              initValue=""
              // label="Procedures"
              // tooltipTitle=""
              iProps={{
                placeholder:
                  "Example: Develop detailed project schedules with tasks, Establish milestones and deliverable dates, Allocate necessary resources.",
              }}
              isRequired
              rules={{
                required: "Error message",
              }}
            />
          </Col>
          <Col span={24}>
            <h4 style={{ position: "absolute", right: "0", top: "-2px" }}>
              <Checkbox style={{ marginRight: "4px" }} /> N/A
            </h4>
            <SelectField
              fieldName="workflows"
              label="Workflows"
              control={control}
              selectOption={optionsForDropdown}
              mode="multiple"
              iProps={{
                placeholder: "Select workflows",
                suffixIcon: <DropdownIcon />,
              }}
            />
          </Col>
          <Col span={24}>
            <h4 style={{ position: "absolute", right: "0", top: "-2px" }}>
              <Checkbox style={{ marginRight: "4px" }} /> N/A
            </h4>

            <SelectField
              fieldName="skills"
              label="Skills"
              control={control}
              selectOption={arrayDropdown("SKILL")}
              mode="multiple"
              iProps={{
                placeholder: "Select skills",
                suffixIcon: <DropdownIcon />,
              }}
            />
          </Col>
          <Col span={24}>
            <h4 style={{ position: "absolute", right: "0", top: "-2px" }}>
              <Checkbox style={{ marginRight: "4px" }} /> N/A
            </h4>

            <SelectField
              fieldName="technologies"
              label="Technologies"
              control={control}
              selectOption={arrayDropdown("TECHNOLOGIES")}
              mode="multiple"
              iProps={{
                placeholder: "Select Technologies",
                suffixIcon: <DropdownIcon />,
              }}
            />
          </Col>
          <Col span={24}>
            <h4 style={{ position: "absolute", right: "0", top: "-2px" }}>
              <Checkbox style={{ marginRight: "4px" }} /> N/A
            </h4>

            <SelectField
              fieldName="teams"
              label="Teams"
              control={control}
              selectOption={arrayDropdown("TEAMS")}
              mode="multiple"
              iProps={{
                placeholder: "Select teams",
                suffixIcon: <DropdownIcon />,
              }}
            />
          </Col>
          <Col span={24}>
            <h4 style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                Entities <span className="red_color">*</span>
              </div>
              <h4>
                <Checkbox style={{ marginRight: "4px" }} /> N/A
              </h4>
            </h4>
            <TextEditor
              fieldName="entities"
              control={control}
              initValue=""
              // label="Entities"
              // tooltipTitle=""
              iProps={{
                placeholder: "Example: Parents, Vendors, Hiring Coordinator",
              }}
              isRequired
              rules={{
                required: "Error message",
              }}
            />
          </Col>
          <Col span={24}>
            <h4 style={{ position: "absolute", right: "0", top: "-2px" }}>
              <Checkbox style={{ marginRight: "4px" }} /> N/A
            </h4>

            <SelectField
              fieldName="meetings"
              label="Meetings"
              control={control}
              selectOption={arrayDropdown("MEETINGS")}
              mode="multiple"
              iProps={{
                placeholder: "Select meetings",
                suffixIcon: <DropdownIcon />,
              }}
            />
          </Col>
        </Row>
        <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Col style={{ width: "49.5%" }}>
            <h4 style={{ position: "absolute", right: "0", top: "-2px" }}>
              <Checkbox style={{ marginRight: "4px" }} /> N/A
            </h4>

            <SelectField
              fieldName="reports_to"
              label="Reports To"
              control={control}
              selectOption={dropdownData?.roles}
              iProps={{
                placeholder: "Select reports to",
                suffixIcon: <DropdownIcon />,
              }}
            />
          </Col>
          <Col style={{ width: "49.5%" }}>
            <h4 style={{ position: "absolute", right: "0", top: "-2px" }}>
              <Checkbox style={{ marginRight: "4px" }} /> N/A
            </h4>

            <SelectField
              fieldName="mentored_by"
              label="Mentioned by"
              control={control}
              selectOption={dropdownData?.mentoredBy}
              iProps={{
                placeholder: "Select mentioned by",
                suffixIcon: <DropdownIcon />,
              }}
            />
          </Col>
        </Row>

        <div
          className="steps"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div>
            <Button
              className="icon_grey_btn"
              icon={<PrevIcon />}
              style={{ marginRight: "10px" }}
            >
              Previous
            </Button>
            <Button className="icon_grey_btn" disabled>
              Next <NextIcon />
            </Button>
          </div>

          <Button className="icon_dark_btn" htmlType="submit">
            Finish
          </Button>
        </div>
      </div>
    </Form>
  );
}
