import Icon from "@ant-design/icons";

const SearchSvg = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.4705 13.1614L13.7275 10.4148C14.1431 9.49938 14.3925 8.58387 14.3925 7.58516C14.3925 5.75414 13.7275 4.08961 12.3976 2.8412C9.73771 0.177934 5.49852 0.177934 2.83865 2.8412C0.178782 5.50445 0.178782 9.74907 2.83865 12.4123C4.08547 13.6607 5.83103 14.4098 7.57657 14.4098C8.57403 14.4098 9.57148 14.1601 10.4027 13.7439L13.1457 16.4904C13.5612 16.9066 14.2263 17.1562 14.8081 17.1562C15.3899 17.1562 15.9718 16.9066 16.4705 16.4904C17.3848 15.4917 17.3848 13.9936 16.4705 13.1614ZM4.00235 11.0807C2.00745 9.08325 2.00745 5.83739 4.00235 3.92315C4.99979 2.92443 6.24661 2.42506 7.57657 2.42506C8.90648 2.42506 10.1533 2.92443 11.1507 3.92315C12.1482 4.92188 12.6469 6.17027 12.6469 7.50191C12.6469 8.83356 12.1482 10.1652 11.1507 11.0807C10.1533 11.9962 8.90648 12.5787 7.57657 12.5787C6.24661 12.5787 4.91666 12.0794 4.00235 11.0807Z"
      fill="#161616"
    />
  </svg>
);

const SearchIcon = (props: any) => <Icon component={SearchSvg} {...props} />;

export default SearchIcon;
