import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const Leaf18 = () => (
  <svg
    width="261"
    height="161"
    viewBox="0 0 261 161"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M55.1956 85.6993L259.862 159.659L92.8938 20.2709C79.2741 8.90102 62.2614 2.38104 44.5313 1.73654L26.1765 1.06932C6.86226 0.367219 -5.31761 21.6167 5.04984 37.9276L14.8684 53.3751C24.3643 68.3148 38.5472 79.683 55.1956 85.6993Z"
      fill="currentColor"
      stroke="#ECEDF3"
    />
  </svg>
);

const LeafIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={Leaf18} {...props} />
);

export default LeafIcon;
