import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const flowerSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_5998_47069)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.73086 1.90947C6.17177 1.46869 6.73196 1.16648 7.34245 1.04005C7.95294 0.913617 8.58708 0.968485 9.16679 1.1979C9.74649 1.42731 10.2464 1.82124 10.6051 2.3312C10.9637 2.84116 11.1654 3.44487 11.1853 4.068C11.9778 3.83959 12.8279 3.92738 13.557 4.31291C14.286 4.69844 14.8372 5.35167 15.0945 6.13521C15.3487 6.91633 15.2895 7.76668 14.9294 8.50496C14.5692 9.24323 13.9364 9.81272 13.1643 10.0932C13.6222 10.7742 13.7967 11.6068 13.6508 12.4144C13.5049 13.2219 13.0501 13.9409 12.3829 14.4186C11.7144 14.9013 10.8857 15.108 10.0688 14.9958C9.25193 14.8835 8.5097 14.4609 7.99628 13.8156C7.67656 14.2179 7.26485 14.5374 6.79584 14.7473C6.32683 14.9572 5.81425 15.0513 5.30127 15.0217C4.78829 14.9921 4.28994 14.8396 3.84818 14.5772C3.40643 14.3148 3.03421 13.95 2.76288 13.5137C2.44365 12.9981 2.27975 12.4016 2.29079 11.7953C2.30184 11.1891 2.48735 10.5988 2.82514 10.0953C2.05465 9.81027 1.4245 9.23792 1.06688 8.49832C0.70926 7.75873 0.651977 6.90938 0.907046 6.12846C1.16212 5.34754 1.70971 4.69576 2.43495 4.30986C3.16019 3.92396 4.0067 3.83393 4.79688 4.05866C4.82515 3.25022 5.15891 2.48257 5.73086 1.91051V1.90947ZM10.2793 8.2896C10.2793 9.54528 9.25508 10.5623 7.99109 10.5623C7.38631 10.5642 6.80552 10.3259 6.37642 9.8997C5.94732 9.47352 5.70504 8.89437 5.70284 8.2896C5.70284 7.03391 6.7271 6.01691 7.99109 6.01691C9.25508 6.01691 10.2793 7.03391 10.2793 8.2896Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_5998_47069">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const FlowerIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={flowerSvg} {...props} />
);

export default FlowerIcon;
