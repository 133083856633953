import * as action_types from "./constants";

export type accountState = {
  accountData: {
    company_id: string;
    email: string;
    first_name: string;
    id: string;
    job_id: string;
    last_name: string;
    search_key: string;
    status: string;
    type: string;
  };
};

const initialState: accountState = {
  accountData: {
    company_id: "",
    email: "",
    first_name: "",
    id: "",
    job_id: "",
    last_name: "",
    search_key: "",
    status: "",
    type: "",
  },
};

export default (state = initialState, action: any) => {
  const { type, data } = action;
  switch (type) {
    case action_types.ACCOUNT_DATA:
      return { ...state, accountData: data };

    default:
      return state;
  }
};
