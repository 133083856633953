import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const ToggleUpSvg = () => (
  <svg
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.195262 7.05669C-0.0650874 6.79634 -0.0650874 6.37423 0.195262 6.11388L5.23576 1.07339C5.33314 0.972163 5.4497 0.891263 5.57868 0.835426C5.71168 0.77785 5.85507 0.748143 6 0.748143C6.14493 0.748143 6.28832 0.77785 6.42132 0.835426C6.5503 0.891263 6.66686 0.972163 6.76424 1.07339L11.8047 6.11388C12.0651 6.37423 12.0651 6.79634 11.8047 7.05669C11.5444 7.31704 11.1223 7.31704 10.8619 7.05669L6 2.19476L1.13807 7.05669C0.877722 7.31704 0.455612 7.31704 0.195262 7.05669Z"
      fill="#161616"
    />
  </svg>
);

const ToggleUpIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ToggleUpSvg} {...props} />
);

export default ToggleUpIcon;
