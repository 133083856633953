import { Card, Button } from "antd";
import { useNavigate } from "react-router-dom";
import FullPageLayout from "../../../templates/FullPageLayout";
import { CloseIcon } from "../../../atoms/CustomIcons";

export default function ThanksForList() {
  const navigate = useNavigate();

  return (
    <FullPageLayout loading={false}>
      <Card className="fp_layout_title">
        <div className="mainTitle">
          <Button
            className="icon_grey_btn"
            icon={<CloseIcon />}
            onClick={() => navigate(-1)}
          >
            Close
          </Button>
          <h3> Flower Power session </h3>
        </div>
      </Card>
      <Card
        className="fp_layout_content"
        style={{ height: "auto", minHeight: "82vh" }}
      >
        <div className="empty_card" style={{ textAlign: "center" }}>
          <div className="ant-empty">
            <div className="ant-empty-image">
              <img src="/images/empty/thanks_WS_completed.svg" alt="" />
            </div>
            <div className="ant-empty-description">
              <h2
                style={{
                  fontSize: "32px",
                  lineHeight: "40px",
                  marginBottom: "12px",
                }}
              >
                Thank you! <br /> Now, return to the session
              </h2>
              <p> The session facilitator will explain the next steps. </p>
            </div>
          </div>
        </div>
      </Card>
    </FullPageLayout>
  );
}
