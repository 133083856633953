import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const NextSvg = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99884 2.59429C10.3283 2.26479 10.8626 2.26479 11.1921 2.5943L16.3466 7.74892C16.5049 7.90715 16.5938 8.12176 16.5938 8.34554C16.5937 8.56931 16.5049 8.78393 16.3466 8.94216L11.1921 14.0966C10.8626 14.4261 10.3283 14.4261 9.99883 14.0966C9.66933 13.7671 9.66933 13.2329 9.99884 12.9034L13.713 9.18928H2.25C1.78401 9.18928 1.40625 8.81152 1.40625 8.34553C1.40625 7.87954 1.78401 7.50178 2.25 7.50178H13.713L9.99883 3.78754C9.66933 3.45803 9.66933 2.9238 9.99884 2.59429Z"
      fill="#070707"
    />
  </svg>
);

const NextIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={NextSvg} {...props} />
);

export default NextIcon;
