import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const Leaf14 = () => (
  <svg
    width="108"
    height="311"
    viewBox="0 0 108 311"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.31982 86.1383L54.3124 310.839L105.783 85.6808C109.478 69.5211 105.558 52.5599 95.1479 39.6596L70.0134 8.51196C61.9076 -1.53299 46.6054 -1.55196 38.4748 8.4729L13.0075 39.8735C2.50446 52.8235 -1.43894 69.8937 2.31982 86.1383Z"
      fill="currentColor"
      stroke="#ECEDF3"
    />
  </svg>
);

const LeafIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={Leaf14} {...props} />
);

export default LeafIcon;
