import { useState } from "react";
import { Row, Col, Button, Card, Form } from "antd";
import { useForm } from "react-hook-form";
import UploadPhoto from "../../../../molecules/FormItems/UploadPhoto";
import InputField from "../../../../molecules/FormItems/InputField";
import { uploadUserImage } from "../../../../organisms/MyAccount/ducks/services";
import { useAppDispatch } from "../../../../config/hooks";
import { getUserDetails } from "../../../../organisms/MyAccount/ducks/actions";
import ErrorMessage from "../../../../atoms/Toasts/ErrorMessage";
import SuccessMessage from "../../../../atoms/Toasts/SuccessMessage";
import FullPageLayout from "../../../../templates/FullPageLayout";
import { AxiosError, AxiosResponse } from "axios";
import { CloseIcon, DropdownIcon } from "../../../../atoms/CustomIcons";
import { useLocation, useNavigate } from "react-router-dom";
import SelectField from "../../../../molecules/FormItems/SelectField";
import { createUser } from "../../ducks/services";

type FormValues = {
  first_name: string;
  last_name: string;
  email: string;
  user_type: string;
};

export default function AddEmployees() {
  const url = useLocation();
  const companyId = url?.pathname.split("/")[2];
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [checklistFile, setChecklistFile] = useState<any>({});
  const [uploadModal, setUploadModal] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<{
    file: string;
    imagePreviewUrl: any;
  }>({ file: "", imagePreviewUrl: null });
  const [modalPreviewImage, setModalPreviewImage] = useState<{
    file: string;
    imagePreviewUrl: any;
  }>({ file: "", imagePreviewUrl: null });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const onSubmit = (val: FormValues) => {
    setLoading(true);
    const payload = {
      firstName: val?.first_name,
      lastName: val?.last_name,
      email: val?.email,
      userType: val?.user_type,
      companyId: companyId,
    };
    createUser(payload)
      .then((res: AxiosResponse) => {
        setLoading(false);
        dispatch(getUserDetails());
        SuccessMessage("Profile updated.");

        if (checklistFile?.uid && res && res?.data?.id) {
          uploadUserImage({
            owner_id: res?.data?.id,
            image: checklistFile?.uid ? checklistFile : undefined,
            image_type: "user_image",
          })
            .then((res) => {
              if (res) {
                SuccessMessage("Image changed.");
                setLoading(false);
                setUploadModal(false);
                navigate(-1);
              }
            })
            .catch((e: unknown) => {
              if (e instanceof AxiosError) {
                setLoading(false);
                ErrorMessage(
                  e?.response?.data?.error?.message ?? "Something went wrong"
                );
              } else {
                setLoading(false);
                ErrorMessage("Something went wrong");
              }
            });
        } else {
          navigate(-1);
        }
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  const saveUpload = () => {
    setLoading(true);
    setPreviewImage({ ...modalPreviewImage });
    setLoading(false);
    setUploadModal(false);
  };

  const userTypeDropDown = [
    {
      value: "INDIVIDUAL_CONTRIBUTOR",
      label: "Individual Contributor",
      desc: (
        <>
          Participates in the Flower Power sessions and has a view access to the
          company's organizational structure.
        </>
      ),
    },
    {
      value: "COMPANY_ADMIN",
      label: "Company Admin",
      desc: "Manages the company and its employees. Can facilitate and participate in the Flower Power sessions",
    },
  ];

  return (
    <FullPageLayout loading={loading}>
      <Card className="fp_layout_title">
        <div className="mainTitle">
          <Button
            className="icon_grey_btn"
            icon={<CloseIcon />}
            onClick={() => navigate(-1)}
          >
            Close
          </Button>
          <h3>Add Employee</h3>
        </div>
      </Card>
      <Card className="fp_layout_content " style={{ height: "auto" }}>
        <div className="cardContent">
          <Form onFinish={handleSubmit(onSubmit)}>
            <Row gutter={[0, 32]}>
              <Col span={24}>
                <Row gutter={21}>
                  <Col flex="0 0 273px">
                    <h4
                      style={{
                        marginBottom: "8px",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      User Photo
                    </h4>
                  </Col>
                  <Col>
                    <div className="leftCardDiv">
                      <UploadPhoto
                        uploadAvatar="/images/upload-avatar.svg"
                        width={88}
                        height={88}
                        saveUpload={saveUpload}
                        loading={loading}
                        uploadModal={uploadModal}
                        setUploadModal={setUploadModal}
                        onImageDelete={() => null}
                        previewImage={previewImage}
                        checklistFile={checklistFile}
                        modalPreviewImage={modalPreviewImage}
                        setChecklistFile={setChecklistFile}
                        setPreviewImage={setPreviewImage}
                        setModalPreviewImage={setModalPreviewImage}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={21}>
                  <Col flex="0 0 265px">
                    <h4>
                      First Name <span className="red_color">*</span>
                    </h4>
                    <InputField
                      fieldName="first_name"
                      label=""
                      control={control}
                      initValue=""
                      iProps={{
                        placeholder: "Specify first name",
                      }}
                      isRequired
                      rules={{
                        required: "First name is required.",
                      }}
                      validate={errors.first_name && "error"}
                      validMessage={
                        errors.first_name && errors.first_name.message
                      }
                    />
                  </Col>
                  <Col flex="0 0 265px">
                    <h4>
                      Last Name <span className="red_color">*</span>
                    </h4>
                    <InputField
                      fieldName="last_name"
                      label=""
                      control={control}
                      initValue=""
                      iProps={{
                        placeholder: "Specify last name",
                      }}
                      isRequired
                      rules={{
                        required: "First name is required.",
                      }}
                      validate={errors.last_name && "error"}
                      validMessage={
                        errors.last_name && errors.last_name.message
                      }
                    />
                  </Col>
                  <Col flex="0 0 533px">
                    <h4>
                      Email Address <span className="red_color">*</span>
                    </h4>
                    <InputField
                      fieldName="email"
                      label=""
                      control={control}
                      initValue=""
                      iProps={{
                        placeholder: "Type location here",
                      }}
                      isRequired
                      rules={{
                        required: "Email is required.",
                        pattern: {
                          value: /^[\w.-]+@([\w-]+\.)+[\w-]{2,4}$/,
                          message: "Email is required.",
                        },
                      }}
                      validate={errors.email && "error"}
                      validMessage={errors.email && errors.email.message}
                    />
                  </Col>
                  {/* <Col flex="0 0 533px">
                    <h4>Job</h4>
                    <SelectField
                      fieldName="job"
                      label=""
                      control={control}
                      selectOption={optionsForDropdown}
                      className="fp_select"
                      iProps={{
                        placeholder: "Select Job",
                        suffixIcon: <DropdownIcon />,
                      }}
                    />
                  </Col> */}
                  <Col flex="0 0 533px">
                    <h4>
                      User type <span className="red_color">*</span>{" "}
                    </h4>
                    <SelectField
                      fieldName="user_type"
                      label=""
                      control={control}
                      selectOption={userTypeDropDown}
                      className="fp_select"
                      iProps={{
                        placeholder: "Select user type",
                        suffixIcon: <DropdownIcon />,
                      }}
                    />
                  </Col>
                  <Col className="fp_footer">
                    <div>
                      <Button
                        htmlType="submit"
                        type="primary"
                        className="icon_dark_btn"
                      >
                        Add employee
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      </Card>
    </FullPageLayout>
  );
}
