import * as action_types from "./constants";

export type MyCompanyState = {
  usersList: {
    loading: boolean;
    count: number;
    data: [];
  };
  userDetail: {
    first_name: string;
    last_name: string;
    email: string;
  };
};

const initialState: MyCompanyState = {
  usersList: {
    loading: true,
    count: 0,
    data: [],
  },
  userDetail: {
    first_name: "",
    last_name: "",
    email: "",
  },
};

export default (state = initialState, action: any) => {
  const { type, data } = action;
  switch (type) {
    case action_types.USERS_LIST:
      return {
        ...state,
        usersList: {
          count: data?.count,
          data: data?.data,
          loading: action?.data?.loading,
        },
      };

    case action_types.EMPLOYEE_DETAILS:
      return { ...state, userDetail: data };

    default:
      return state;
  }
};
