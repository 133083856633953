import { useMemo } from "react";
import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import RoleCard from "../RoleCard";
import { Column, Id, Role } from "../../types";

interface PropsType {
  column: Column;
  roles: Role[];
  index: number;
  deleteColumn: (id: Id) => void;
  deleteRole: (id: Id) => void;
  updateRole?: (id: string | number, descriptor: string) => void;
  roleToUpdate: any;
}

export default function ColumnContainer(props: PropsType) {
  const {
    column,
    deleteColumn,
    roles,
    deleteRole,
    index,
    updateRole,
    roleToUpdate,
  } = props;

  const rolesId = useMemo(() => roles?.map((role: Role) => role?.id), [roles]);

  const { setNodeRef, transform, transition, isDragging } = useSortable({
    id: column.id,
    data: {
      type: "Column",
      column,
    },
  });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  if (isDragging) {
    return (
      <div
        className="column_wrapper is_dragging"
        ref={setNodeRef}
        style={style}
      ></div>
    );
  }
  return (
    <div className="column_wrapper" ref={setNodeRef} style={style}>
      <div className="column_header">
        <div className="column_num">{index + 1}</div>
        <div className="column_title">Petal</div>
        <button onClick={() => deleteColumn(column.id)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
            />
          </svg>
        </button>
      </div>
      <div className="column_content">
        {roles?.length === 0 && (
          <div className="drag_item">Drag and drop the items here</div>
        )}
        <SortableContext items={rolesId}>
          {roles?.map((role: Role) => (
            <RoleCard
              key={role.id}
              role={role}
              deleteRole={deleteRole}
              updateRole={updateRole}
              roleToUpdate={roleToUpdate}
            />
          ))}
        </SortableContext>
      </div>
    </div>
  );
}
