import { Button, Card, Col, Row, Select, Input, Image } from "antd";
import {
  completeSessionById,
  updatePetalById,
  updateRoleDescriptor,
  updateSessionById,
} from "../../../../../../../organisms/MyJob/ducks/services";
import SessionFlowerFinalized from "../SessionFlowerFinalized";
import { useAppDispatch } from "../../../../../../../config/hooks";
import { getFlowerDataBySessionID } from "../../../../../../../organisms/MyJob/ducks/actions";
import { useEffect, useState } from "react";
import { SessionTypeResponse } from "../../../../../../../organisms/MyJob/ducks/types";

const { TextArea } = Input;

type PropsType = {
  data: any;
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  activeData?: any;
  activeNumber: number;
  petalData: any;
  sessionId: string;
  petalTeams: any;
  sessionData: SessionTypeResponse;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function SelectFpSessionStatement(props: PropsType) {
  const {
    data,
    activeTab,
    setActiveTab,
    activeData,
    setCurrent,
    activeNumber,
    petalTeams,
    sessionId,
    sessionData,
    setLoading,
  } = props;
  // const dispatch = useAppDispatch();
  console.log("activeData", activeData);
  const dispatch = useAppDispatch();
  const [value, setValue] = useState("");
  const [enableSubmit, setEnableSubmit] = useState(false);

  const selectDescriptor = (value: string, text: string, id: string) => {
    const payload = {
      id: id,
      descriptor: text,
      category: value,
    };
    updateRoleDescriptor(payload).then(() => {
      console.log("success");
    });
  };

  const setPurposeStatement = (active: boolean) => {
    const payload = {
      purpose_statement: value,
    };
    updatePetalById(activeData?.id, payload).then(() => {
      dispatch(getFlowerDataBySessionID(sessionId));

      if (+activeTab !== data?.length) {
        setActiveTab((+activeTab + 1).toString());
      }
    });
    if (!active) {
      setEnableSubmit(true);
    }
  };

  const CompleteSession = () => {
    setLoading(true);
    completeSessionById(sessionId).then(() => {
      const payloadScreen = {
        last_session_screen: 9,
      };
      updateSessionById(payloadScreen, sessionId).then(() => {
        setLoading(false);
        setCurrent(9);
      });
    });
  };

  const setPurposeStatementText = () => {
    const content = `The purpose of the ${activeData?.finalized_name} role is to achieve organizational growth and improved team performance by developing leadership skills, providing mentorship, conducting training programs, and fostering a culture of continuous improvement and innovation.`;
    setValue(content);
  };

  useEffect(() => {
    if (activeData?.purpose_statement) {
      setValue(activeData?.purpose_statement);
    }
  }, [activeData]);
  return (
    <Row>
      <Col span={13}>
        <div className="session_flower finalizedFlower">
          <div
            className="flower_wrapper"
            style={{ height: "calc(100vh - 130px)" }}
          >
            <SessionFlowerFinalized
              data={data}
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              setCurrent={setCurrent}
              activeData={activeData}
              petalTeams={petalTeams}
            />
            <div className="flower_position">{sessionData?.job?.name}</div>
          </div>
        </div>
      </Col>
      <Col span={11}>
        <Card className="session_fp_card fp_session_role session_inner_tab">
          <h4>
            {" "}
            Define role purpose statements <span>*</span>
          </h4>
          <p>
            Ensure role purpose statements clearly and concisely cover a role's
            why, how, and what. Typically, 3 or so sentences.
          </p>
          <p>
            Often starts with "The purpose of role X is to achieve ABC by doing
            XYZ ", where XYZ could be the tasks within the petal.
          </p>
          <div className="content_section">
            <div className="active_state" style={{ marginBottom: "16px" }}>
              <h6 className={`tagNumber_${activeNumber}`}> {activeNumber} </h6>
              {activeData?.finalized_name}
            </div>
            <div className="purpose_session_area">
              <div className="textarea_top">
                <label>Purpose statement</label>
                <Button
                  className="generate_btn"
                  onClick={setPurposeStatementText}
                >
                  <Image
                    src="/images/MagicWand.svg"
                    alt="Generate with AI"
                    width={16}
                    height={16}
                    preview={false}
                  />
                  Generate with AI
                </Button>
              </div>
              <TextArea
                value={value}
                onChange={(e) => setValue(e.target.value)}
                placeholder="Enter the statement here or generate with AI"
              />
            </div>
            <div className="role_description">
              <h4> Role descriptors: </h4>
              <ul>
                {activeData?.descriptors?.map(
                  (e: { descriptor: string; id: string; category: string }) => (
                    <li>
                      {e?.descriptor}{" "}
                      <Select
                        defaultValue={
                          e?.category ? e?.category : "UNCATEGORIZED"
                        }
                        onChange={(value) =>
                          selectDescriptor(value, e.descriptor, e.id)
                        }
                        options={[
                          { value: "UNCATEGORIZED", label: "Uncategorized" },
                          { value: "PROCEDURE_TASK", label: "Procedure/Task" },
                          { value: "RESPONSIBILITY", label: "Responsibility" },
                        ]}
                      />
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>

          <div className="move_buttons">
            <Button
              htmlType="button"
              className="icon_grey_btn"
              type="primary"
              size="large"
              disabled={activeTab === "1"}
              onClick={() => {
                setActiveTab((+activeTab - 1).toString());
              }}
            >
              Previous Role
            </Button>

            {+activeTab !== data?.length ? (
              <Button
                htmlType="button"
                className="icon_grey_btn"
                type="primary"
                size="large"
                disabled={+activeTab === data?.length || value === ""}
                onClick={() => setPurposeStatement(true)}
              >
                Next Role
              </Button>
            ) : (
              <Button
                htmlType="button"
                className="icon_grey_btn"
                type="primary"
                size="large"
                onClick={() => setPurposeStatement(false)}
                disabled={value === ""}
              >
                Submit
              </Button>
            )}
          </div>
          <div className="steps_footer">
            <div className="steps_btn_wrapper">
              <Button
                htmlType="button"
                className="icon_grey_btn"
                type="primary"
                size="large"
                onClick={() => setCurrent(7)}
              >
                Back
              </Button>
              <Button
                htmlType="button"
                className="icon_dark_btn"
                type="primary"
                size="large"
                onClick={CompleteSession}
                disabled={
                  (+activeTab !== data?.length ||
                    value === "" ||
                    !enableSubmit) &&
                  sessionData?.last_session_screen < 8
                }
              >
                Complete session
              </Button>
            </div>
          </div>
        </Card>
      </Col>
    </Row>
  );
}
