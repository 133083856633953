import { useEffect } from "react";
import DashboardTemplate from "../../templates/Dashboard";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { getJobDetails } from "./ducks/actions";
import JobDetails from "../../atoms/JobDetails";

export default function MyJob() {
  const dispatch = useAppDispatch();
  const accountData = useAppSelector((state) => state?.account?.accountData);
  const jobDetailData = useAppSelector(
    (state) => state?.jobDetail?.jobDetailData
  );

  useEffect(() => {
    if (accountData?.job_id) {
      dispatch(getJobDetails(accountData?.job_id));
    }
  }, [accountData?.job_id]);

  return (
    <DashboardTemplate loading={jobDetailData?.loading}>
      <JobDetails jobDetailData={jobDetailData?.detailData} />
    </DashboardTemplate>
  );
}
