import { Row, Col, Button, Card, Form } from "antd";
import { useForm } from "react-hook-form";
import UploadPhoto from "../../../molecules/FormItems/UploadPhoto";
import InputField from "../../../molecules/FormItems/InputField";
import { useEffect, useState } from "react";
import {
  onImageDelete,
  uploadUserImage,
} from "../../../organisms/MyAccount/ducks/services";
import ErrorMessage from "../../../atoms/Toasts/ErrorMessage";
import SuccessMessage from "../../../atoms/Toasts/SuccessMessage";
import FullPageLayout from "../../../templates/FullPageLayout";
import { AxiosError } from "axios";
import { CloseIcon, DropdownIcon } from "../../../atoms/CustomIcons";
import { useLocation, useNavigate } from "react-router-dom";
import SelectField from "../../../molecules/FormItems/SelectField";
import { updateEmployeeById } from "../../../organisms/CompanyDetails/ducks/services";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { getEmployeeDetails } from "../ducks/actions";

type FormValues = {
  first_name: string;
  last_name: string;
  email: string;
};

export default function EditUser() {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const url = useLocation();
  const employeeId = url?.pathname.split("/")[2];
  const [checklistFile, setChecklistFile] = useState<any>({});
  const [uploadModal, setUploadModal] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<{
    file: string;
    imagePreviewUrl: any;
  }>({ file: "", imagePreviewUrl: null });
  const [modalPreviewImage, setModalPreviewImage] = useState<{
    file: string;
    imagePreviewUrl: any;
  }>({ file: "", imagePreviewUrl: null });
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>();
  const userDetail = useAppSelector((state) => state?.system?.userDetail);

  const onSubmit = (val: FormValues) => {
    setLoading(true);
    const payload = {
      first_name: val?.first_name,
      last_name: val?.last_name,
      email: val?.email,
      type: "SUPER_ADMIN",
    };
    updateEmployeeById(payload, employeeId)
      .then(() => {
        setLoading(false);
        SuccessMessage("User updated.");
        navigate("/system-settings");
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  const saveUpload = () => {
    setLoading(true);
    setPreviewImage({ ...modalPreviewImage });
    uploadUserImage({
      owner_id: employeeId,
      image: checklistFile?.uid ? checklistFile : undefined,
      image_type: "user_image",
    })
      .then((res) => {
        if (res) {
          SuccessMessage("Image changed.");
          setLoading(false);
          setUploadModal(false);
        }
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  const optionsForDropdown = [{ value: "SUPER_ADMIN", label: "Super Admin" }];

  useEffect(() => {
    if (employeeId) {
      dispatch(getEmployeeDetails(employeeId));
    }
  }, [employeeId, dispatch]);

  useEffect(() => {
    if (userDetail) {
      setValue("first_name", userDetail?.first_name);
      setValue("last_name", userDetail?.last_name);
      setValue("email", userDetail?.email);
    }
  }, [userDetail]);

  function callUserDetailsAPI() {
    dispatch(getEmployeeDetails(employeeId));
  }

  return (
    <FullPageLayout loading={false}>
      <Card className="fp_layout_title">
        <div className="mainTitle">
          <Button
            className="icon_grey_btn"
            icon={<CloseIcon />}
            onClick={() => navigate(-1)}
          >
            Close
          </Button>
          <h3>Edit user</h3>
        </div>
      </Card>
      <Card className="fp_layout_content">
        <div className="cardContent">
          <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
            <Row gutter={[0, 32]}>
              <Col span={24}>
                <Row gutter={21}>
                  <Col flex="0 0 273px">
                    <h4
                      style={{
                        marginBottom: "8px",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      User Photo
                    </h4>
                  </Col>
                  <Col>
                    <div className="leftCardDiv">
                      <UploadPhoto
                        uploadAvatar={
                          userDetail?.image?.url
                            ? userDetail?.image?.url
                            : "/images/upload-avatar.svg"
                        }
                        imageUploaded={userDetail?.image?.url}
                        uploadedImageURL={
                          userDetail?.image?.url
                            ? userDetail?.image?.url
                            : undefined
                        }
                        width={88}
                        height={88}
                        saveUpload={saveUpload}
                        loading={loading}
                        uploadModal={uploadModal}
                        setUploadModal={setUploadModal}
                        onImageDelete={() => {
                          onImageDelete(
                            setLoading,
                            SuccessMessage,
                            callUserDetailsAPI,
                            ErrorMessage,
                            userDetail?.image?.id
                              ? userDetail?.image?.id
                              : undefined
                          );
                        }}
                        previewImage={previewImage}
                        checklistFile={checklistFile}
                        modalPreviewImage={modalPreviewImage}
                        setChecklistFile={setChecklistFile}
                        setPreviewImage={setPreviewImage}
                        setModalPreviewImage={setModalPreviewImage}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={21}>
                  <Col flex="0 0 265px">
                    <h4>
                      First Name <span className="red_color">*</span>
                    </h4>
                    <InputField
                      fieldName="first_name"
                      label=""
                      control={control}
                      initValue=""
                      iProps={{
                        placeholder: "Specify first name",
                      }}
                      isRequired
                      rules={{
                        required: "First name is required.",
                      }}
                      validate={errors.first_name && "error"}
                      validMessage={
                        errors.first_name && errors.first_name.message
                      }
                    />
                  </Col>
                  <Col flex="0 0 265px">
                    <h4>
                      Last Name <span className="red_color">*</span>
                    </h4>
                    <InputField
                      fieldName="last_name"
                      label=""
                      control={control}
                      initValue=""
                      iProps={{
                        placeholder: "Specify last name",
                      }}
                      isRequired
                      rules={{
                        required: "First name is required.",
                      }}
                      validate={errors.last_name && "error"}
                      validMessage={
                        errors.last_name && errors.last_name.message
                      }
                    />
                  </Col>
                  <Col flex="0 0 533px">
                    <h4>
                      Email Address <span className="red_color">*</span>
                    </h4>
                    <InputField
                      fieldName="email"
                      label=""
                      control={control}
                      initValue=""
                      iProps={{
                        placeholder: "Enter email address",
                      }}
                      isRequired
                      rules={{
                        required: "Email is required.",
                        pattern: {
                          value: /^[\w.-]+@([\w-]+\.)+[\w-]{2,4}$/,
                          message: "Email is required.",
                        },
                      }}
                      validate={errors.email && "error"}
                      validMessage={errors.email && errors.email.message}
                    />
                  </Col>
                  <Col flex="0 0 533px">
                    <SelectField
                      fieldName="user_type"
                      label="Access level"
                      control={control}
                      selectOption={optionsForDropdown}
                      className="fp_select"
                      initValue="SUPER_ADMIN"
                      iProps={{
                        placeholder: "Select user type",
                        suffixIcon: <DropdownIcon />,
                        disabled: true,
                      }}
                      tooltipTitle="There's only one access level in Flower Power at the moment"
                    />
                  </Col>
                  <Col className="fp_footer">
                    <div>
                      <Button
                        htmlType="submit"
                        type="primary"
                        className="icon_dark_btn"
                      >
                        Update user
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      </Card>
    </FullPageLayout>
  );
}
