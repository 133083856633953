import { useForm, SubmitHandler } from "react-hook-form";
import { Image, Form, Button, Input, Alert } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";

import { resetPassword } from "../Login/ducks/services";
import LoginTemplate from "../../templates/LoginTemplate";
import {
  ErrorIcon,
  EyeInvisibleOutlined,
  EyeOutlined,
} from "../../atoms/CustomIcons";

interface FormValues {
  password: string;
  confirmPassword: string;
}

export default function UpdatePassword() {
  const { handleSubmit } = useForm<FormValues>({
    mode: "onChange",
  });

  const [loading, setLoading] = useState(false);
  const [linkExpired, setLinkExpired] = useState<boolean>(false);
  const [token, setToken] = useState<string>("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const navigate = useNavigate();
  const [form] = Form.useForm();

  useEffect(() => {
    const params = new URLSearchParams(window?.location?.search);
    const authId = params.get("auth");
    setToken(authId as string);
  }, []);

  const onSubmit: SubmitHandler<FormValues> = () => {
    setLoading(true);
    const payload: { newPassword: string; token: string } = {
      newPassword: newPassword,
      token: token,
    };

    resetPassword(payload)
      .then(() => {
        setLoading(false);
        setTimeout(() => {
          navigate("/login", { state: { id: 1, name: "update" } });
        }, 500);
      })
      .catch((e: unknown) => {
        setLoading(false);
        if (e instanceof AxiosError) {
          if (e?.response?.status === 401) {
            setLinkExpired(true);
          }
        }
      });
  };

  return (
    <LoginTemplate loading={loading}>
      <div className="loginForm">
        <div className="loginLogo">
          <Image
            src="/images/login/fp_logo.svg"
            // width={48}
            // height={48}
            alt="Logo"
            preview={false}
          />
        </div>

        <h1 style={{ marginBottom: "48px" }}>Create new password</h1>
        {linkExpired && (
          <div className="alertWrapper">
            <Alert
              message="The password reset link has expired."
              icon={<ErrorIcon />}
              type="error"
              showIcon
              closable
            />
          </div>
        )}
        <Form
          form={form}
          name="dependencies"
          autoComplete="off"
          style={{ maxWidth: 600 }}
          layout="vertical"
          onFinish={handleSubmit(onSubmit)}
        >
          <Form.Item
            label="New Password"
            name="password"
            rules={[{ required: true, message: "New password is required." }]}
            className="requiredInput"
          >
            <Input.Password
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="Enter new password"
              iconRender={(visible: boolean) =>
                visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
              }
              className="formControl"
            />
          </Form.Item>

          <Form.Item
            label="Confirm New Password"
            name="confirmPassword"
            dependencies={["password"]}
            className="requiredInput"
            rules={[
              {
                required: true,
                message: "Confirm new password is required.",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Passwords don't match"));
                },
              }),
            ]}
          >
            <Input.Password
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              placeholder="Enter new password"
              iconRender={(visible: boolean) =>
                visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
              }
              className="formControl"
            />
          </Form.Item>

          <Button
            htmlType="submit"
            type="primary"
            className="icon_dark_btn"
            style={{ marginTop: "16px" }}
            disabled={newPassword?.length < 4 || confirmNewPassword?.length < 4}
          >
            Reset password
          </Button>
        </Form>
      </div>
    </LoginTemplate>
  );
}
