import { Image } from "antd";
import NoPermissionTemplate from "../../templates/NoPermission";

export default function NoPermission() {
  return (
    <NoPermissionTemplate>
      <div className="no_data_card">
        <div className="no_data_image">
          <Image
            src="/images/empty-Icon.svg"
            preview={false}
            width={216}
            height={102}
          />
        </div>
        <div className="no_data_content">
          <h4>There’re no features available on the web platform</h4>
          <p>
            Members don't have web interface yet.
            <br /> To access the features, use the E-table mobile application.
          </p>
          <div className="no_data_app_btn">
            <Image
              src="/images/appstore.svg"
              preview={false}
              width={180}
              height={52}
            />
            <Image
              src="/images/googleplay.svg"
              preview={false}
              width={180}
              height={52}
            />
          </div>
        </div>
      </div>
    </NoPermissionTemplate>
  );
}
