import { Fragment, useCallback, useEffect, useState } from "react";
import {
  Row,
  Col,
  Table,
  TableColumnsType,
  Button,
  Select,
  Image,
  Avatar,
  Tag,
  Badge,
  Dropdown,
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardTemplate from "../../../templates/Dashboard";
import { DropdownIcon, SortIcon } from "../../../atoms/CustomIcons";
import EmptyCard from "../../../molecules/EmptyCard";
import { useAppSelector, useAppDispatch } from "../../../config/hooks";
import { Popup } from "../../../atoms/Popup";
import ConfirmDelete from "../../../atoms/ConfirmDelete";
import { getJobFilters, getJobsListing } from "../ducks/actions";
import {
  getInitials,
  STATUS_TYPE,
  STATUS_TYPE_DROPDOWN,
} from "../../../config/constants";
import { deleteJob } from "../ducks/services";
import { AxiosError } from "axios";
import ErrorMessage from "../../../atoms/Toasts/ErrorMessage";
import Header from "../Components/Header";
import CompanyLinks from "../Components/CompanyLinks";
import CreateJob from "../../../atoms/CreateJob";

interface DataType {
  id: string;
  name: string;
  department_name: string;
  status: string;
  roles: { id: string; name: string }[];
  employees: string[];
}

export default function CompanyDetails() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [pagination, setPagination] = useState(1);
  const [limit, setLimit] = useState(10);
  const [jobCreate, setJobCreate] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [statusDropdown, setStatusDropdown] = useState<
    { label: string; value: string }[]
  >([]);
  const [tableRecord, setTableRecord] = useState<DataType>();
  const url = useLocation();
  const companyId = url?.pathname.split("/")[3];
  const urlType = url?.pathname.split("/")[2];
  const jobsFilterOptions = useAppSelector(
    (state) => state?.companyDetails?.jobFiltersOptions
  );

  const jobsListData = useAppSelector(
    (state) => state?.companyDetails?.jobsList
  );

  const items = [
    {
      key: "1",
      label: (
        <Button
          className="noBorder"
          style={{ padding: "0px" }}
          onClick={() =>
            navigate(`/job-detail/${tableRecord?.id}/${companyId}`)
          }
        >
          Edit
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Button
          onClick={() => setIsDeleteModalOpen(true)}
          className="noBorder"
          style={{ padding: "0px" }}
        >
          Delete
        </Button>
      ),
    },
  ];

  const columns: TableColumnsType<DataType> = [
    {
      title: "Job Name",
      dataIndex: "name",
      sorter: (a, b) => a?.name?.localeCompare(b?.name),
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Roles",
      dataIndex: "roles",
      sortIcon: () => <SortIcon />,
      render: (text) =>
        text?.length > 0
          ? text?.map((e: { name: string; id: string }) => (
              <Tag
                className="defaultTag white_tag"
                style={{ marginRight: "2px", marginLeft: "2px" }}
                key={e?.id}
              >
                {e?.name}
              </Tag>
            ))
          : "-",
      // render: (text: any[]) => (
      //   <div className="location_table_wrapper">
      //     {text && text?.length > 0 ? (
      //       <>
      //         <div className="label">{text[0]?.name}</div>
      //         {text?.length > 1 && (
      //           <div className="tooltip_location">
      //             <Tooltip
      //               title={
      //                 <>
      //                   {text?.map(
      //                     (e: any, index: number) =>
      //                       index !== 0 && (
      //                         <div key={e?.id} style={{ marginTop: 5 }}>
      //                           {e?.name}
      //                           <br />
      //                         </div>
      //                       )
      //                   )}
      //                 </>
      //               }
      //             >
      //               + {text?.length - 1}
      //             </Tooltip>
      //           </div>
      //         )}
      //       </>
      //     ) : (
      //       "-"
      //     )}
      //   </div>
      // ),
    },
    {
      title: "Holders",
      dataIndex: "employees",
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <Avatar.Group
          className="avatarGroup"
          size="small"
          max={{
            count: 2,
            style: {
              color: "#161616",
              backgroundColor: "#fff",
              cursor: "pointer",
              fontSize: "10px",
              fontWeight: "500",
            },
          }}
        >
          {text?.length > 0
            ? text?.map(
                (e: {
                  first_name: string;
                  last_name: string;
                  id: string;
                  image: { url: string };
                }) => (
                  <Fragment key={e?.id}>
                    {e?.image ? (
                      <Avatar src={e?.image?.url} />
                    ) : (
                      <Avatar>
                        {getInitials(e?.first_name, e?.last_name)}
                      </Avatar>
                    )}
                  </Fragment>
                )
              )
            : "-"}
        </Avatar.Group>
      ),
    },
    {
      title: "Department",
      dataIndex: "department_name",
      sorter: (a, b) => a?.department_name?.localeCompare(b?.department_name),
      sortIcon: () => <SortIcon />,
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
      sortIcon: () => <SortIcon />,
      render: (text: keyof typeof STATUS_TYPE) => {
        let className = "";
        if (text?.includes("paused")) {
          className = "orange_tag";
        } else if (text?.includes("pending")) {
          className = "blue_tag";
        } else if (text?.includes("approved")) {
          className = "green_tag";
        } else {
          className = "defaultTag";
        }
        return text ? (
          <Tag className={`defaultTag ${className}`}>
            <Badge className={`default_badge`} /> {STATUS_TYPE[text]}
          </Tag>
        ) : (
          "-"
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      width: 52,
      render: (_text, record) => (
        <Dropdown
          menu={{ items }}
          trigger={["click"]}
          placement="bottomRight"
          className="actionButton"
        >
          <Button onClick={() => setTableRecord(record)}>
            <Image
              src="/images/vertical_dots.svg"
              width={3}
              height={15}
              alt="vertical dots"
              preview={false}
            />
          </Button>
        </Dropdown>
      ),
    },
  ];

  const updateApi = () => {
    const payload = {
      offset: pagination > 1 ? limit * (pagination - 1) : pagination - 1,
      limit: limit,
      companyId: companyId,
      status: undefined,
      roles: undefined,
      departments: undefined,
      employees: undefined,
    };
    dispatch(getJobsListing(payload));

    setJobCreate(false);
  };

  const createJob = {
    visibility: jobCreate,
    class: "upload_photo_modal",
    content: (
      <CreateJob
        title={<>Create Job</>}
        onClose={() => setJobCreate(false)}
        btnText="Create Job"
        updateApi={updateApi}
        companyId={companyId}
      />
    ),
    width: 600,
    onCancel: () => setJobCreate(false),
  };

  const handleDeleteJob = async () => {
    setDeleteLoading(true);
    deleteJob(tableRecord?.id as string)
      .then(() => {
        const payload = {
          offset: pagination > 1 ? limit * (pagination - 1) : pagination - 1,
          limit: limit,
          companyId: companyId,
        };
        dispatch(getJobsListing(payload));
        setIsDeleteModalOpen(false);
        setDeleteLoading(false);
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setDeleteLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setDeleteLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  const deleteConfirmationPopup = {
    visibility: isDeleteModalOpen,
    class: "delete_confirmation_modal",
    content: (
      <ConfirmDelete
        title="Confirm Deletion"
        heading="Are you sure you want to delete this job?"
        onClose={() => setIsDeleteModalOpen(false)}
        btnText="Delete job"
        btnApi={handleDeleteJob}
        loading={deleteLoading}
      />
    ),
    width: 520,
    onCancel: () => setIsDeleteModalOpen(false),
  };

  useEffect(() => {
    if (companyId) {
      const payload = {
        offset: pagination > 1 ? limit * (pagination - 1) : pagination - 1,
        limit: limit,
        companyId: companyId,
        status: undefined,
        roles: undefined,
        departments: undefined,
        employees: undefined,
      };
      dispatch(getJobsListing(payload));
    }
  }, [pagination, limit, dispatch, companyId]);

  useEffect(() => {
    if (companyId) {
      dispatch(getJobFilters(companyId));
    }
  }, [dispatch, companyId]);

  const onPageSize = useCallback((currentPage: number, pageSize: number) => {
    setPagination(currentPage);
    setLimit(pageSize);
  }, []);

  const onPageChange = useCallback((pageNumber: number, pageSize: number) => {
    setPagination(pageNumber);
    setLimit(pageSize);
  }, []);

  useEffect(() => {
    if (jobsFilterOptions?.status) {
      const statusArray: { label: string; value: string }[] = [];
      jobsFilterOptions?.status?.map(
        (e: { label: keyof typeof STATUS_TYPE_DROPDOWN; value: string }) => {
          statusArray.push({
            label: STATUS_TYPE_DROPDOWN[e?.label],
            value: e?.value,
          });
        }
      );
      setStatusDropdown(statusArray);
    }
  }, [jobsFilterOptions?.status]);

  return (
    <DashboardTemplate loading={jobsListData?.loading}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Header companyId={companyId} updateApi={updateApi} />
        </Col>
        <Col span={24} className="invoice_filter">
          <Row
            gutter={[16, 16]}
            justify="space-between"
            className="filter_btns"
          >
            <Col span={24}>
              <div className="top_filter">
                <CompanyLinks companyId={companyId} urlType={urlType} />
                <div className="filter_right">
                  <div
                    className="filter_btn"
                    style={{ display: "flex", gap: "6px" }}
                  >
                    <span className="filterTitle"> Filter: </span>
                    <Select
                      placeholder="Roles"
                      suffixIcon={<DropdownIcon />}
                      popupMatchSelectWidth={false}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      maxTagCount="responsive"
                      options={jobsFilterOptions?.role}
                    />
                    <Select
                      placeholder="Holders"
                      suffixIcon={<DropdownIcon />}
                      popupMatchSelectWidth={false}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      maxTagCount="responsive"
                      options={jobsFilterOptions?.employee}
                    />
                    <Select
                      placeholder="Departments"
                      suffixIcon={<DropdownIcon />}
                      popupMatchSelectWidth={false}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      maxTagCount="responsive"
                      options={jobsFilterOptions?.department}
                    />
                    <Select
                      placeholder="Status"
                      suffixIcon={<DropdownIcon />}
                      popupMatchSelectWidth={false}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      maxTagCount="responsive"
                      options={statusDropdown}
                    />
                    <Image src="/images/filter_btn.svg" preview={false} />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          {jobsListData?.data?.length > 0 && (
            <Table
              className="dashboard_table"
              columns={columns}
              dataSource={jobsListData?.data}
              pagination={{
                defaultPageSize: limit,
                total: jobsListData?.count,
                current: pagination,
                onChange: onPageChange,
                onShowSizeChange: onPageSize,
              }}
              rowKey="id"
            />
          )}

          {jobsListData?.data?.length === 0 && (
            <EmptyCard
              image="/images/empty/no-results.svg"
              title="There are no jobs"
              description='Click the "Create job" button to add a new job.'
              buttonText="Create job"
              showPopUp={(e) => setJobCreate(e)}
            />
          )}
        </Col>
      </Row>

      <Popup {...createJob} />
      <Popup {...deleteConfirmationPopup} />
    </DashboardTemplate>
  );
}
