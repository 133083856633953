import { Button, Card, Col, Row, Tag } from "antd";
import SessionFlowerFinalized from "../SessionFlowerFinalized";
import { SessionTypeResponse } from "../../../../../../../organisms/MyJob/ducks/types";
import { useNavigate } from "react-router-dom";

type PropsType = {
  data: any;
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  activeData?: any;
  activeNumber: number;
  petalData: any;
  sessionId: string;
  petalTeams: any;
  sessionData: SessionTypeResponse;
};

export default function FpSessionCompleted(props: PropsType) {
  const {
    data,
    activeTab,
    setActiveTab,
    activeData,
    setCurrent,
    petalTeams,
    sessionData,
  } = props;
  const navigate = useNavigate();

  return (
    <Row>
      <Col span={15}>
        <div
          className="flower_wrapper finalizedFlower"
          style={{ height: "calc(100vh - 130px)" }}
        >
          <SessionFlowerFinalized
            data={data}
            setActiveTab={setActiveTab}
            activeTab={activeTab}
            setCurrent={setCurrent}
            activeData={activeData}
            petalTeams={petalTeams}
          />
          <div className="flower_position">{sessionData?.job?.name}</div>
        </div>
      </Col>
      <Col span={9}>
        <Card className="session_completed">
          <h1>Excellent, you’ve completed the Flower Power session!</h1>
          <h3>
            List of roles <div className="total_roles">{data?.length}</div>
          </h3>

          <div className="roles_tag_wrapper">
            {data?.length > 0 &&
              data?.map((e: any) => (
                <div className="roles_tag" key={e?.id}>
                  <Tag className="">{e?.finalized_name}</Tag>
                </div>
              ))}
          </div>

          <h3 className="invite_sent">
            Participants' next step is to complete the roles worksheet.
            <br /> We've already sent them the invites to that session.
          </h3>

          <Button className="icon_dark_btn" onClick={() => navigate(-1)}>
            Go Home
          </Button>
        </Card>
      </Col>
    </Row>
  );
}
