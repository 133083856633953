import { useEffect } from "react";
import {
  Button,
  Layout,
  Menu,
  Image,
  Spin,
  Input,
  Dropdown,
  Avatar,
} from "antd";
import type { MenuProps } from "antd";
import { Link, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { getUserDetails } from "../../organisms/MyAccount/ducks/actions";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { SearchIcon } from "../../atoms/CustomIcons";

const { Header, Content } = Layout;
const antIcon = <Loading3QuartersOutlined spin />;

type MenuItem = Required<MenuProps>["items"][number];
type dashboardProps = {
  loading?: boolean;
  children?: React.ReactNode;
};

const DashboardTemplate = (props: dashboardProps) => {
  const { loading, children } = props;
  const location = useLocation();
  const dispatch = useAppDispatch();
  const accountData = useAppSelector((state) => state?.account?.accountData);

  const logoutHandler = () => {
    localStorage.clear();
    window.location.assign("/login");
  };

  const companyAdmin: MenuItem[] = [
    {
      label: (
        <Link to="/my-job">
          <img src="/images/userIcon.svg" width={16} alt="" /> My job
        </Link>
      ),
      key: "my-job",
    },
    {
      label: (
        <Link to={`/company-detail/jobs/${accountData?.company_id}`}>
          <img src="/images/settingsIcon.svg" width={16} alt="" /> My company
        </Link>
      ),
      key: "company-detail",
    },
  ];

  const superAdmin: MenuItem[] = [
    {
      label: (
        <Link to="/companies">
          <img src="/images/userIcon.svg" width={16} alt="" /> Companies
        </Link>
      ),
      key: "companies",
    },
    {
      label: (
        <Link to="/system-settings">
          <img src="/images/settingsIcon.svg" width={16} alt="" /> System
          settings
        </Link>
      ),
      key: "system-settings",
    },
  ];

  const itemsStatus: MenuProps["items"] = [
    {
      label: <Link to="/my-account"> My account details </Link>,
      key: "1",
    },
    {
      label: <Link to="/change-password"> Change password </Link>,
      key: "2",
    },
    {
      label: (
        <Button className="no_border" onClick={() => logoutHandler()}>
          Logout{" "}
        </Button>
      ),
      key: "3",
    },
  ];

  useEffect(() => {
    dispatch(getUserDetails());
  }, [dispatch]);

  const getSelectedMenuItemKey = () => {
    if (
      location.pathname.includes("company-detail") &&
      accountData?.type === "SUPER_ADMIN"
    ) {
      return "companies";
    }

    if (
      location.pathname.includes("job-detail") &&
      accountData?.type === "SUPER_ADMIN"
    ) {
      return "companies";
    }

    if (
      location.pathname.includes("job-detail") &&
      accountData?.type === "COMPANY_ADMIN"
    ) {
      return "company-detail";
    }

    return location?.pathname?.split("/")[1]; // Extract the first path segment
  };

  const currentPath = getSelectedMenuItemKey();

  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <Layout className="main_layout">
        <Header style={{ display: "flex", alignItems: "center" }}>
          <Link to="/" className="logo_area">
            <Image src="/images/login/fp_logo.svg" preview={false} />{" "}
          </Link>
          <Menu
            mode="horizontal"
            items={
              accountData?.type === "SUPER_ADMIN" ? superAdmin : companyAdmin
            }
            selectedKeys={[currentPath]}
          />
          <div style={{ display: "flex", gap: "16px", marginLeft: "auto" }}>
            <div className="search_filter">
              <Input
                placeholder="Search jobs, roles, employees"
                prefix={<SearchIcon />}
              />
            </div>
            <div className="user_info">
              {/* <Dropdown overlay={notificationMenu} trigger={["click"]}>
                <div className="notificationButton">
                  <Image src="/images/login/bell_icon.svg" preview={false} />
                </div>
              </Dropdown> */}
              <div className="notificationButton">
                <Image src="/images/login/bell_icon.svg" preview={false} />
              </div>
              <Dropdown
                menu={{ items: itemsStatus }}
                trigger={["click"]}
                placement="bottomLeft"
                overlayClassName="ft_dropdown"
              >
                <Button
                  type="text"
                  className="user_info_button"
                  onClick={(e) => e.preventDefault()}
                >
                  <div className="userName">
                    <span>
                      {`${accountData?.first_name} ${accountData?.last_name}`}
                    </span>
                    {accountData?.image?.url ? (
                      <Image
                        src={accountData?.image?.url}
                        alt="icon"
                        width={36}
                        height={36}
                        preview={false}
                      />
                    ) : (
                      <Avatar shape="square" size={36} />
                    )}
                  </div>
                </Button>
              </Dropdown>
            </div>
          </div>
        </Header>
        <Content className="content_area">{children}</Content>
      </Layout>
    </Spin>
  );
};

export default DashboardTemplate;
