import { BASE_URLS, ENVIRONMENT_MODE } from "../../../config/constants";
import { AxiosResponse } from "axios";
import axios from "../../../config/axiosInterceptor";
import { CreateCompanyType } from "./types";

const baseURL = BASE_URLS[ENVIRONMENT_MODE].REACT_APP_API_BASE_URL;

export const createCompany = (
  payload: CreateCompanyType
): Promise<AxiosResponse<{ message: string; success: boolean }>> => {
  return axios.post(`${baseURL}createCompany`, payload);
};

export const updateCompanyById = (
  payload: CreateCompanyType,
  companyId: string
): Promise<AxiosResponse<{ message: string; success: boolean }>> => {
  return axios.patch(`${baseURL}updateCompanyById/${companyId}`, payload);
};

export const deleteCompany = (
  companyId: string
): Promise<AxiosResponse<string>> => {
  return axios.delete(`${baseURL}deleteCompany/${companyId}`);
};
