import React, { useEffect } from "react";
import { Layout, Spin } from "antd";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { useAppDispatch } from "../../config/hooks";
import { getUserDetails } from "../../organisms/MyAccount/ducks/actions";

const { Content } = Layout;
const antIcon = <Loading3QuartersOutlined spin />;

type layoutProps = {
  loading: boolean;
  children: React.ReactNode;
};

const FullPageLayout = (props: layoutProps) => {
  const { loading, children } = props;
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getUserDetails());
  }, [dispatch]);

  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <Layout className="full_page_layout">
        <Content className="content_area">{children}</Content>
      </Layout>
    </Spin>
  );
};

export default FullPageLayout;
