import React, { Fragment } from "react";
import { Form, Radio } from "antd";
import { Controller } from "react-hook-form";
import { InfoCircleOutlined } from "@ant-design/icons";

type OptionType = {
  label?: string;
  value?: string;
};
export interface RadioGroupProps {
  fieldName: string;
  isRequired?: boolean;
  label?: string;
  validate?: any;
  validMessage?: any;
  className?: string;
  control: any;
  initValue?: any;
  rules?: any;
  iProps?: any;
  onChangeRadio: any;
  optionsData?: Array<OptionType>;
  circleRadio?: boolean;
  tooltipTitle?: any;
  colorRadio?: boolean;
}

const RadioGroup: React.FunctionComponent<RadioGroupProps> = ({
  fieldName,
  isRequired,
  label,
  validate,
  validMessage,
  className,
  control,
  initValue,
  rules,
  iProps,
  optionsData,
  onChangeRadio,
  tooltipTitle,
}: RadioGroupProps) => {
  return (
    <Form.Item
      required={isRequired ?? false}
      validateStatus={validate}
      help={validMessage}
      label={label}
      valuePropName="checked"
      className={className}
      tooltip={
        tooltipTitle && {
          title: tooltipTitle,
          icon: (
            <InfoCircleOutlined
              style={{ color: "#505560", fontSize: "14px" }}
            />
          ),
        }
      }
    >
      <Controller
        rules={rules}
        name={fieldName}
        control={control}
        defaultValue={initValue ?? ""}
        render={({ field: { onChange, value } }) => (
          <Radio.Group
            value={value}
            {...iProps}
            onChange={(e) => {
              onChange(e.target.value);
              onChangeRadio(e);
            }}
          >
            {optionsData &&
              optionsData?.map((e: any) => (
                <Fragment key={e?.value}>
                  <Radio.Button value={e?.value}>{e?.label}</Radio.Button>
                </Fragment>
              ))}
          </Radio.Group>
        )}
      />
    </Form.Item>
  );
};

export default RadioGroup;
