import { Action, Dispatch } from "redux";
import axios from "../../../config/axiosInterceptor";
import { BASE_URLS, ENVIRONMENT_MODE } from "../../../config/constants";
import * as action_types from "./constants";
import { CompanyList } from "./types";

const baseURL = BASE_URLS[ENVIRONMENT_MODE].REACT_APP_API_BASE_URL;

export const getCompanyListing = (payload: CompanyList) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const { data } = await axios.post(`${baseURL}getCompanyListing`, payload);
      dispatch({
        type: action_types.COMPANIES_LIST,
        data: { ...data, loading: false },
      });
    } catch (error) {
      dispatch({
        type: action_types.COMPANIES_LIST,
        data: { loading: false },
      });
    }
  };
};
