import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const EyeInvisibleOutlinedSvg = () => (
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.4058 5.975C14.9058 4.7 12.4308 3.125 9.05576 3.125C8.83076 3.125 8.60576 3.125 8.30576 3.125C8.00576 3.125 7.78076 3.35 7.63076 3.575C7.48076 3.8 7.63076 4.175 7.78076 4.4L14.0808 10.7C14.2308 10.85 14.3808 10.925 14.6058 10.925C14.7558 10.925 14.9058 10.85 14.9808 10.775C15.4308 10.475 15.8058 10.175 16.2558 9.8C16.8558 9.35 17.1558 8.6 17.1558 7.85C17.3058 7.175 17.0058 6.5 16.4058 5.975Z"
      fill="#4C4D51"
    />
    <path
      d="M13.0309 10.85L6.13091 4.025L3.28091 1.1C2.98091 0.8 2.53091 0.8 2.23091 1.1C1.93091 1.4 1.93091 1.85 2.23091 2.15L4.33091 4.25C3.35591 4.7 2.53091 5.3 1.70591 5.975C1.10591 6.5 0.805908 7.175 0.805908 8C0.805908 8.75 1.10591 9.5 1.70591 9.95C3.20591 11.225 5.68091 12.8 9.05591 12.8C10.1809 12.8 11.2309 12.65 12.2809 12.275L14.9059 14.9C15.0559 15.05 15.2809 15.125 15.4309 15.125C15.6559 15.125 15.8059 15.05 15.9559 14.9C16.2559 14.6 16.2559 14.15 15.9559 13.85L13.0309 10.85ZM9.05591 9.725C8.08091 9.725 7.33091 8.975 7.33091 8C7.33091 7.775 7.33091 7.625 7.40591 7.4L9.58091 9.575C9.43091 9.65 9.28091 9.725 9.05591 9.725Z"
      fill="#4C4D51"
    />
  </svg>
);

const EyeInvisibleOutlined = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={EyeInvisibleOutlinedSvg} {...props} />
);

export default EyeInvisibleOutlined;
