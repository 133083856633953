import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const JobsSvg = () => (

<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M5.625 3.9375C5.625 3.34076 5.86205 2.76847 6.28401 2.34651C6.70597 1.92455 7.27826 1.6875 7.875 1.6875H10.125C10.7217 1.6875 11.294 1.92455 11.716 2.34651C12.1379 2.76847 12.375 3.34076 12.375 3.9375V4.09125C13.0747 4.155 13.7678 4.239 14.4555 4.34175C15.546 4.50525 16.3125 5.454 16.3125 6.5295V8.80425C16.3125 9.7125 15.762 10.5683 14.8605 10.8683C12.9704 11.4956 10.9915 11.8145 9 11.8125C6.9525 11.8125 4.98225 11.481 3.1395 10.8683C2.238 10.5683 1.6875 9.7125 1.6875 8.80425V6.5295C1.6875 5.454 2.454 4.5045 3.5445 4.34175C4.23547 4.23842 4.92925 4.15488 5.625 4.09125V3.9375ZM11.25 3.9375V4.005C9.75138 3.91399 8.24862 3.91399 6.75 4.005V3.9375C6.75 3.63913 6.86853 3.35298 7.0795 3.142C7.29048 2.93103 7.57663 2.8125 7.875 2.8125H10.125C10.4234 2.8125 10.7095 2.93103 10.9205 3.142C11.1315 3.35298 11.25 3.63913 11.25 3.9375ZM9 10.125C9.14918 10.125 9.29226 10.0657 9.39775 9.96025C9.50324 9.85476 9.5625 9.71169 9.5625 9.5625C9.5625 9.41332 9.50324 9.27024 9.39775 9.16475C9.29226 9.05926 9.14918 9 9 9C8.85082 9 8.70774 9.05926 8.60225 9.16475C8.49676 9.27024 8.4375 9.41332 8.4375 9.5625C8.4375 9.71169 8.49676 9.85476 8.60225 9.96025C8.70774 10.0657 8.85082 10.125 9 10.125Z" fill="white"/>
<path d="M2.25 13.8V11.703C2.42086 11.7965 2.59985 11.8743 2.78475 11.9355C4.78927 12.601 6.88789 12.9394 9 12.9375C11.169 12.9375 13.26 12.5865 15.2153 11.9363C15.4043 11.8733 15.5828 11.7945 15.75 11.703V13.8C15.75 14.889 14.9647 15.846 13.8577 15.9923C12.2677 16.2038 10.6462 16.3125 9 16.3125C7.37546 16.3128 5.75268 16.2058 4.14225 15.9923C3.03525 15.846 2.25 14.889 2.25 13.8Z" fill="white"/>
</svg>

);

const JobsIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={JobsSvg} {...props} />
);

export default JobsIcon;
