import { Row, Col, Button, Card, Form, Checkbox, Image } from "antd";
import { useForm } from "react-hook-form";
import { DropdownIcon } from "../../../../../atoms/CustomIcons";
import TextEditor from "../../../../../molecules/FormItems/TextEditor";
import { useCallback, useEffect, useState } from "react";
import { getUserDetails } from "../../../../MyAccount/ducks/actions";
import { useAppDispatch, useAppSelector } from "../../../../../config/hooks";
import SelectField from "../../../../../molecules/FormItems/SelectField";
import {
  getCompanyFieldsByIdDrop,
  getDropdownsByCompany,
} from "../../../ducks/actions";
import { createRole } from "../../../ducks/services";
import { CreateRole } from "../../../ducks/types";
import SuccessMessage from "../../../../../atoms/Toasts/SuccessMessage";
import { AxiosError } from "axios";
import ErrorMessage from "../../../../../atoms/Toasts/ErrorMessage";
import InputField from "../../../../../molecules/FormItems/InputField";

interface PropsType {
  companyId: string;
  jobId: string;
  setSelectedRoleName: React.Dispatch<React.SetStateAction<string>>;
  setActiveTab?: React.Dispatch<React.SetStateAction<string>>;
}

export default function AddRoleForm(props: PropsType) {
  const { companyId, jobId, setSelectedRoleName, setActiveTab } = props;
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateRole>();
  const [roleName, setRoleName] = useState("");
  const [roleSelect, setRoleSelect] = useState(false);
  const settingData = useAppSelector(
    (state) => state?.companyDetails?.fieldsDropdown
  );
  const dropdownData = useAppSelector(
    (state) => state?.companyDetails?.roleDropdown
  );

  const optionsForDropdown = [
    { value: "Option 1", label: "Option 1" },
    { value: "Option 2", label: "Option 2" },
    { value: "Option 3", label: "Option 3" },
  ];

  const onSubmit = (val: CreateRole) => {
    const payload = {
      name: roleName,
      jobs: [jobId],
      rolePurposeStatement: val?.rolePurposeStatement,
      objectives: val?.objectives,
      keyResults: val?.keyResults,
      procedures: val?.procedures,
      workflows: val?.workflows,
      skills: val?.skills,
      technologies: val?.technologies,
      teams: val?.teams,
      entities: val?.entities,
      meetings: val?.meetings,
      companyId: companyId,
      coachedBy: val?.coachedBy ?? undefined,
      department: val?.department,
    };

    createRole(payload)
      .then(() => {
        SuccessMessage("Role created.");
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          ErrorMessage("Something went wrong");
        }
      });
  };

  useEffect(() => {
    if (companyId) {
      const payload = {
        companyId: companyId,
      };
      const dropdownPayload = {
        companyId: companyId,
        sendRoles: true,
        sendUsers: true,
        sendJobs: true,
      };
      dispatch(getCompanyFieldsByIdDrop(payload));
      dispatch(getDropdownsByCompany(dropdownPayload));
    }
  }, [dispatch, companyId]);

  useEffect(() => {
    dispatch(getUserDetails());
  }, [dispatch]);

  const arrayDropdown = useCallback(
    (moduleName: string) => {
      const filteredArray = settingData?.filter(
        (e: { module: string; name: string; value: string }) =>
          e?.module === moduleName
      );
      return filteredArray?.map((e: { name: string; value: string }) => ({
        label: e?.name,
        value: e?.value,
      }));
    },
    [settingData]
  );

  console.log("roleName", roleName);

  return (
    <div className="contentPanel">
      <Card
        className="fp_layout_content"
        style={{ height: "100%", maxWidth: "992px", margin: "0 auto" }}
      >
        <div
          className="cardContent"
          style={{
            maxWidth: "992px",
            paddingLeft: "68px",
            paddingRight: "68px",
            border: "unset",
            marginTop: "0px",
            paddingTop: "0px",
          }}
        >
          <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
            <Row>
              <Col span={24}>
                {!roleSelect && (
                  <div className="role_name">
                    <InputField
                      fieldName="name"
                      label=""
                      control={control}
                      initValue=""
                      iProps={{
                        placeholder: "Specify role name",
                      }}
                      isRequired
                      rules={{
                        required: "Role name is required.",
                      }}
                      validate={errors.name && "error"}
                      validMessage={errors.name && errors.name.message}
                      onInputChange={(e: any) => setRoleName(e.target.value)}
                    />
                    {roleName && (
                      <Button
                        type="text"
                        htmlType="button"
                        onClick={() => {
                          setRoleSelect(true);
                          setSelectedRoleName(roleName);
                        }}
                        className="role_btn"
                      >
                        <Image
                          src="/images/check-icon.svg"
                          preview={false}
                          width={16}
                        />
                      </Button>
                    )}
                  </div>
                )}
                {roleName && roleSelect && (
                  <div className="role_title">{roleName}</div>
                )}
              </Col>
              <Col span={24}>
                <h4
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    Role purpose statement <span className="red_color">*</span>
                  </div>
                  <div>
                    <h4 style={{ display: "flex", gap: "6px" }}>
                      <Image
                        src="/images/MagicWand.svg"
                        alt="Generate with AI"
                        width={16}
                        height={16}
                        preview={false}
                      />
                      Generate with AI
                    </h4>
                  </div>
                </h4>
                <TextEditor
                  fieldName="rolePurposeStatement"
                  control={control}
                  initValue=""
                  label=""
                  iProps={{
                    placeholder: "Explain the Role purpose statement",
                  }}
                  isRequired
                  rules={{
                    required: "Job name is required.",
                  }}
                  validate={errors.rolePurposeStatement && "error"}
                  validMessage={
                    errors.rolePurposeStatement &&
                    errors.rolePurposeStatement.message
                  }
                />
              </Col>
              <Col span={24}>
                <h4
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    Objectives <span className="red_color">*</span>
                  </div>
                  <div>
                    <h4>
                      <Checkbox style={{ marginRight: "4px" }} /> N/A
                    </h4>
                  </div>
                </h4>
                <TextEditor
                  fieldName="objectives"
                  control={control}
                  initValue=""
                  // label="Objectives"
                  // tooltipTitle=""
                  iProps={{
                    placeholder:
                      "Example: Increase market share by developing and implementing effective sales strategies and fostering strong customer relationships.",
                  }}
                  isRequired
                  rules={{
                    required: "Error message",
                  }}
                />
              </Col>
              <Col span={24}>
                <h4 style={{ position: "absolute", right: "0", top: "-2px" }}>
                  <Checkbox style={{ marginRight: "4px" }} /> N/A
                </h4>

                <SelectField
                  fieldName="keyResults"
                  label="Key Results"
                  control={control}
                  selectOption={arrayDropdown("KEY_RESULT")}
                  className="fp_select"
                  mode="multiple"
                  iProps={{
                    placeholder: "Select key results",
                    suffixIcon: <DropdownIcon />,
                  }}
                />
              </Col>
              <Col flex="0 0 992px">
                <h4
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    Procedures/WIs/Policies <span className="red_color">*</span>
                  </div>
                  <div>
                    <h4>
                      <Checkbox style={{ marginRight: "4px" }} /> N/A
                    </h4>
                  </div>
                </h4>
                <TextEditor
                  fieldName="procedures"
                  control={control}
                  initValue=""
                  // label="Procedures"
                  // tooltipTitle=""
                  iProps={{
                    placeholder:
                      "Example: Develop detailed project schedules with tasks, Establish milestones and deliverable dates, Allocate necessary resources.",
                  }}
                  isRequired
                  rules={{
                    required: "Error message",
                  }}
                />
              </Col>
              <Col flex="0 0 992px">
                <h4 style={{ position: "absolute", right: "0", top: "-2px" }}>
                  <Checkbox style={{ marginRight: "4px" }} /> N/A
                </h4>

                <SelectField
                  fieldName="workflows"
                  label="Workflows"
                  control={control}
                  selectOption={optionsForDropdown}
                  mode="multiple"
                  iProps={{
                    placeholder: "Select workflows",
                    suffixIcon: <DropdownIcon />,
                  }}
                />
              </Col>
              <Col flex="0 0 992px">
                <h4 style={{ position: "absolute", right: "0", top: "-2px" }}>
                  <Checkbox style={{ marginRight: "4px" }} /> N/A
                </h4>

                <SelectField
                  fieldName="skills"
                  label="Skills"
                  control={control}
                  selectOption={arrayDropdown("SKILL")}
                  mode="multiple"
                  iProps={{
                    placeholder: "Select skills",
                    suffixIcon: <DropdownIcon />,
                  }}
                />
              </Col>
              <Col flex="0 0 992px">
                <h4 style={{ position: "absolute", right: "0", top: "-2px" }}>
                  <Checkbox style={{ marginRight: "4px" }} /> N/A
                </h4>

                <SelectField
                  fieldName="technologies"
                  label="Technologies"
                  control={control}
                  selectOption={arrayDropdown("TECHNOLOGIES")}
                  mode="multiple"
                  iProps={{
                    placeholder: "Select Technologies",
                    suffixIcon: <DropdownIcon />,
                  }}
                />
              </Col>
              <Col flex="0 0 992px">
                <h4 style={{ position: "absolute", right: "0", top: "-2px" }}>
                  <Checkbox style={{ marginRight: "4px" }} /> N/A
                </h4>

                <SelectField
                  fieldName="teams"
                  label="Teams"
                  control={control}
                  selectOption={arrayDropdown("TEAMS")}
                  mode="multiple"
                  iProps={{
                    placeholder: "Select teams",
                    suffixIcon: <DropdownIcon />,
                  }}
                />
              </Col>
              <Col flex="0 0 992px">
                <h4
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    Entities <span className="red_color">*</span>
                  </div>
                  <h4>
                    <Checkbox style={{ marginRight: "4px" }} /> N/A
                  </h4>
                </h4>
                <TextEditor
                  fieldName="entities"
                  control={control}
                  initValue=""
                  // label="Entities"
                  // tooltipTitle=""
                  iProps={{
                    placeholder:
                      "Example: Parents, Vendors, Hiring Coordinator",
                  }}
                  isRequired
                  rules={{
                    required: "Error message",
                  }}
                />
              </Col>
              <Col flex="0 0 992px">
                <h4 style={{ position: "absolute", right: "0", top: "-2px" }}>
                  <Checkbox style={{ marginRight: "4px" }} /> N/A
                </h4>

                <SelectField
                  fieldName="meetings"
                  label="Meetings"
                  control={control}
                  selectOption={arrayDropdown("MEETINGS")}
                  mode="multiple"
                  iProps={{
                    placeholder: "Select meetings",
                    suffixIcon: <DropdownIcon />,
                  }}
                />
              </Col>
            </Row>
            <Row
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Col flex="0 0 395px">
                <h4 style={{ position: "absolute", right: "0", top: "-2px" }}>
                  <Checkbox style={{ marginRight: "4px" }} /> N/A
                </h4>

                <SelectField
                  fieldName="coachedBy"
                  label="Coached by"
                  control={control}
                  selectOption={dropdownData?.roles}
                  iProps={{
                    placeholder: "Coached by",
                    suffixIcon: <DropdownIcon />,
                  }}
                />
              </Col>
              <Col flex="0 0 395px">
                <SelectField
                  fieldName="department"
                  label="Department"
                  control={control}
                  selectOption={arrayDropdown("DEPARTMENT")}
                  iProps={{
                    placeholder: "Select department",
                    suffixIcon: <DropdownIcon />,
                  }}
                />
              </Col>
              <Col
                className="fp_footer"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <div>
                  {setActiveTab && (
                    <Button onClick={() => setActiveTab("2")}>next</Button>
                  )}
                  <Button
                    htmlType="submit"
                    type="primary"
                    className="icon_dark_btn"
                  >
                    Create Role
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </Card>
    </div>
  );
}
