import React from "react";
import { Form } from "antd";
import { Controller } from "react-hook-form";
import "react-quill/dist/quill.snow.css";
import { InfoIconOutlined } from "../../../atoms/CustomIcons";
import ReactQuill from "react-quill";

export interface InputFieldProps {
  fieldName: string;
  isRequired?: boolean;
  label?: string;
  validate?: any;
  validMessage?: string | any;
  className?: string;
  control: any;
  initValue?: any;
  rules?: any;
  iProps?: any;
  prefix?: any;
  disabled?: boolean;
  tooltipTitle?: string | any;
  optionalLabel?: string;
  hintMessage?: string;
}

const TextEditor: React.FunctionComponent<InputFieldProps> = ({
  fieldName,
  isRequired,
  label,
  validate,
  validMessage,
  className,
  control,
  initValue,
  rules,
  tooltipTitle,
  optionalLabel,
  hintMessage,
  iProps,
}: InputFieldProps) => {
  return (
    <Form.Item
      required={isRequired ?? false}
      label={label}
      validateStatus={validate}
      help={validMessage}
      className={className}
      tooltip={
        tooltipTitle && {
          title: tooltipTitle,
          icon: (
            <InfoIconOutlined style={{ color: "#505560", fontSize: "14px" }} />
          ),
        }
      }
    >
      <Controller
        name={fieldName}
        control={control}
        defaultValue={initValue ?? ""}
        rules={rules}
        render={({ field: { onChange, value } }) => (
          <>
            <ReactQuill
              theme="snow"
              onChange={(description: any) => onChange(description)}
              value={value || ""}
              readOnly={iProps?.disabled}
            />
            {optionalLabel && (
              <div className="optional-label">{optionalLabel}</div>
            )}
            {iProps?.disabled && <div className="disabledTextEditor" />}
            {hintMessage && <div className="hint-label">{hintMessage}</div>}
          </>
        )}
      />
    </Form.Item>
  );
};

export default TextEditor;
