import {
  Avatar,
  Badge,
  Breadcrumb,
  Button,
  Col,
  Dropdown,
  Image,
  Row,
  Tag,
} from "antd";
import DashboardTemplate from "../../../../templates/Dashboard";
import Meta from "antd/es/card/Meta";
import { ArrowIconRight } from "../../../../atoms/CustomIcons";

const myJobDropdown = [
  {
    key: "1",
    label: <Button> Edit </Button>,
  },
  {
    key: "2",
    label: <Button> Deactivate </Button>,
  },
  {
    key: "3",
    label: <Button> Delete </Button>,
  },
];

const roles = [
  "Face of School",
  "Safety",
  "Staff Scheduling",
  "Payroll",
  "GM Communication",
  "Shift Coverage",
  "GM Communication",
  "Face of School",
  "Safety",
  "Staff Scheduling",
  "Payroll",
  "GM Communication",
  "Shift Coverage",
  "GM Communication",
];

export default function EmployeeDetails() {
  return (
    <DashboardTemplate loading={false}>
      <Row className="secondary_header">
        <Col span={24}>
          <Breadcrumb
            separator={<ArrowIconRight />}
            items={[
              {
                href: "/",
                title: (
                  <>
                    <Image
                      src="/images/breadcrumb_rightarrow.svg"
                      preview={false}
                      width={16}
                      height={16}
                      style={{ marginRight: "14px" }}
                    />
                    My company
                  </>
                ),
              },
              {
                title: "Employee details",
              },
            ]}
          />
          <div className="emp_profile">
            <div className="emp_info">
              <Meta
                avatar={
                  <Image
                    src="/images/avatar_1.jpg"
                    width={68}
                    height={68}
                    preview={false}
                    style={{
                      borderRadius: "12px",
                      border: "1px solid #ECEDF3",
                    }}
                  />
                }
                title="James George"
                description="james.george@gmail.com"
                className="headingH2"
              />
              <Tag
                className="defaultTag white_tag"
                style={{ marginTop: "4px", fontWeight: "400" }}
              >
                Individual Contributor
              </Tag>
            </div>
            <div className="right_top_job">
              <Tag className="defaultTag green_tag">
                {/* <span className="ant-badge default_badge"></span>  */}
                Active
              </Tag>

              <div className="detail_dropdown">
                <Dropdown
                  menu={{ items: myJobDropdown }}
                  trigger={["click"]}
                  placement="bottomRight"
                  className="actionButton"
                  overlayClassName="full_width_btn"
                >
                  <Button>
                    <Image
                      src="/images/vertical_dots.svg"
                      width={3}
                      height={15}
                      alt="vertical dots"
                      preview={false}
                    />
                  </Button>
                </Dropdown>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row gutter={[24, 24]} className="employeeDetail">
        <Col span={24}>
          <div className="jobTitle">
            <h3> Job: General Manager </h3>
            <Button className="icon_grey_btn">
              View full details
              <Image
                src="/images/visit_icon.svg"
                alt="arrow icon"
                width={18}
                height={18}
                preview={false}
                style={{ transform: "rotate(45deg)" }}
              />
            </Button>
          </div>
        </Col>
        <Col span={5} className="info_section">
          <ul>
            <li>
              Department: <span style={{ fontWeight: "600" }}> Sales </span>
            </li>
            <li className="card_role">
              <p className="role_title">Reports to</p>
              <div className="role_name">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  Project Owner <img src="/images/visit_icon.svg" />
                </div>
                <div>
                  <Avatar.Group
                    className="avatarGroup"
                    size="small"
                    max={{
                      count: 3,
                      style: {
                        color: "#161616",
                        backgroundColor: "#fff",
                        cursor: "pointer",
                        fontSize: "10px",
                        fontWeight: "500",
                      },
                    }}
                  >
                    <Avatar>AR</Avatar>
                    <Avatar src="/images/avatar_1.jpg" />
                    <Avatar>SN</Avatar>
                  </Avatar.Group>
                </div>
              </div>
            </li>
            <li>
              Mentored by:
              <span style={{ fontWeight: "600" }}>
                Mike Brown
                <Tag
                  className="defaultTag white_tag"
                  style={{ marginLeft: "8px", fontWeight: "400" }}
                >
                  Project owner
                </Tag>
              </span>
            </li>
          </ul>
        </Col>
        <Col span={19} className="roles_section">
          <h4>
            Roles <Badge className="count_badge"> 14 </Badge>
          </h4>
          <div className="roleAdd">
            <ul>
              {roles.map((role, index) => (
                <li key={index}>
                  <span className={`count count-${index + 1}`}>
                    {index + 1}
                  </span>{" "}
                  {role}
                </li>
              ))}
            </ul>
          </div>
        </Col>
      </Row>
    </DashboardTemplate>
  );
}
