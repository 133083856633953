import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const RolesSvg = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_5406_1217)">
      <path
        d="M16.9591 11.6917C17.3952 11.5382 17.8312 11.8453 17.964 12.4339C18.0967 12.9969 17.8502 13.6111 17.4331 13.7903C15.0821 14.7372 12.295 15.2235 9.37526 15.2235C6.09525 15.2235 3.04276 14.6093 0.540096 13.432C0.122985 13.2273 -0.10453 12.613 0.0471464 12.05C0.198823 11.487 0.653853 11.1799 1.07096 11.3846C3.36507 12.4595 6.32277 13.0481 9.37526 13.0481C12.1244 13.0481 14.7598 12.5874 16.9591 11.6917Z"
        fill="#9396A6"
      />
      <path
        d="M13.4103 4.19893L14.9586 10.661C13.227 11.101 11.3325 11.321 9.37681 11.321C7.13596 11.321 4.95623 11.0185 3.04132 10.441L4.52843 4.19893C4.79326 3.099 5.68959 2.49404 6.50445 2.90651L8.43973 3.86895C8.78604 4.03394 9.15273 4.03394 9.49904 3.86895L11.4343 2.90651C12.2492 2.49404 13.1455 3.099 13.4103 4.19893Z"
        fill="#9396A6"
      />
    </g>
    <defs>
      <clipPath id="clip0_5406_1217">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const RolesIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={RolesSvg} {...props} />
);

export default RolesIcon;
