import { Avatar, Button, Col, Row } from "antd";
import InputField from "../../../../molecules/FormItems/InputField";
import { PlusIcon } from "../../../../atoms/CustomIcons";
import TrashIcon from "../../../../atoms/CustomIcons/TrashIcon";
import { getInitials } from "../../../../config/constants";

export default (props: any) => {
  const { control, append, remove, fields, accountData, sessionData } = props;

  const initQ: any = {
    descriptor: "",
  };

  const onAdd = () => {
    append(initQ);
  };

  const removeWeekTime = (ind: number) => {
    remove(ind);
  };

  return (
    <Col span={24} className="task_list">
      <div className="tasks_section">
        <h4> Generate a list </h4>
        <p>
          Reflect and write down on what{" "}
          <b>
            {sessionData?.facilitator?.first_name ?? ""}{" "}
            {sessionData?.facilitator?.last_name ?? ""}
          </b>{" "}
          does and everything that she’s thinking about or doing - every second,
          minute, hour, week, month, quarter, and year.
        </p>

        <div className="user_profile">
          {accountData?.image?.url ? (
            <div className="participant_avatar">
              <Avatar src={accountData?.image?.url} />
            </div>
          ) : (
            <div className="participant_avatar">
              <Avatar>
                {getInitials(accountData?.first_name, accountData?.last_name)}
              </Avatar>
            </div>
          )}

          <div style={{ marginLeft: 12 }}>
            <h4>
              {" "}
              {accountData?.first_name ?? ""} {accountData?.last_name ?? ""}{" "}
            </h4>
            <p>{accountData?.job?.name}</p>
          </div>
        </div>
      </div>

      <Row className="add_more">
        <Col flex={fields?.length > 0 ? "0 0 100%" : "0 0 100%"}>
          {fields.map((item: any, ind: number) => (
            <div className="addBook_wrapper" key={item.id}>
              <div className="book_name">
                <InputField
                  fieldName={`RoleDescriptors.${ind}.descriptor`}
                  control={control}
                  initValue=""
                  iProps={{
                    placeholder:
                      "Enter responsibility, task, procedure, activity, etc.",
                  }}
                  // isRequired
                  // rules={{
                  //   required: "Book name is required.",
                  // }}
                  // validMessage={
                  //   Object.entries(errors).length > 0 &&
                  //   errors?.fav_business_books &&
                  //   errors?.fav_business_books[ind]?.businessBook &&
                  //   errors?.fav_business_books?.length &&
                  //   errors?.fav_business_books[ind]?.businessBook?.message
                  // }
                  // validate={
                  //   Object.entries(errors).length > 0 &&
                  //   errors?.fav_business_books &&
                  //   errors?.fav_business_books?.length &&
                  //   errors?.fav_business_books[ind]?.businessBook &&
                  //   "error"
                  // }
                />
              </div>

              {ind > 0 && (
                <div className="book_delete">
                  <Button
                    htmlType="button"
                    className="removeFieldButton"
                    onClick={() => removeWeekTime(ind)}
                  >
                    <TrashIcon className="darkIcon" />
                  </Button>
                </div>
              )}
            </div>
          ))}
        </Col>
        <Col span={24}>
          <Button
            htmlType="button"
            onClick={onAdd}
            className="addFieldButton"
            icon={<PlusIcon className="darkIcon" />}
          >
            Add more
          </Button>
        </Col>
      </Row>
    </Col>
  );
};
