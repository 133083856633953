import { BASE_URLS, ENVIRONMENT_MODE } from "../../../config/constants";
import axios from "../../../config/axiosInterceptor";

const baseURL = BASE_URLS[ENVIRONMENT_MODE].REACT_APP_API_BASE_URL;

export const updateCompanyFieldsById = (payload: {
  companyId: string;
  module: string;
  fields: string[];
}) => {
  return axios.patch(`${baseURL}updateCompanyFieldsById`, payload);
};
