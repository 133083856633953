import { Button, Card, Col, Row } from "antd";
import {
  RoleType,
  SessionTypeResponse,
} from "../../../../../../../organisms/MyJob/ducks/types";
import SessionFlower from "../SessionFlower";
import { useEffect, useState } from "react";
import {
  updatePetalInfoByTeamId,
  updateSessionById,
} from "../../../../../../../organisms/MyJob/ducks/services";
import { clearFlowerDataBySessionID } from "../../../../../../../organisms/MyJob/ducks/actions";
import { useAppDispatch } from "../../../../../../../config/hooks";

type PropsType = {
  data: any;
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  activeData?: any;
  activeNumber: number;
  sessionId: string;
  petalTeams: any;
  sessionData: SessionTypeResponse;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function FpSessionRole(props: PropsType) {
  const {
    data,
    activeTab,
    setActiveTab,
    activeData,
    setCurrent,
    activeNumber,
    petalTeams,
    sessionData,
    sessionId,
    setLoading,
  } = props;
  const [seriousName, setSeriousName] = useState("");
  const [funnyName, setFunnyName] = useState("");
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [newData, setNewData] = useState<RoleType[]>([]);
  const dispatch = useAppDispatch();

  const addNames = (active: boolean) => {
    if (sessionData?.last_session_screen === 5) {
      const newValue = [...(data?.sessionPetals ?? [])];
      newValue[+activeTab - 1].serious_name = seriousName ?? "";
      newValue[+activeTab - 1].funny_name = funnyName ?? "";

      setNewData(newValue);
    }
    if (active) {
      setActiveTab((+activeTab + 1).toString());
    }
    if (!active) {
      setEnableSubmit(true);
    }
  };

  useEffect(() => {
    setNewData(data?.sessionPetals);
  }, [data]);

  const submitSFNames = () => {
    setLoading(true);
    let contributions: any = [];
    if (sessionData?.last_session_screen === 5) {
      newData?.map((res: any) => {
        contributions.push({
          funny_name: res?.funny_name,
          serious_name: res?.serious_name,
          petal_id: res?.id,
        });
      });
    } else {
      contributions = petalTeams[0].contributions;
    }
    console.log("newData", sessionData?.last_session_screen, newData);

    updatePetalInfoByTeamId(petalTeams[0].id, contributions).then(() => {
      const payloadScreen = {
        last_session_screen: 6,
      };
      updateSessionById(payloadScreen, sessionId).then(() => {
        setLoading(false);
        dispatch(clearFlowerDataBySessionID());
        setCurrent(6);
      });
    });
  };

  return (
    <Row>
      <Col span={13}>
        <div className="session_flower finalizedFlower">
          <div
            className="flower_wrapper"
            style={{ height: "calc(100vh - 130px)" }}
          >
            {newData?.length > 0 && (
              <>
                <SessionFlower
                  data={newData}
                  setActiveTab={setActiveTab}
                  activeTab={activeTab}
                  setCurrent={setCurrent}
                  activeData={activeData}
                  petalTeams={petalTeams}
                />
                <div className="flower_position">{sessionData?.job?.name}</div>
              </>
            )}
          </div>
        </div>
      </Col>
      <Col span={11}>
        <Card className="session_fp_card fp_session_role session_inner_tab">
          <h4> Come up with role names </h4>
          <p>Brainstorm and enter serious and funny names for each role.</p>
          <div className="content_section">
            <div className="active_state" style={{ marginBottom: "16px" }}>
              <h6 className={`tagNumber_${activeNumber}`}> {activeNumber} </h6>
              Role
            </div>
            {petalTeams?.map((e: any) => (
              <div className="inputFields">
                <div style={{ width: "100%" }}>
                  <label> Serious name: </label>
                  <input
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setSeriousName(e.target.value)
                    }
                    className="ant-input ant-input-outlined formControl"
                    placeholder="Type a serious name here"
                    value={
                      e?.contributions.find(
                        (item1: any) => activeData?.id === item1.petal_id
                      )?.serious_name
                    }
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <label> Funny name: </label>
                  <input
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFunnyName(e.target.value)
                    }
                    className="ant-input ant-input-outlined formControl"
                    placeholder="Type a funny name here"
                    value={
                      e?.contributions.find(
                        (item1: any) => activeData?.id === item1.petal_id
                      )?.funny_name
                    }
                  />
                </div>
              </div>
            ))}

            <div className="role_description">
              <h4> Role descriptors: </h4>
              <ul>
                {activeData?.descriptors?.map((e: { descriptor: string }) => (
                  <li>{e?.descriptor}</li>
                ))}
              </ul>
            </div>
          </div>

          <div className="move_buttons">
            <Button
              htmlType="button"
              className="icon_grey_btn"
              type="primary"
              size="large"
              disabled={activeTab === "1"}
              onClick={() => {
                setActiveTab((+activeTab - 1).toString());
              }}
            >
              Previous Role
            </Button>
            {+activeTab !== newData?.length ? (
              <Button
                htmlType="button"
                className="icon_grey_btn"
                type="primary"
                size="large"
                disabled={
                  +activeTab === newData?.length ||
                  seriousName === "" ||
                  funnyName === ""
                }
                onClick={() => addNames(true)}
              >
                Next Role
              </Button>
            ) : (
              <Button
                htmlType="button"
                className="icon_grey_btn"
                type="primary"
                size="large"
                onClick={() => addNames(false)}
                disabled={seriousName === "" || funnyName === ""}
              >
                Submit
              </Button>
            )}
          </div>
          <div className="steps_footer">
            <div className="steps_btn_wrapper">
              <Button
                htmlType="button"
                className="icon_grey_btn"
                type="primary"
                size="large"
                onClick={() => setCurrent(4)}
              >
                Back
              </Button>
              <Button
                htmlType="button"
                className="icon_dark_btn"
                type="primary"
                size="large"
                disabled={
                  (+activeTab !== newData?.length ||
                    seriousName === "" ||
                    funnyName === "" ||
                    !enableSubmit) &&
                  sessionData?.last_session_screen < 6
                }
                onClick={submitSFNames}
              >
                Continue
              </Button>
            </div>
          </div>
        </Card>
      </Col>
    </Row>
  );
}
