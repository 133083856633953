import { Action, Dispatch } from "redux";
import axios from "../../../config/axiosInterceptor";
import { BASE_URLS, ENVIRONMENT_MODE } from "../../../config/constants";
import * as action_types from "./constants";

const baseURL = BASE_URLS[ENVIRONMENT_MODE].REACT_APP_API_BASE_URL;

export const getCompanyFieldsById = (payload: {
  companyId: string;
  module?: string;
}) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const { data } = await axios.post(
        `${baseURL}getCompanyFieldsById`,
        payload
      );
      dispatch({
        type: action_types.COMPANY_FIELDS,
        data: { ...data, loading: false },
      });
    } catch (error) {
      dispatch({
        type: action_types.COMPANY_FIELDS,
        data: { loading: false },
      });
    }
  };
};

export const getCompanyDetails = (companyId: string) => {
  return async (dispatch: Dispatch<Action>) => {
    const { data } = await axios.get(
      `${baseURL}getCompanyDetails/${companyId}`
    );
    dispatch({
      type: action_types.COMPANY_DETAILS,
      data,
    });
  };
};
