export enum ENVIRONMENTS {
  STAGING = "staging",
  LOCAL_SHEHAN = "local_shehan",
  LOCAL_ALI = "local_ali",
}
export const ENVIRONMENT_MODE: string = ENVIRONMENTS.STAGING;

export const BASE_URLS: {
  [key: string]: {
    REACT_APP_API_BASE_URL: string;
  };
} = {
  staging: {
    REACT_APP_API_BASE_URL: "https://www.fpbackend.darkhorsesolutions.co/",
  },
  local_shehan: {
    REACT_APP_API_BASE_URL: "http://192.168.100.5:3000/",
  },
  local_ali: {
    REACT_APP_API_BASE_URL: "http://192.168.100.7:3000/",
  },
};

export enum USER_TYPE {
  INDIVIDUAL_CONTRIBUTOR = "Individual Contributor",
  COMPANY_ADMIN = "Company Admin",
  SUPER_ADMIN = "Super Admin",
}

export enum CATEGORIES {
  UNCATEGORIZED = "Uncategorized",
  PROCEDURE_TASK = "Procedure/Task",
  RESPONSIBILITY = "Responsibility",
}

export enum STATUS_TYPE {
  pending_approval = "Pending approval",
  approved = "Approved",
  suspended_fp_session = "Suspended FP Session",
  pending_fp_session = "Pending FP Session",
  pending_ws_session = "Pending WS Session",
  suspended_ws_session = "Suspended WS Session",
}

export enum STATUS_TYPE_DROPDOWN {
  PENDING_APPROVAL = "Pending Approval",
  APPROVED = "Approved",
  SUSPENDED_FP_SESSION = "Suspended FP Session",
  PENDING_FP_SESSION = "Pending FP Session",
}

export const getInitials = (firstName: string, lastName: string) => {
  const firstInitial = firstName?.charAt(0).toUpperCase();
  const lastInitial = lastName?.charAt(0).toUpperCase();

  return firstInitial + lastInitial;
};
