import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const InfoIconOutlinedSvg = () => (  
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M6.51379 1.60286C3.53303 1.60286 1.11665 4.01924 1.11665 7C1.11665 9.98076 3.53303 12.3971 6.51379 12.3971C9.49455 12.3971 11.9109 9.98076 11.9109 7C11.9109 4.01924 9.49455 1.60286 6.51379 1.60286ZM0 7C3.32788e-07 3.40253 2.91633 0.486206 6.51379 0.486206C10.1113 0.486206 13.0276 3.40253 13.0276 7C13.0276 10.5975 10.1113 13.5138 6.51379 13.5138C2.91632 13.5138 -3.32788e-07 10.5975 0 7ZM6.51379 4.20837C6.82215 4.20837 7.07212 4.45834 7.07212 4.7667V4.79287C7.07212 5.10122 6.82215 5.3512 6.51379 5.3512C6.20544 5.3512 5.95547 5.10122 5.95547 4.79287V4.7667C5.95547 4.45834 6.20544 4.20837 6.51379 4.20837ZM6.51379 6.44167C6.82215 6.44167 7.07212 6.69165 7.07212 7L7.07212 9.97773C7.07212 10.2861 6.82215 10.5361 6.51379 10.5361C6.20544 10.5361 5.95547 10.2861 5.95547 9.97773V7C5.95547 6.69165 6.20544 6.44167 6.51379 6.44167Z" fill="#505560"/>
  </svg>
);

const InfoIconOutlined = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={InfoIconOutlinedSvg} {...props} />
);

export default InfoIconOutlined;
