import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const Leaf6 = () => (
  <svg
    width="246"
    height="325"
    viewBox="0 0 246 325"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.9193 152.53L123.151 322.682L221.234 152.694C235.425 128.1 230.433 96.9254 209.272 77.9924L149.569 24.5762C134.368 10.9767 111.37 10.9915 96.1879 24.6106L36.8173 77.8674C15.698 96.8118 10.7345 127.959 24.9193 152.53Z"
      fill="currentColor"
      stroke="#ECEDF3"
      strokeWidth="1.62816"
    />
  </svg>
);

const LeafIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={Leaf6} {...props} />
);

export default LeafIcon;
