import { combineReducers } from "@reduxjs/toolkit";
import account from "../organisms/MyAccount/ducks/reducers";
import companyDetails from "../organisms/CompanyDetails/ducks/reducers";
import settings from "../organisms/CompanySettings/ducks/reducers";
import companies from "../organisms/Companies/ducks/reducers";
import system from "../organisms/SystemSettings/ducks/reducers";
import jobDetail from "../organisms/MyJob/ducks/reducers";

const rootReducer = combineReducers({
  companyDetails,
  account,
  settings,
  companies,
  system,
  jobDetail,
});

export default rootReducer;
