import { Button, Col, Image, Row, Form, Spin } from "antd";
import InputField from "../../molecules/FormItems/InputField";
import { useForm } from "react-hook-form";
import { DropdownIcon } from "../CustomIcons";
import SelectField from "../../molecules/FormItems/SelectField";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { getJobFilters } from "../../organisms/CompanyDetails/ducks/actions";
import { createJobByCompanyId } from "../../organisms/CompanyDetails/ducks/services";
import { AxiosError } from "axios";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import ErrorMessage from "../Toasts/ErrorMessage";
import SuccessMessage from "../Toasts/SuccessMessage";

const antIcon = <Loading3QuartersOutlined spin />;

export default (props: any) => {
  const { onClose, title, btnText, btnApi, updateApi, companyId } = props;
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const jobsFilterOptions = useAppSelector(
    (state) => state?.companyDetails?.jobFiltersOptions
  );

  const onCloseHandler = () => {
    onClose();
  };

  type FormValues = {
    jobName: string;
    jobHolders: string;
    department: string;
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<FormValues>();

  useEffect(() => {
    if (companyId) {
      dispatch(getJobFilters(companyId));
    }
  }, [dispatch, companyId]);

  const onSubmit = (val: FormValues) => {
    setLoading(true);
    const payload = {
      jobName: val?.jobName,
      jobHolders: val?.jobHolders,
      department: val?.department,
      companyId: companyId,
    };
    createJobByCompanyId(payload)
      .then(() => {
        setLoading(false);
        SuccessMessage("Job created.");
        updateApi && updateApi();
        onClose();
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };
  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <div className="eTableModal">
        <div className="modal_header">
          <div className="title"> {title} </div>
          <div className="close_icon">
            <Button
              size="large"
              type="link"
              className="p-0 m-20 close_popup"
              onClick={() => onCloseHandler()}
            >
              <Image src="/images/close-icon.svg" preview={false} width={10} />
            </Button>
          </div>
        </div>
        <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
          <div className="modal_content">
            <Row gutter={16}>
              <Col flex="0 0 100%">
                <h4>
                  Job Name <span className="red_color">*</span>
                </h4>
                <InputField
                  fieldName="jobName"
                  label=""
                  control={control}
                  initValue=""
                  iProps={{
                    placeholder: "Type name",
                  }}
                  isRequired
                  rules={{
                    required: "Job name is required.",
                  }}
                  validate={errors.jobName && "error"}
                  validMessage={errors.jobName && errors.jobName.message}
                />
              </Col>
              <Col flex="0 0 100%">
                <h4>Job Holders</h4>
                <SelectField
                  fieldName="jobHolders"
                  label=""
                  control={control}
                  selectOption={jobsFilterOptions?.employee}
                  className="fp_select"
                  mode="multiple"
                  iProps={{
                    placeholder: "Select employee",
                    suffixIcon: <DropdownIcon />,
                  }}
                  isRequired
                  rules={{
                    required: "Employee is required.",
                  }}
                  validate={errors.jobHolders && "error"}
                  validMessage={errors.jobHolders && errors.jobHolders.message}
                />
              </Col>
              <Col flex="0 0 100%">
                <h4>Departments</h4>
                <SelectField
                  fieldName="department"
                  label=""
                  control={control}
                  selectOption={jobsFilterOptions?.department}
                  className="fp_select"
                  iProps={{
                    placeholder: "Select department",
                    suffixIcon: <DropdownIcon />,
                  }}
                  isRequired
                  rules={{
                    required: "Department is required.",
                  }}
                  validate={errors.department && "error"}
                  validMessage={errors.department && errors.department.message}
                />
              </Col>
            </Row>
          </div>

          <div className="modal_footer">
            <Button
              htmlType="button"
              className="cancelBtn defaultBtnColor"
              type="default"
              size="large"
              onClick={() => onCloseHandler()}
            >
              Cancel
            </Button>

            <Button
              htmlType="submit"
              className="actionBtn dangerBtnColor"
              type="primary"
              size="large"
              onClick={btnApi}
            >
              {btnText}
            </Button>
          </div>
        </Form>
      </div>
    </Spin>
  );
};
