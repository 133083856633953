import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const IconSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.67106 12.6665H4.34891C5.22139 12.6665 6.02676 12.3354 6.63081 11.7393L11.9999 6.3089L11.0603 5.38175L9.31534 3.59366L8.77845 3.13008L8.30863 2.6665L2.93951 8.09695C2.33549 8.69299 1.99992 9.4877 1.99992 10.3486V12.0042C1.99992 12.4016 2.26838 12.6665 2.67106 12.6665Z"
      fill="white"
    />
    <path
      d="M10.2375 2.74758L11.9169 4.45028L12.8212 5.33317L13.2733 4.89175C14.2421 3.94578 14.2421 2.36921 13.2733 1.42326C12.8212 0.918757 12.1753 0.666504 11.5293 0.666504C10.8834 0.666504 10.2375 0.918757 9.78539 1.3602L9.33325 1.80164L9.78539 2.24308L10.2375 2.74758Z"
      fill="white"
    />
    <path
      d="M13.9999 13.9998H1.99992C1.59992 13.9998 1.33325 14.2665 1.33325 14.6665C1.33325 15.0665 1.59992 15.3332 1.99992 15.3332H13.9999C14.3999 15.3332 14.6666 15.0665 14.6666 14.6665C14.6666 14.2665 14.3999 13.9998 13.9999 13.9998Z"
      fill="white"
    />
  </svg>
);

const PencilIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={IconSvg} {...props} />
);

export default PencilIcon;
