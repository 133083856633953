export const JOBS_LIST = "JOBS_LIST";
export const ROLES_LIST = "ROLES_LIST";
export const EMPLOYEES_LIST = "EMPLOYEES_LIST";
export const JOB_FILTERS = "JOB_FILTERS";
export const ROLES_FILTERS = "ROLES_FILTERS";
export const COMPANY_DROP = "COMPANY_DROP";
export const ROLE_DROP = "ROLE_DROP";
export const EMPLOYEES_FILTERS = "EMPLOYEES_FILTERS";
export const SET_FILTERS_OPTIONS_JOBS = "SET_FILTERS_OPTIONS_JOBS";
export const ROLES_DETAILS = "ROLES_DETAILS";
