import { Button, Image } from "antd";

export default (props: any) => {
  const {
    onClose,
    title,
    heading,
    paragraph_1,
    paragraph_2,
    btnText,
    btnApi,
    loading,
  } = props;

  const onCloseHandler = () => {
    onClose();
  };

  return (
    <div className="eTableModal">
      <div className="modal_header">
        <div className="title"> {title} </div>
        <div className="close_icon">
          <Button
            size="large"
            type="link"
            className="p-0 m-20 close_popup"
            onClick={onCloseHandler}
          >
            <Image src="/images/close-icon.svg" preview={false} width={10} />
          </Button>
        </div>
      </div>

      <div className="modal_content">
        <h4>{heading}</h4>
        {paragraph_1 && <p style={{ marginBottom: "20px" }}>{paragraph_1}</p>}
        {paragraph_2 && <p style={{ marginBottom: "20px" }}>{paragraph_2}</p>}
      </div>

      <div className="modal_footer">
        <Button
          htmlType="button"
          className="cancelBtn defaultBtnColor"
          type="default"
          size="large"
          onClick={onCloseHandler}
        >
          Cancel
        </Button>

        <Button
          htmlType="button"
          className="actionBtn dangerBtnColor"
          type="primary"
          size="large"
          onClick={btnApi}
          loading={loading}
        >
          {btnText}
        </Button>
      </div>
    </div>
  );
};
