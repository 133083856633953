import { useEffect, useMemo, useState } from "react";
import { Card, Button, Tabs, TabsProps, Image } from "antd";
import FullPageLayout from "../../../../templates/FullPageLayout";
import { CloseIcon, PencilIcon } from "../../../../atoms/CustomIcons";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../config/hooks";
import { getJobDetails } from "../../../../organisms/MyJob/ducks/actions";
import { RoleType } from "../../../..//organisms/MyJob/ducks/types";
import RoleForm from "../Components/RoleForm";
import EditJobForm from "../../../..//molecules/FormItems/EditJobForm";

export default function WSSession() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState("1");
  const [loading, setLoading] = useState(false);
  const [jobTabsItems, setJobTabsItems] = useState<TabsProps["items"]>([]);
  const url = useLocation();
  const jobId = url?.pathname.split("/")[2];
  const customArray: TabsProps["items"] = [];
  const jobDetailData = useAppSelector(
    (state) => state?.jobDetail?.jobDetailData
  );
  const jobItems: TabsProps["items"] = [
    {
      key: "jobTitle",
      label: <div className="jobTitle">Job</div>,
      disabled: true,
    },
    {
      key: "jobName",
      label: jobDetailData?.detailData?.name,
      children: <EditJobForm />,
      icon: <PencilIcon className="jobName-icon" />,
    },
  ];

  useEffect(() => {
    if (jobId) {
      dispatch(getJobDetails(jobId));
    }
  }, [jobId]);

  useMemo(() => {
    if (jobDetailData?.detailData?.roles?.length > 0) {
      jobDetailData?.detailData?.roles?.map((e: RoleType, i: number) =>
        customArray.push({
          key: `${i + 1}`,
          disabled: true,
          label: (
            <div className="tab-label">
              <span className="tab-number">{i + 1}</span> {e?.name}{" "}
              <Image
                src={
                  e?.department
                    ? "/images/session/checked-circle.svg"
                    : "/images/session/unchecked-circle.svg"
                }
                preview={false}
                width={20}
              />
            </div>
          ),
          children: (
            <RoleForm
              data={e}
              setActiveTab={setActiveTab}
              totalLength={jobDetailData?.detailData?.roles?.length}
              activeNumber={i + 1}
              setLoading={setLoading}
            />
          ),
        })
      );

      const tabItems = [...customArray, ...jobItems];
      setJobTabsItems(tabItems);
    }
  }, [jobDetailData?.detailData?.roles]);

  console.log("activeTab", activeTab);
  return (
    <FullPageLayout loading={loading}>
      <Card className="fp_layout_title">
        <div className="mainTitle">
          <Button
            className="icon_grey_btn"
            icon={<CloseIcon />}
            onClick={() => navigate(-1)}
          >
            Close
          </Button>
          <h3>Edit Job</h3>
        </div>
      </Card>

      <div className="fp_layout_content" style={{ height: "auto" }}>
        <div className="editJob">
          <div className="controlPanel">
            <div className="title">
              <h6>Roles</h6>
            </div>
            <div className="role_job_list">
              <Tabs
                tabPosition="left"
                items={jobTabsItems}
                activeKey={activeTab}
                destroyInactiveTabPane={true}
                defaultActiveKey={
                  jobDetailData?.detailData?.roles?.length > 0 ? "1" : "newRole"
                }
              />
            </div>
          </div>
        </div>
      </div>
    </FullPageLayout>
  );
}
