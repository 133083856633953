import { Button, Card, Col, Row } from "antd";
import { SessionTypeResponse } from "../../../../../../organisms/MyJob/ducks/types";
import FpSessionReady from "../Components/FpSessionReady";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../config/hooks";
import { getFlowerDataBySessionID } from "../../../../../../organisms/MyJob/ducks/actions";
import { updateSessionById } from "../../../../../../organisms/MyJob/ducks/services";

type PropsType = {
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  sessionData: SessionTypeResponse;
  sessionId: string;
};

export default function Step4(props: PropsType) {
  const { setCurrent, sessionId, sessionData } = props;
  const dispatch = useAppDispatch();
  const flowerData = useAppSelector(
    (state) => state?.jobDetail?.sessionFlowerData
  );

  console.log("flowerData", flowerData);

  useEffect(() => {
    if (sessionId) {
      dispatch(getFlowerDataBySessionID(sessionId));
    }
  }, [sessionId]);

  const updateLastScreen = () => {
    const payload = {
      last_session_screen: 4,
    };
    updateSessionById(payload, sessionId).then(() => {
      setCurrent(4);
    });
  };

  return (
    <Row>
      <Col span={13}>
        <div className="session_flower finalizedFlower">
          <div
            className="flower_wrapper"
            style={{ minHeight: "calc(100vh - 130px)" }}
          >
            {flowerData?.sessionPetals && (
              <>
                <FpSessionReady
                  data={flowerData?.sessionPetals}
                  setCurrent={setCurrent}
                  activeTab="1"
                />
                <div className="flower_position">{sessionData?.job?.name}</div>
              </>
            )}
          </div>
        </div>
      </Col>
      <Col span={11}>
        <Card
          className="session_fp_card"
          style={{ height: "calc(100vh - 130px)" }}
        >
          <div className="fp_ready_wrap">
            <h3>Job flower is ready!</h3>
            <p>Fantastic! Now, let's take the next step and name the roles.</p>
            <div className="fp_ready_btn">
              <Button
                htmlType="button"
                className="icon_grey_btn"
                type="primary"
                size="large"
                onClick={() => setCurrent(2)}
              >
                Back
              </Button>
              <Button
                htmlType="button"
                className="icon_dark_btn"
                type="primary"
                size="large"
                onClick={updateLastScreen}
              >
                Continue
              </Button>
            </div>
          </div>
        </Card>
      </Col>
    </Row>
  );
}
