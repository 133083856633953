import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const ArrowSvg = () => (
  <svg
    width="8"
    height="5"
    viewBox="0 0 8 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.669922 0.724319C0.921749 0.455134 1.34364 0.441506 1.61224 0.693855L3.99997 3.09158L6.39094 0.689481C6.6596 0.437132 7.08147 0.450761 7.33334 0.719946C7.58507 0.989131 7.57147 1.41192 7.30294 1.66427L4.45593 4.49477C4.19949 4.73568 3.80045 4.73568 3.54401 4.49477L0.700322 1.66864C0.431708 1.41629 0.418109 0.993504 0.669922 0.724319Z"
      fill="#0D1019"
    />
  </svg>
);

const SelectArrowIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ArrowSvg} {...props} />
);

export default SelectArrowIcon;
