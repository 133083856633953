import { useEffect } from "react";

import { useLocation } from "react-router-dom";
import DashboardTemplate from "../../../../templates/Dashboard";
import {
  emptyJobDetailsData,
  getJobDetails,
} from "../../../../organisms/MyJob/ducks/actions";
import { useAppDispatch, useAppSelector } from "../../../../config/hooks";
import JobDetails from "../../../../atoms/JobDetails";

export default function MyJob() {
  const dispatch = useAppDispatch();
  const url = useLocation();
  const jobId = url?.pathname.split("/")[2];
  const jobDetailData = useAppSelector(
    (state) => state?.jobDetail?.jobDetailData
  );

  useEffect(() => {
    if (jobId) {
      dispatch(getJobDetails(jobId));
    }
  }, [jobId]);

  useEffect(() => {
    dispatch(emptyJobDetailsData());
  }, [dispatch]);

  return (
    <DashboardTemplate loading={jobDetailData?.loading}>
      <JobDetails jobDetailData={jobDetailData?.detailData} />
    </DashboardTemplate>
  );
}
