import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../config/hooks";
import KanbanBoard from "../../../../../../molecules/KanbanBoard";
import { Id, Role } from "../../../../../../molecules/KanbanBoard/types";
import {
  addPetalsBySessionId,
  updateSessionById,
} from "../../../../../../organisms/MyJob/ducks/services";
import { getPetalsWithRoleDescriptorsBySessionId } from "../../../../../../organisms/MyJob/ducks/actions";
import { SessionTypeResponse } from "../../../../../../organisms/MyJob/ducks/types";
import { AxiosError } from "axios";
import ErrorMessage from "../../../../../Toasts/ErrorMessage";

type PropsType = {
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  sessionId: string;
  sessionData: SessionTypeResponse;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function Step3(props: PropsType) {
  const { setCurrent, sessionId, sessionData, setLoading } = props;
  const [roles, setRoles] = useState<Role[]>([]);
  const dispatch = useAppDispatch();
  const roleData = useAppSelector((state) => state?.jobDetail?.roleData);

  console.log("roleData", roleData);

  const groupByColumnId = (data: Role[]) => {
    const groupedData: { [key: string]: Role[] } = {};

    data?.forEach((item: Role) => {
      const { column_id } = item;
      if (!groupedData[column_id]) {
        groupedData[column_id] = [];
      }
      groupedData[column_id].push(item);
    });

    return Object.values(groupedData);
  };

  const transformData = () => {
    setLoading(true);
    const rolesResult = groupByColumnId(roles);
    const petalsArray: { index: number; role_descriptors_id: Id[] }[] = [];
    rolesResult?.map((role, ind) => {
      petalsArray.push({
        index: ind,
        role_descriptors_id: role?.map((r: { id: Id }) => r.id),
      });
    });

    const payload = {
      session_id: sessionId,
      petals: petalsArray,
    };
    addPetalsBySessionId(payload)
      .then(() => {
        const payload = {
          last_session_screen: 3,
        };
        updateSessionById(payload, sessionId)
          .then(() => {
            setLoading(false);
            setCurrent(3);
          })
          .catch((e: unknown) => {
            if (e instanceof AxiosError) {
              setLoading(false);
              ErrorMessage(
                e?.response?.data?.error?.message ?? "Something went wrong"
              );
            } else {
              setLoading(false);
              ErrorMessage("Something went wrong");
            }
          });
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });

    console.log("petalsArray", petalsArray);
  };

  useEffect(() => {
    if (sessionId) {
      dispatch(getPetalsWithRoleDescriptorsBySessionId(sessionId));
    }
  }, [dispatch, sessionId]);

  return (
    <KanbanBoard
      roleData={roleData}
      setCurrent={setCurrent}
      transformData={transformData}
      roles={roles}
      setRoles={setRoles}
      sessionId={sessionId}
      sessionData={sessionData}
    />
  );
}
