import { Row, Col, Button, Card, Form, Checkbox, Image } from "antd";
import { useForm } from "react-hook-form";
import { DropdownIcon } from "../../../../../atoms/CustomIcons";
import TextEditor from "../../../../../molecules/FormItems/TextEditor";
import { useCallback, useEffect, useState } from "react";
import { getUserDetails } from "../../../../MyAccount/ducks/actions";
import { useAppDispatch, useAppSelector } from "../../../../../config/hooks";
import SelectField from "../../../../../molecules/FormItems/SelectField";
import {
  getCompanyFieldsByIdDrop,
  getDropdownsByCompany,
} from "../../../ducks/actions";
import { updateRoleById } from "../../../ducks/services";
import { UpdateRole } from "../../../ducks/types";
import { AxiosError } from "axios";
import ErrorMessage from "../../../../../atoms/Toasts/ErrorMessage";
import { RoleType } from "../../../../..//organisms/MyJob/ducks/types";
import { useLocation } from "react-router-dom";
import { getJobDetails } from "../../../../MyJob/ducks/actions";

type PropsType = {
  data: RoleType;
  setActiveTab?: React.Dispatch<React.SetStateAction<string>>;
  totalLength?: number;
  activeNumber?: number;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function RoleForm(props: PropsType) {
  const { data, setActiveTab, totalLength, activeNumber, setLoading } = props;
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<UpdateRole>();
  const [checkNA, setCheckNA] = useState({
    objectives: false,
    key_results: false,
    procedures: false,
    workflows: false,
    skills: false,
    technologies: false,
    teams: false,
    entities: false,
    meetings: false,
    coached_by: false,
  });
  const url = useLocation();
  const jobId = url?.pathname.split("/")[2];
  const companyId = url?.pathname.split("/")[3];

  const settingData = useAppSelector(
    (state) => state?.companyDetails?.fieldsDropdown
  );
  const dropdownData = useAppSelector(
    (state) => state?.companyDetails?.roleDropdown
  );

  const optionsForDropdown = [
    { value: "Option 1", label: "Option 1" },
    { value: "Option 2", label: "Option 2" },
    { value: "Option 3", label: "Option 3" },
  ];

  const onSubmit = (val: UpdateRole) => {
    setLoading(true);
    const payload = {
      name: data?.name,
      purpose_statement: val?.purpose_statement,
      objectives: checkNA?.objectives ? "N/A" : val?.objectives,
      key_results: checkNA?.key_results ? [] : val?.key_results,
      procedures: checkNA?.procedures ? "N/A" : val?.procedures,
      workflows: checkNA?.workflows ? [] : val?.workflows,
      skills: checkNA?.skills ? [] : val?.skills,
      technologies: checkNA?.technologies ? [] : val?.technologies,
      teams: checkNA?.teams ? [] : val?.teams,
      entities: checkNA?.entities ? "N/A" : val?.entities,
      meetings: checkNA?.meetings ? [] : val?.meetings,
      company_id: companyId,
      coached_by: checkNA?.coached_by
        ? "coached_by_not_available"
        : val?.coached_by,
      department: val?.department,
    };

    updateRoleById(payload, data?.id)
      .then(() => {
        dispatch(getJobDetails(jobId));

        setTimeout(() => {
          setLoading(false);
          if (setActiveTab && activeNumber && totalLength !== activeNumber) {
            setActiveTab((activeNumber + 1).toString());
          } else if (setActiveTab) {
            setActiveTab("jobName");
          }
        }, 1500);
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  useEffect(() => {
    if (companyId) {
      const payload = {
        companyId: companyId,
      };
      const dropdownPayload = {
        companyId: companyId,
        sendRoles: true,
        sendUsers: true,
        sendJobs: true,
      };
      dispatch(getCompanyFieldsByIdDrop(payload));
      dispatch(getDropdownsByCompany(dropdownPayload));
    }
  }, [dispatch, companyId]);

  useEffect(() => {
    dispatch(getUserDetails());
  }, [dispatch]);

  const arrayDropdown = useCallback(
    (moduleName: string) => {
      const filteredArray = settingData?.filter(
        (e: { module: string; name: string; value: string }) =>
          e?.module === moduleName
      );
      return filteredArray?.map((e: { name: string; value: string }) => ({
        label: e?.name,
        value: e?.value,
      }));
    },
    [settingData]
  );

  useEffect(() => {
    if (data) {
      setValue("purpose_statement", data?.purpose_statement);

      setCheckNA({
        objectives: data?.objectives === "N/A",
        key_results: data?.key_results?.length === 0,
        procedures: data?.procedures === "N/A",
        workflows: data?.workflows?.length === 0,
        skills: data?.skills?.length === 0,
        technologies: data?.technologies?.length === 0,
        teams: data?.teams?.length === 0,
        entities: data?.entities === "N/A",
        meetings: data?.meetings?.length === 0,
        coached_by: data?.coached_by === "coached_by_not_available",
      });

      if (data?.objectives !== "N/A") {
        setValue("objectives", data?.objectives);
      }
      if (data?.procedures !== "N/A") {
        setValue("procedures", data?.procedures);
      }
      if (data?.entities !== "N/A") {
        setValue("entities", data?.entities);
      }
      if (data?.key_results?.length > 0) {
        setValue("key_results", data?.key_results);
      }
      if (data?.workflows?.length > 0) {
        setValue("workflows", data?.workflows);
      }
      if (data?.skills?.length > 0) {
        setValue("skills", data?.skills);
      }
      if (data?.technologies?.length > 0) {
        setValue("technologies", data?.technologies);
      }
      if (data?.teams?.length > 0) {
        setValue("teams", data?.teams);
      }
      if (data?.meetings?.length > 0) {
        setValue("meetings", data?.meetings);
      }
      if (data?.coached_by !== "coached_by_not_available") {
        setValue("coached_by", data?.coached_by);
      }

      setValue("department", data?.department);
    }
  }, [data]);

  // console.log("totalLength", totalLength, activeNumber);

  interface OnChangeEvent {
    target: {
      checked: boolean;
    };
  }

  const onChange = (e: OnChangeEvent, field: string): void => {
    setCheckNA({ ...checkNA, [field]: e.target.checked });
  };
  console.log("checkNA.objectives", checkNA);
  return (
    <div className="contentPanel">
      <Card
        className="fp_layout_content"
        style={{ height: "100%", maxWidth: "992px", margin: "0 auto" }}
      >
        <div
          className="cardContent"
          style={{
            maxWidth: "992px",
            paddingLeft: "68px",
            paddingRight: "68px",
            border: "unset",
            marginTop: "0px",
            paddingTop: "0px",
          }}
        >
          <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
            <Row>
              <Col span={24}>
                <div className="mainTitle">
                  <h3> {data?.name} </h3>
                </div>
              </Col>
              <Col span={24}>
                <h4
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    Role purpose statement <span className="red_color">*</span>
                  </div>
                  <div>
                    <h4 style={{ display: "flex", gap: "6px" }}>
                      <Image
                        src="/images/MagicWand.svg"
                        alt="Generate with AI"
                        width={16}
                        height={16}
                        preview={false}
                      />
                      Generate with AI
                    </h4>
                  </div>
                </h4>
                <TextEditor
                  fieldName="purpose_statement"
                  control={control}
                  initValue=""
                  label=""
                  iProps={{
                    placeholder: "Explain the Role purpose statement",
                  }}
                  isRequired
                  rules={{
                    required: "Job name is required.",
                  }}
                  validate={errors.purpose_statement && "error"}
                  validMessage={
                    errors.purpose_statement && errors.purpose_statement.message
                  }
                />
              </Col>
              <Col span={24}>
                <h4 style={{ position: "absolute", right: "0", top: "-2px" }}>
                  <Checkbox
                    style={{ marginRight: "4px" }}
                    checked={checkNA.objectives}
                    onChange={(e) => onChange(e, "objectives")}
                  />{" "}
                  N/A
                </h4>
                <TextEditor
                  fieldName="objectives"
                  control={control}
                  initValue=""
                  label="Objectives"
                  iProps={{
                    disabled: checkNA.objectives,
                    placeholder:
                      "Example: Increase market share by developing and implementing effective sales strategies and fostering strong customer relationships.",
                  }}
                  isRequired={!checkNA.objectives ? true : false}
                  rules={{
                    required: !checkNA.objectives
                      ? "Job name is required."
                      : false,
                  }}
                  validate={!checkNA.objectives && errors.objectives && "error"}
                  validMessage={
                    !checkNA.objectives &&
                    errors.objectives &&
                    errors.objectives.message
                  }
                />
              </Col>
              <Col span={24}>
                <h4 style={{ position: "absolute", right: "0", top: "-2px" }}>
                  <Checkbox
                    style={{ marginRight: "4px" }}
                    checked={checkNA.key_results}
                    onChange={(e) => onChange(e, "key_results")}
                  />{" "}
                  N/A
                </h4>

                <SelectField
                  fieldName="key_results"
                  label="Key Results"
                  control={control}
                  selectOption={arrayDropdown("KEY_RESULT")}
                  className="fp_select"
                  mode="multiple"
                  iProps={{
                    placeholder: "Select key results",
                    suffixIcon: <DropdownIcon />,
                    disabled: checkNA.key_results,
                  }}
                  isRequired={!checkNA.key_results ? true : false}
                  rules={{
                    required: !checkNA.key_results
                      ? "Key Results is required."
                      : false,
                  }}
                  validate={
                    !checkNA.key_results && errors.key_results && "error"
                  }
                  validMessage={
                    !checkNA.key_results &&
                    errors.key_results &&
                    errors.key_results.message
                  }
                />
              </Col>
              <Col flex="0 0 992px">
                <h4 style={{ position: "absolute", right: "0", top: "-2px" }}>
                  <Checkbox
                    style={{ marginRight: "4px" }}
                    checked={checkNA.procedures}
                    onChange={(e) => onChange(e, "procedures")}
                  />{" "}
                  N/A
                </h4>
                <TextEditor
                  fieldName="procedures"
                  control={control}
                  initValue=""
                  label="Procedures"
                  isRequired={!checkNA.procedures ? true : false}
                  iProps={{
                    disabled: checkNA.procedures,
                  }}
                  rules={{
                    required: !checkNA.procedures
                      ? "Key Results is required."
                      : false,
                  }}
                  validate={!checkNA.procedures && errors.procedures && "error"}
                  validMessage={
                    !checkNA.procedures &&
                    errors.procedures &&
                    errors.procedures.message
                  }
                />
              </Col>
              <Col flex="0 0 992px">
                <h4 style={{ position: "absolute", right: "0", top: "-2px" }}>
                  <Checkbox
                    style={{ marginRight: "4px" }}
                    checked={checkNA.workflows}
                    onChange={(e) => onChange(e, "workflows")}
                  />{" "}
                  N/A
                </h4>

                <SelectField
                  fieldName="workflows"
                  label="Workflows"
                  control={control}
                  selectOption={optionsForDropdown}
                  mode="multiple"
                  iProps={{
                    placeholder: "Select workflows",
                    suffixIcon: <DropdownIcon />,
                    disabled: checkNA.workflows,
                  }}
                  isRequired={!checkNA.workflows ? true : false}
                  rules={{
                    required: !checkNA.workflows
                      ? "Key Results is required."
                      : false,
                  }}
                  validate={!checkNA.workflows && errors.workflows && "error"}
                  validMessage={
                    !checkNA.workflows &&
                    errors.workflows &&
                    errors.workflows.message
                  }
                />
              </Col>
              <Col flex="0 0 992px">
                <h4 style={{ position: "absolute", right: "0", top: "-2px" }}>
                  <Checkbox
                    style={{ marginRight: "4px" }}
                    checked={checkNA.skills}
                    onChange={(e) => onChange(e, "skills")}
                  />{" "}
                  N/A
                </h4>

                <SelectField
                  fieldName="skills"
                  label="Skills"
                  control={control}
                  selectOption={arrayDropdown("SKILL")}
                  mode="multiple"
                  iProps={{
                    placeholder: "Select skills",
                    suffixIcon: <DropdownIcon />,
                    disabled: checkNA.skills,
                  }}
                  isRequired={!checkNA.skills ? true : false}
                  rules={{
                    required: !checkNA.skills
                      ? "Key Results is required."
                      : false,
                  }}
                  validate={!checkNA.skills && errors.skills && "error"}
                  validMessage={
                    !checkNA.skills && errors.skills && errors.skills.message
                  }
                />
              </Col>
              <Col flex="0 0 992px">
                <h4 style={{ position: "absolute", right: "0", top: "-2px" }}>
                  <Checkbox
                    style={{ marginRight: "4px" }}
                    checked={checkNA.technologies}
                    onChange={(e) => onChange(e, "technologies")}
                  />{" "}
                  N/A
                </h4>

                <SelectField
                  fieldName="technologies"
                  label="Technologies"
                  control={control}
                  selectOption={arrayDropdown("TECHNOLOGIES")}
                  mode="multiple"
                  iProps={{
                    placeholder: "Select Technologies",
                    suffixIcon: <DropdownIcon />,
                    disabled: checkNA.technologies,
                  }}
                  isRequired={!checkNA.technologies ? true : false}
                  rules={{
                    required: !checkNA.technologies
                      ? "Key Results is required."
                      : false,
                  }}
                  validate={
                    !checkNA.technologies && errors.technologies && "error"
                  }
                  validMessage={
                    !checkNA.technologies &&
                    errors.technologies &&
                    errors.technologies.message
                  }
                />
              </Col>
              <Col flex="0 0 992px">
                <h4 style={{ position: "absolute", right: "0", top: "-2px" }}>
                  <Checkbox
                    style={{ marginRight: "4px" }}
                    checked={checkNA.teams}
                    onChange={(e) => onChange(e, "teams")}
                  />{" "}
                  N/A
                </h4>

                <SelectField
                  fieldName="teams"
                  label="Teams"
                  control={control}
                  selectOption={arrayDropdown("TEAMS")}
                  mode="multiple"
                  iProps={{
                    placeholder: "Select teams",
                    suffixIcon: <DropdownIcon />,
                    disabled: checkNA.teams,
                  }}
                  isRequired={!checkNA.teams ? true : false}
                  rules={{
                    required: !checkNA.teams
                      ? "Key Results is required."
                      : false,
                  }}
                  validate={!checkNA.teams && errors.teams && "error"}
                  validMessage={
                    !checkNA.teams && errors.teams && errors.teams.message
                  }
                />
              </Col>
              <Col flex="0 0 992px">
                <h4 style={{ position: "absolute", right: "0", top: "-2px" }}>
                  <Checkbox
                    style={{ marginRight: "4px" }}
                    checked={checkNA.entities}
                    onChange={(e) => onChange(e, "entities")}
                  />{" "}
                  N/A
                </h4>
                <TextEditor
                  fieldName="entities"
                  label="Entities"
                  control={control}
                  initValue=""
                  isRequired={!checkNA.entities ? true : false}
                  iProps={{
                    disabled: checkNA.entities,
                  }}
                  rules={{
                    required: !checkNA.entities
                      ? "Key Results is required."
                      : false,
                  }}
                  validate={!checkNA.entities && errors.entities && "error"}
                  validMessage={
                    !checkNA.entities &&
                    errors.entities &&
                    errors.entities.message
                  }
                />
              </Col>
              <Col flex="0 0 992px">
                <h4 style={{ position: "absolute", right: "0", top: "-2px" }}>
                  <Checkbox
                    style={{ marginRight: "4px" }}
                    checked={checkNA.meetings}
                    onChange={(e) => onChange(e, "meetings")}
                  />{" "}
                  N/A
                </h4>

                <SelectField
                  fieldName="meetings"
                  label="Meetings"
                  control={control}
                  selectOption={arrayDropdown("MEETINGS")}
                  mode="multiple"
                  iProps={{
                    placeholder: "Select meetings",
                    suffixIcon: <DropdownIcon />,
                    disabled: checkNA.meetings,
                  }}
                  isRequired={!checkNA.meetings ? true : false}
                  rules={{
                    required: !checkNA.meetings
                      ? "Key Results is required."
                      : false,
                  }}
                  validate={!checkNA.meetings && errors.meetings && "error"}
                  validMessage={
                    !checkNA.meetings &&
                    errors.meetings &&
                    errors.meetings.message
                  }
                />
              </Col>
            </Row>
            <Row
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Col flex="0 0 395px">
                <h4 style={{ position: "absolute", right: "0", top: "-2px" }}>
                  <Checkbox
                    style={{ marginRight: "4px" }}
                    checked={checkNA.coached_by}
                    onChange={(e) => onChange(e, "coached_by")}
                  />{" "}
                  N/A
                </h4>

                <SelectField
                  fieldName="coached_by"
                  label="Select Role"
                  control={control}
                  selectOption={dropdownData?.roles}
                  iProps={{
                    placeholder: "Select role",
                    suffixIcon: <DropdownIcon />,
                    disabled: checkNA.coached_by,
                  }}
                  isRequired={!checkNA.coached_by ? true : false}
                  rules={{
                    required: !checkNA.coached_by
                      ? "Key Results is required."
                      : false,
                  }}
                  validate={!checkNA.coached_by && errors.coached_by && "error"}
                  validMessage={
                    !checkNA.coached_by &&
                    errors.coached_by &&
                    errors.coached_by.message
                  }
                />
              </Col>
              <Col flex="0 0 395px">
                <SelectField
                  fieldName="department"
                  label="Department"
                  control={control}
                  selectOption={arrayDropdown("DEPARTMENT")}
                  iProps={{
                    placeholder: "Select department",
                    suffixIcon: <DropdownIcon />,
                  }}
                  isRequired
                  rules={{
                    required: "Department is required.",
                  }}
                  validate={errors.department && "error"}
                  validMessage={errors.department && errors.department.message}
                />
              </Col>
              <Col
                className="fp_footer"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {setActiveTab && (
                  <div className="button_wrapper">
                    <Button
                      htmlType="button"
                      type="primary"
                      className="icon_white_btn"
                      disabled={activeNumber === 1}
                      onClick={() =>
                        totalLength !== activeNumber && activeNumber
                          ? setActiveTab((activeNumber - 1).toString())
                          : setActiveTab("jobName")
                      }
                    >
                      Previous
                    </Button>
                    <Button
                      htmlType="submit"
                      type="primary"
                      className="icon_white_btn"
                    >
                      Next
                    </Button>
                  </div>
                )}
                <Button
                  htmlType="button"
                  type="primary"
                  className="icon_dark_btn"
                  disabled
                >
                  Finish
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Card>
    </div>
  );
}
