import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const EyeOutlinedSvg = () => (
  <svg
    width="16"
    height="10"
    viewBox="0 0 16 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00008 0.666626C11.0001 0.666626 13.2001 2.0208 14.5334 3.23956C15.0667 3.64578 15.3334 4.32289 15.3334 4.99996C15.3334 5.67703 15.0667 6.35414 14.5334 6.76036C13.2001 7.97911 11.0001 9.33329 8.00008 9.33329C5.00008 9.33329 2.80008 7.91143 1.46675 6.76036C0.933415 6.35414 0.666748 5.67703 0.666748 4.99996C0.666748 4.32289 0.933415 3.64578 1.46675 3.23956C2.80008 2.0208 5.00008 0.666626 8.00008 0.666626ZM9.53371 4.93348C9.53371 5.78032 8.84722 6.46681 8.00037 6.46681C7.15353 6.46681 6.46704 5.78032 6.46704 4.93348C6.46704 4.08664 7.15353 3.40015 8.00037 3.40015C8.84722 3.40015 9.53371 4.08664 9.53371 4.93348Z"
      fill="#161616"
    />
  </svg>
);

const EyeOutlined = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={EyeOutlinedSvg} {...props} />
);

export default EyeOutlined;
