import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Input, List, Spin } from "antd";
import { AxiosError } from "axios";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { CloseIcon, PlusIcon } from "../../../atoms/CustomIcons";
import { updateCompanyFieldsById } from "../ducks/services";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import ErrorMessage from "../../../atoms/Toasts/ErrorMessage";
import SuccessMessage from "../../../atoms/Toasts/SuccessMessage";
import { getCompanyFieldsById } from "../ducks/actions";
import { getUserDetails } from "../../MyAccount/ducks/actions";
import { useLocation } from "react-router-dom";

const antIcon = <Loading3QuartersOutlined spin />;

export default function Technologies() {
  const dispatch = useAppDispatch();
  const [inputs, setInputs] = useState<string[]>([]);
  const [listData, setListData] = useState<string[]>([]);
  const [currentInput, setCurrentInput] = useState<string>("");
  const [addNew, setAddNew] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const url = useLocation();
  const companyId = url?.pathname.split("/")[2];
  const settingData = useAppSelector((state) => state?.settings?.companyFields);

  const handleAddInput = () => {
    if (currentInput.trim() !== "") {
      setInputs([currentInput, ...inputs]);
      setCurrentInput("");
      setAddNew(false);
      setListData([currentInput, ...inputs]);
    }
  };

  const handleDeleteInput = (index: number) => {
    const newInputs = inputs.filter((_, i) => i !== index);
    setInputs(newInputs);
    setListData(newInputs);
  };

  const searchItems = (value: any) => {
    const filteredInputs = inputs.filter((input) =>
      input.toLowerCase().includes(value.toLowerCase())
    );
    setSearchTerm(value);
    setListData(filteredInputs);
  };

  const updateSetting = () => {
    setLoading(true);
    const payload = {
      companyId: companyId,
      module: "TECHNOLOGIES",
      fields: listData,
    };
    updateCompanyFieldsById(payload)
      .then(() => {
        setLoading(false);
        SuccessMessage("Changes saved");
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  useEffect(() => {
    if (companyId) {
      const payload = {
        companyId: companyId,
        module: "TECHNOLOGIES",
      };
      dispatch(getCompanyFieldsById(payload));
    }
  }, [dispatch, companyId]);

  useEffect(() => {
    if (settingData?.listData) {
      const array: string[] = [];
      settingData?.listData?.map((e: { name: string }) => {
        array.push(e?.name);
      });
      setListData(array);
      setInputs(array);
    }
  }, [settingData?.listData]);

  useEffect(() => {
    dispatch(getUserDetails());
  }, [dispatch]);

  return (
    <Spin
      indicator={antIcon}
      size="large"
      spinning={loading || settingData?.loading}
    >
      <Card className="fp_layout_inner">
        <div className="cardContent" style={{ marginBottom: "48px" }}>
          <Form layout="vertical">
            <Row gutter={[0, 24]}>
              <Col>
                <h4>Technologies</h4>
              </Col>
              <Col span={24} className="Add_Departments">
                <div className="actionBar">
                  <Input
                    value={searchTerm}
                    onChange={(e) => searchItems(e.target.value)}
                    placeholder="Search technologies"
                    className="search_departments"
                  />
                  <Button
                    onClick={() => setAddNew(true)}
                    className="addNewItem icon_grey_btn"
                  >
                    <PlusIcon /> Add New
                  </Button>
                </div>

                <div className="departments_list">
                  {addNew && (
                    <div>
                      <Input
                        value={currentInput}
                        onChange={(e) => setCurrentInput(e.target.value)}
                        onPressEnter={(e) => {
                          if (e.key === "Enter") {
                            handleAddInput();
                          }
                        }}
                        placeholder="Type technology name"
                        className="enterNewCompany"
                      />
                    </div>
                  )}

                  <List
                    dataSource={listData}
                    renderItem={(item: string, index: number) => (
                      <List.Item
                        actions={[
                          <Button
                            type="link"
                            onClick={() => handleDeleteInput(index)}
                            icon={<CloseIcon />}
                            className="deleteBtn"
                          ></Button>,
                        ]}
                      >
                        {item}
                      </List.Item>
                    )}
                  />
                </div>
              </Col>
              <Col className="fp_footer">
                <Button
                  htmlType="button"
                  type="primary"
                  className="icon_dark_btn"
                  onClick={updateSetting}
                  disabled={listData?.length === 0}
                >
                  Save Changes
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Card>
    </Spin>
  );
}
