import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const tableSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.75 5V3.5C14.75 2.90326 14.5129 2.33097 14.091 1.90901C13.669 1.48705 13.0967 1.25 12.5 1.25H3.5C2.90326 1.25 2.33097 1.48705 1.90901 1.90901C1.48705 2.33097 1.25 2.90326 1.25 3.5V5H14.75Z"
      fill="#9396A6"
    />
    <path
      d="M1.25 6.5V12.5C1.25 13.0967 1.48705 13.669 1.90901 14.091C2.33097 14.5129 2.90326 14.75 3.5 14.75H7.25V6.5H1.25Z"
      fill="#9396A6"
    />
    <path
      d="M8.75 6.5V14.75H12.5C13.0967 14.75 13.669 14.5129 14.091 14.091C14.5129 13.669 14.75 13.0967 14.75 12.5V6.5H8.75Z"
      fill="#9396A6"
    />
  </svg>
);

const TableIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={tableSvg} {...props} />
);

export default TableIcon;
