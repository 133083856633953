import { useEffect, useMemo, useState } from "react";
import { Card, Button, Tabs, TabsProps, Dropdown } from "antd";
import FullPageLayout from "../../../../templates/FullPageLayout";
import { CloseIcon, PencilIcon, PlusIcon } from "../../../../atoms/CustomIcons";
import { useLocation, useNavigate } from "react-router-dom";
// import WorksheetGuide from "../../../../atoms/WorksheetGuide";
import { Popup } from "../../../../atoms/Popup";
import AddRole from "../../../../atoms/AddRole";
// import EditJobForm from "../../../../molecules/FormItems/EditJobForm";
import { useAppDispatch, useAppSelector } from "../../../../config/hooks";
import { getJobDetails } from "../../../../organisms/MyJob/ducks/actions";
import { RoleType } from "../../../..//organisms/MyJob/ducks/types";
import RoleForm from "../Components/RoleForm";
import EditJobForm from "../../../..//molecules/FormItems/EditJobForm";
import AddRoleForm from "../Components/AddRoleForm";

export default function EditJob() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [jobTabsItems, setJobTabsItems] = useState<TabsProps["items"]>([]);
  const [existingRolesId, setExistingRolesId] = useState<Array<string>>([]);
  const [selectedRoleName, setSelectedRoleName] = useState("");
  const url = useLocation();
  const jobId = url?.pathname.split("/")[2];
  const companyId = url?.pathname.split("/")[3];
  const customArray: TabsProps["items"] = [];
  const jobDetailData = useAppSelector(
    (state) => state?.jobDetail?.jobDetailData
  );
  const jobItems: TabsProps["items"] = [
    {
      key: "jobTitle",
      label: "Job",
      disabled: true,
    },
    {
      key: "jobName",
      label: jobDetailData?.detailData?.name,
      children: <EditJobForm />,
      icon: <PencilIcon />,
    },
  ];

  const addRolePopup = {
    visibility: isDeleteModalOpen,
    class: "delete_confirmation_modal",
    content: (
      <AddRole
        title="Add roles to the job"
        heading="Select roles you want to add to Director of Ops."
        onClose={() => setIsDeleteModalOpen(false)}
        btnText="Add role"
        existingRoles={existingRolesId}
        jobId={jobId}
        companyId={companyId}
      />
    ),
    width: 520,
    onCancel: () => setIsDeleteModalOpen(false),
  };

  useEffect(() => {
    if (jobId) {
      dispatch(getJobDetails(jobId));
    }
  }, [jobId]);

  useMemo(() => {
    if (jobDetailData?.detailData?.roles?.length > 0) {
      jobDetailData?.detailData?.roles?.map((e: RoleType, i: number) =>
        customArray.push({
          key: `${i + 1}`,
          label: (
            <div
              className="tab-label"
              style={{ display: "flex", alignItems: "center" }}
            >
              <span className="tab-number">{i + 1}</span> {e?.name}
            </div>
          ),
          children: <RoleForm data={e} setLoading={setLoading} />,
        })
      );

      const tabItems = [...customArray, ...jobItems];
      setJobTabsItems(tabItems);
    } else {
      const newRoleArray: TabsProps["items"] = [
        {
          key: "newRole",
          label: (
            <div
              className="tab-label"
              style={{ display: "flex", alignItems: "center" }}
            >
              <span className="tab-number">1</span>{" "}
              {selectedRoleName ? selectedRoleName : "Role Name"}
            </div>
          ),
          children: (
            <AddRoleForm
              companyId={companyId}
              jobId={jobId}
              setSelectedRoleName={setSelectedRoleName}
            />
          ),
        },
      ];

      const tabItems = [...newRoleArray, ...jobItems];
      setJobTabsItems(tabItems);
    }

    const existingRoles: string[] = [];
    jobDetailData?.detailData?.roles?.map((e: { id: string }) => {
      existingRoles.push(e?.id);
      return existingRoles;
    });
    setExistingRolesId(existingRoles);
  }, [jobDetailData?.detailData?.roles, selectedRoleName]);

  const addNewRole = () => {
    jobDetailData?.detailData?.roles?.map((e: RoleType, i: number) =>
      customArray.push({
        key: `${i + 1}`,
        label: (
          <div
            className="tab-label"
            style={{ display: "flex", alignItems: "center" }}
          >
            <span className="tab-number">{i + 1}</span> {e?.name}
          </div>
        ),
        children: <RoleForm data={e} setLoading={setLoading} />,
      })
    );
    const addRoleArray: TabsProps["items"] = [
      {
        key: jobDetailData?.detailData?.roles?.length + 1,
        label: (
          <div
            className="tab-label"
            style={{ display: "flex", alignItems: "center" }}
          >
            <span className="tab-number">1</span>{" "}
            {selectedRoleName ? selectedRoleName : "Role Name"}
          </div>
        ),
        children: (
          <AddRoleForm
            companyId={companyId}
            jobId={jobId}
            setSelectedRoleName={setSelectedRoleName}
          />
        ),
      },
    ];

    const tabItems = [...customArray, ...addRoleArray, ...jobItems];
    setJobTabsItems(tabItems);
  };

  const addRoleDropdown = [
    {
      key: "1",
      label: (
        <Button onClick={() => setIsDeleteModalOpen(true)}>
          Add existing role
        </Button>
      ),
    },
    {
      key: "2",
      label: <Button onClick={addNewRole}>Add new role</Button>,
    },
  ];
  return (
    <FullPageLayout loading={loading}>
      <Card className="fp_layout_title">
        <div className="mainTitle">
          <Button
            className="icon_grey_btn"
            icon={<CloseIcon />}
            onClick={() => navigate(-1)}
          >
            Close
          </Button>
          <h3>Edit Job</h3>
        </div>
      </Card>

      <div className="fp_layout_content" style={{ height: "auto" }}>
        <div className="editJob">
          <div className="controlPanel">
            <div className="title">
              <h6>Roles</h6>
              <Dropdown
                menu={{ items: addRoleDropdown }}
                trigger={["click"]}
                placement="bottomRight"
                className="actionButton"
                overlayClassName="full_width_btn"
              >
                <Button>
                  <span>
                    <PlusIcon className="PlusIcon" />
                    Add role
                  </span>
                </Button>
              </Dropdown>
            </div>
            <div className="role_job_list">
              <Tabs
                tabPosition="left"
                items={jobTabsItems}
                defaultActiveKey={
                  jobDetailData?.detailData?.roles?.length > 0
                    ? "jobName"
                    : "newRole"
                }
              />
            </div>
          </div>
          {/* <div className="contentPanel">
            <div className="drawerButton">
              <WorksheetGuide />
            </div>
            <div className="title_bar">
              <div className="mainTitle">
                <div>
                  <img
                    src="/images/EditDarkIcon.svg"
                    style={{ marginRight: "12px" }}
                  />
                </div>
                <div>
                  <h4>Job: Director of Ops</h4>
                  <p>
                    Now, look at your job as a whole and complete the details
                    about it.
                  </p>
                </div>
              </div>
              <div className="formLink">
                Fill out from roles
                <img src="/images/Fill_out.svg" style={{ marginLeft: "6px" }} />
              </div>
            </div>
            <div>
              <EditJobForm />
            </div>
          </div> */}
        </div>
      </div>

      <Popup {...addRolePopup} />
    </FullPageLayout>
  );
}
