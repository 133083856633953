import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const CloseSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.39956 3.39859C3.65106 3.14709 4.05883 3.14709 4.31033 3.39859L8.00094 7.0892L11.6916 3.39859C11.9431 3.14709 12.3508 3.14709 12.6023 3.39859C12.8538 3.65009 12.8538 4.05785 12.6023 4.30935L8.9117 7.99996L12.6023 11.6906C12.8538 11.9421 12.8538 12.3498 12.6023 12.6013C12.3508 12.8528 11.9431 12.8528 11.6916 12.6013L8.00094 8.91072L4.31033 12.6013C4.05883 12.8528 3.65106 12.8528 3.39956 12.6013C3.14806 12.3498 3.14806 11.9421 3.39956 11.6906L7.09018 7.99996L3.39956 4.30935C3.14806 4.05785 3.14806 3.65009 3.39956 3.39859Z"
      fill="white"
    />
  </svg>
);

const CloseIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={CloseSvg} {...props} />
);

export default CloseIcon;
