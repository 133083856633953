import { Fragment, useEffect, useMemo, useState } from "react";
import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";
import { createPortal } from "react-dom";
import ColumnContainer from "./Components/ColumnContainer";
import RoleCard from "./Components/RoleCard";
import { Column, Id, Role } from "./types";
import BasketContainer from "./Components/BasketContainer";
import { Button, Col, Row, Image, Avatar } from "antd";
import {
  addRoleDescriptor,
  deleteRoleDescriptorById,
} from "../../organisms/MyJob/ducks/services";
import { SessionTypeResponse } from "../../organisms/MyJob/ducks/types";
import { getInitials } from "../../config/constants";

interface PropsType {
  roleData: Role[];
  roles: Role[];
  setRoles: React.Dispatch<React.SetStateAction<Role[]>>;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  transformData: () => void;
  sessionId: string;
  sessionData: SessionTypeResponse;
}

export default function KanbanBoard(props: PropsType) {
  const {
    roleData,
    setCurrent,
    transformData,
    roles,
    setRoles,
    sessionId,
    sessionData,
  } = props;
  const [columns, setColumns] = useState<Column[]>([]);
  const [columnBasket, setColumnBasket] = useState<Column[]>([]);
  const [columnsWithId, setColumnsWidthId] = useState<Column[]>([]);
  const [roleToUpdate, setRoleToUpdate] = useState<any>(undefined);
  //   const [activeColumn, setActiveColumn] = useState(null);
  const [activeRole, setActiveRole] = useState(null);
  const columnsId = useMemo(() => columns?.map((col) => col?.id), [columns]);
  console.log("roleData", roleData);

  const groupedData = roleData?.reduce(
    (
      acc: { [key: string]: { column_id: string; descriptors: string[] } },
      item
    ) => {
      const { column_id } = item;
      if (!acc[column_id]) {
        acc[column_id] = {
          column_id: column_id.toString(),
          descriptors: [item.descriptor],
        };
      } else {
        acc[column_id].descriptors.push(item.descriptor);
      }
      return acc;
    },
    {}
  );

  useEffect(() => {
    if (roleData) {
      const columnsData = Object.values(groupedData);

      const columnArray: Column[] = [];
      const columnBasket: Column[] = [
        {
          id: "basket",
          title: "Basket",
        },
      ];
      columnsData?.map((col) => {
        columnArray.push({
          id: col.column_id,
          title: `Column ${columnArray.length + 1}`,
        });
      });
      const columnHaveBasket = columnArray.filter((col) => col.id === "basket");

      if (columnHaveBasket?.length > 0) {
        setColumns(columnArray);
      } else {
        setColumns([...columnBasket, ...columnArray]);
      }

      setRoles(roleData);
    }
  }, [setRoles, roleData]);

  console.log("columns", columns);

  const createNewColumn = () => {
    const columnToAdd = {
      id: generateId(),
      title: `Column ${columns.length + 1}`,
    };

    setColumns([...columns, columnToAdd]);
  };

  useEffect(() => {
    if (columns) {
      const basketColumn = columns.filter(
        (col: Column) => col?.id === "basket"
      );
      const IdColumn = columns.filter((col: Column) => col?.id !== "basket");

      setColumnBasket(basketColumn);
      setColumnsWidthId(IdColumn);
    }
  }, [columns]);

  const deleteColumn = (id: Id) => {
    const filteredColumns = columns?.filter((e) => e?.id !== id);
    const newRoles = roles?.filter((role) => role?.column_id !== id);
    setColumns(filteredColumns);
    setRoles(newRoles);
  };

  const onDragStart = (event: DragStartEvent) => {
    // if (event.active.data.current?.type === "Column") {
    //   setActiveColumn(event.active.data.current.column);
    //   return;
    // }

    console.log("event", event);

    if (event.active.data.current?.type === "Role") {
      setActiveRole(event.active.data.current.role);
      return;
    }
  };

  const onDragEnd = (event: DragEndEvent) => {
    // setActiveColumn(null);
    console.log("event en", event);
    setActiveRole(null);
    const { over, active } = event;
    if (!over) return;

    const activeColumnId = active.id;
    const overColumnId = over.id;

    if (activeColumnId === overColumnId) return;

    if (active.id !== over.id) {
      setColumns((column) => {
        const oldIndex = column.findIndex((col) => col?.id === activeColumnId);
        const newIndex = column.findIndex((col) => col?.id === overColumnId);

        return arrayMove(column, oldIndex, newIndex);
      });
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 3,
      },
    })
  );

  const createRole = () => {
    const payload = {
      session_id: sessionId,
      descriptor: `Role ${roles?.length + 1}`,
      index: roles?.length + 1,
    };

    addRoleDescriptor(payload).then((res) => {
      console.log("res", res.data);
      setRoles([...roles, res.data]);
    });
  };

  const deleteRole = (id: Id) => {
    const filteredRoles = roles?.filter((e) => e?.id !== id);
    deleteRoleDescriptorById(id).then(() => {
      setRoles(filteredRoles);
    });
  };

  const onDragOver = (event: DragOverEvent) => {
    const { over, active } = event;
    if (!over) return;

    const activeId = active.id;
    const overId = over.id;

    if (activeId === overId) return;

    const isActiveARole = active.data.current?.type === "Role";
    const isOverARole = over.data.current?.type === "Role";

    if (!isActiveARole) return;

    if (isActiveARole && isOverARole) {
      setRoles((roles) => {
        const activeIndex = roles?.findIndex((role) => role?.id === activeId);
        const overIndex = roles?.findIndex((role) => role?.id === overId);

        roles[activeIndex].column_id = roles[overIndex].column_id;
        return arrayMove(roles, activeIndex, overIndex);
      });
    }

    const isOverAColumn = over.data.current?.type === "Column";

    if (isActiveARole && isOverAColumn) {
      setRoles((roles: Role[]) => {
        const activeIndex = roles?.findIndex((t) => t?.id === activeId);

        roles[activeIndex].column_id = overId;
        return arrayMove(roles, activeIndex, activeIndex);
      });
    }
  };

  const generateId = () => {
    return Math.floor(Math.random() * 10001);
  };

  console.log("roles", roles);

  const updateRole = (id: Id, descriptor: string) => {
    const updatedLocal = roles.map((role: Role) => {
      if (role.id !== id) {
        return role;
      }
      return { ...role, descriptor };
    });
    const findRoleById = roles.find((role) => role.id === id);
    const updatedRole = { ...findRoleById, descriptor };

    setRoleToUpdate(updatedRole);
    setRoles(updatedLocal);
  };
  return (
    <div className="kanban_board">
      <DndContext
        sensors={sensors}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onDragOver={onDragOver}
      >
        <Row gutter={16}>
          <SortableContext items={columnsId}>
            <Col span={8}>
              {columnBasket?.map((col: Column) => (
                <Fragment key={col?.id}>
                  <BasketContainer
                    column={col}
                    createRole={createRole}
                    roles={roles?.filter((e) => e?.column_id === "basket")}
                    deleteRole={deleteRole}
                    setCurrent={setCurrent}
                    transformData={transformData}
                    updateRole={updateRole}
                    roleToUpdate={roleToUpdate}
                  />
                </Fragment>
              ))}
            </Col>
            <Col span={16}>
              <div className="role_right_wrapper">
                <div className="role_right_top">
                  <h6>Flower petals</h6>
                  <div className="facilitator_details">
                    {sessionData?.facilitator?.image?.url ? (
                      <div className="participant_avatar">
                        <Avatar src={sessionData?.facilitator?.image?.url} />
                      </div>
                    ) : (
                      <div className="participant_avatar">
                        <Avatar>
                          {getInitials(
                            sessionData?.facilitator?.first_name,
                            sessionData?.facilitator?.last_name
                          )}
                        </Avatar>
                      </div>
                    )}

                    <div style={{ marginLeft: 8, display: "flex" }}>
                      <h4>
                        {sessionData?.facilitator?.first_name ?? ""}{" "}
                        {sessionData?.facilitator?.last_name ?? ""}{" "}
                      </h4>
                      <p>
                        {sessionData?.facilitator?.job?.name
                          ? " | " + sessionData?.facilitator?.job?.name
                          : ""}
                      </p>
                    </div>
                  </div>
                </div>
                <Row gutter={[16, 16]}>
                  {columnsWithId?.map((col: Column, index: number) => (
                    <Col span={12} key={col?.id}>
                      <ColumnContainer
                        column={col}
                        deleteColumn={deleteColumn}
                        roles={roles?.filter(
                          (e) =>
                            e?.column_id === col?.id &&
                            e?.column_id !== "basket"
                        )}
                        deleteRole={deleteRole}
                        index={index}
                        updateRole={updateRole}
                        roleToUpdate={roleToUpdate}
                      />
                    </Col>
                  ))}
                  <Col span={12}>
                    <Button className="addPetal-btn" onClick={createNewColumn}>
                      <Image
                        src="/images/plus-icon.svg"
                        preview={false}
                        width={24}
                      />{" "}
                      Add Column
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </SortableContext>
        </Row>

        {createPortal(
          <DragOverlay>
            {/* {activeColumn && (
              <ColumnContainer
                column={activeColumn}
                deleteColumn={deleteColumn}
                roles={roles?.filter(
                  (role: Role) => role?.column_id === activeColumn
                )}
                deleteRole={deleteRole}
              />
            )} */}
            {activeRole && (
              <RoleCard role={activeRole} deleteRole={deleteRole} />
            )}
          </DragOverlay>,
          document.body
        )}
      </DndContext>
    </div>
  );
}
