import * as action_types from "./constants";

export type settingsState = {
  companyFields: {
    loading: boolean;
    listData: [];
  };
  companyDetail: {
    name: string;
  };
};

const initialState: settingsState = {
  companyFields: {
    loading: true,
    listData: [],
  },
  companyDetail: {
    name: "",
  },
};

export default (state = initialState, action: any) => {
  const { type, data } = action;
  console.log("action data", data?.data);
  switch (type) {
    case action_types.COMPANY_FIELDS:
      return {
        ...state,
        companyFields: { listData: data?.data, loading: action?.data?.loading },
      };

    case action_types.COMPANY_DETAILS:
      return { ...state, companyDetail: data };

    default:
      return state;
  }
};
