import { useEffect, useState } from "react";
import { Row, Col, Button, Card, Form } from "antd";
import InputField from "../../../molecules/FormItems/InputField";
import UploadPhoto from "../../../molecules/FormItems/UploadPhoto";
import { useForm } from "react-hook-form";
import {
  onImageDelete,
  uploadUserImage,
} from "../../../organisms/MyAccount/ducks/services";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import ErrorMessage from "../../../atoms/Toasts/ErrorMessage";
import SuccessMessage from "../../../atoms/Toasts/SuccessMessage";
import { AxiosError } from "axios";
import RadioGroup from "../../../molecules/FormItems/RadioGroup";
import { useLocation } from "react-router-dom";
import { getCompanyDetails } from "../ducks/actions";
import { CreateCompanyType } from "../../../organisms/Companies/ducks/types";
import { updateCompanyById } from "../../../organisms/Companies/ducks/services";

export default function GeneralSettings() {
  const dispatch = useAppDispatch();
  const url = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [checklistFile, setChecklistFile] = useState<any>({});
  const [uploadModal, setUploadModal] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<{
    file: string;
    imagePreviewUrl: any;
  }>({ file: "", imagePreviewUrl: null });
  const [modalPreviewImage, setModalPreviewImage] = useState<{
    file: string;
    imagePreviewUrl: any;
  }>({ file: "", imagePreviewUrl: null });
  const { control, setValue, handleSubmit } = useForm<CreateCompanyType>();

  const companyId = url?.pathname.split("/")[2];
  const companyData = useAppSelector((state) => state?.settings?.companyDetail);

  const onSubmit = (val: CreateCompanyType) => {
    setLoading(true);
    const payload = {
      name: val?.name,
      location: val?.location,
      labelPosition: val?.labelPosition,
    };
    updateCompanyById(payload, companyId)
      .then(() => {
        setLoading(false);
        SuccessMessage("Company updated.");
        dispatch(getCompanyDetails(companyId));
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  useEffect(() => {
    if (companyId) {
      dispatch(getCompanyDetails(companyId));
    }
  }, [companyId, dispatch]);

  useEffect(() => {
    if (companyData) {
      setValue("name", companyData?.name);
      setValue("location", companyData?.location);
      setValue("labelPosition", companyData?.label_position);
    }
  }, [companyData]);

  const saveUpload = () => {
    setLoading(true);
    setPreviewImage({ ...modalPreviewImage });
    uploadUserImage({
      owner_id: companyId,
      image: checklistFile?.uid ? checklistFile : undefined,
      image_type: "company_image",
    })
      .then((res) => {
        if (res) {
          SuccessMessage("Image changed.");
          dispatch(getCompanyDetails(companyId));
          setLoading(false);
          setUploadModal(false);
        }
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  function callUserDetailsAPI() {
    dispatch(getCompanyDetails(companyId));
  }

  const radioData: Array<{ label: string; value: string }> = [
    {
      label: "Job",
      value: "Job",
    },
    {
      label: "Seat",
      value: "Seat",
    },
  ];

  return (
    <Card className="fp_layout_inner">
      <div className="cardContent" style={{ marginBottom: "48px" }}>
        <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
          <Row gutter={[0, 24]}>
            <Col>
              <h4>General Settings</h4>
            </Col>
            <Col span={24}>
              <Row gutter={21}>
                <Col flex="0 0 273px">
                  <h4
                    style={{
                      marginBottom: "8px",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    Logo
                  </h4>
                </Col>
                <Col>
                  <div className="leftCardDiv">
                    <UploadPhoto
                      uploadAvatar={
                        companyData?.image?.url
                          ? companyData?.image?.url
                          : "/images/upload-avatar.svg"
                      }
                      imageUploaded={companyData?.image?.url}
                      uploadedImageURL={
                        companyData?.image?.url
                          ? companyData?.image?.url
                          : undefined
                      }
                      width={88}
                      height={88}
                      saveUpload={saveUpload}
                      loading={loading}
                      uploadModal={uploadModal}
                      setUploadModal={setUploadModal}
                      onImageDelete={() => {
                        onImageDelete(
                          setLoading,
                          SuccessMessage,
                          callUserDetailsAPI,
                          ErrorMessage,
                          companyData?.image?.id
                            ? companyData?.image?.id
                            : undefined
                        );
                      }}
                      previewImage={previewImage}
                      checklistFile={checklistFile}
                      modalPreviewImage={modalPreviewImage}
                      setChecklistFile={setChecklistFile}
                      setPreviewImage={setPreviewImage}
                      setModalPreviewImage={setModalPreviewImage}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={21}>
                <Col flex="0 0 533px">
                  <InputField
                    fieldName="name"
                    label="Name"
                    control={control}
                    initValue=""
                    iProps={{
                      placeholder: "Enter company name",
                    }}
                  />
                </Col>
                <Col flex="0 0 533px">
                  <InputField
                    fieldName="location"
                    label="Location"
                    control={control}
                    initValue=""
                    iProps={{
                      placeholder: "Enter company location",
                    }}
                  />
                </Col>
                <Col flex="0 0 533px" style={{ marginBottom: "30px" }}>
                  <div className="radioTags">
                    <RadioGroup
                      fieldName="labelPosition"
                      label="Label positions as:"
                      control={control}
                      optionsData={radioData}
                      onChangeRadio={() => null}
                    />
                  </div>
                </Col>
                <Col className="fp_footer">
                  <div>
                    <Button
                      htmlType="submit"
                      type="primary"
                      className="icon_dark_btn"
                    >
                      Save Changes
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
    </Card>
  );
}
