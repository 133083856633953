import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ConfigProvider } from "antd";
import "./app/assets/scss/style.scss";
import Login from "./app/organisms/Login";
import ForgotPassword from "./app/organisms/ForgotPassword";
import UpdatePassword from "./app/organisms/UpdatePassword";
import MyAccount from "./app/organisms/MyAccount";
import { isLoggedIn } from "./app/config/utils";
import Dashboard from "./app/organisms/Dashboard";
import NoPermission from "./app/organisms/NoPermission";
import ChangePassword from "./app/organisms/ChangePassword";
import CompanySettings from "./app/organisms/CompanySettings";
import CompanyDetails from "./app/organisms/CompanyDetails/Jobs";
import MyJob from "./app/organisms/MyJob";
import Employees from "./app/organisms/CompanyDetails/Employees";
import Roles from "./app/organisms/CompanyDetails/Roles";
import AddEmployees from "./app/organisms/CompanyDetails/Employees/AddEmployees";
import AddRoles from "./app/organisms/CompanyDetails/Roles/AddRoles";
import JobDetail from "./app/organisms/CompanyDetails/Jobs/JobDetail";
import Companies from "./app/organisms/Companies";
import CreateCompany from "./app/organisms/Companies/CreateCompany";
import SystemSettings from "./app/organisms/SystemSettings";
import EditJob from "./app/organisms/CompanyDetails/Jobs/EditJob";
import EmployeeDetails from "./app/organisms/CompanyDetails/Employees/EmployeeDetails";
import AddUser from "./app/organisms/SystemSettings/AddUser";
import EditUser from "./app/organisms/SystemSettings/EditUser";
import EditRoles from "./app/organisms/CompanyDetails/Roles/EditRoles";
import SessionSteps from "./app/atoms/JobDetails/Components/FlowerSession/SessionSteps";
import GenerateList from "./app/organisms/GenerateList";
import EditEmployee from "./app/organisms/CompanyDetails/Employees/EditEmployee";
import ThanksForList from "./app/organisms/GenerateList/ThanksForList";
import SorryScreen from "./app/organisms/GenerateList/SorryScreen";
import WSSession from "./app/organisms/CompanyDetails/Jobs/WSSession";

function App() {
  const auth = isLoggedIn();

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "DM Sans",
        },
      }}
    >
      <div className="App">
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/update-password" element={<UpdatePassword />} />
            {auth ? (
              <>
                <Route path="/" element={<Dashboard />} />
                <Route path="/no-permission" element={<NoPermission />} />
                <Route
                  path="/company-detail/jobs/:id"
                  element={<CompanyDetails />}
                />
                <Route
                  path="/company-detail/employees/:id"
                  element={<Employees />}
                />
                <Route path="/company-detail/roles/:id" element={<Roles />} />
                <Route path="/add-employees/:id" element={<AddEmployees />} />
                <Route
                  path="/edit-employee/:id/:id"
                  element={<EditEmployee />}
                />
                <Route path="/add-user" element={<AddUser />} />
                <Route path="/edit-user/:id" element={<EditUser />} />
                <Route path="/employee-details" element={<EmployeeDetails />} />
                <Route path="/create-role/:id" element={<AddRoles />} />
                <Route path="/edit-role/:id/:id" element={<EditRoles />} />
                <Route path="/my-account" element={<MyAccount />} />
                <Route path="/change-password" element={<ChangePassword />} />
                <Route
                  path="/company-settings/:id"
                  element={<CompanySettings />}
                />
                <Route path="/my-job" element={<MyJob />} />
                <Route path="/job-detail/:id/:id" element={<JobDetail />} />
                <Route path="/edit-job/:id/:id" element={<EditJob />} />
                <Route path="/ws-session/:id/:id" element={<WSSession />} />
                <Route path="/companies" element={<Companies />} />
                <Route path="/create-company" element={<CreateCompany />} />
                <Route path="/system-settings" element={<SystemSettings />} />
                <Route path="/flower-session/:id" element={<SessionSteps />} />
                <Route
                  path="/fp-session/generate-list/:id"
                  element={<GenerateList />}
                />
                <Route
                  path="/fp-session/thanks/:id"
                  element={<ThanksForList />}
                />
                <Route
                  path="/fp-session/sorry-screen/:id"
                  element={<SorryScreen />}
                />
              </>
            ) : (
              <Route path="*" element={<Navigate to="/login" replace />} />
            )}
          </Routes>
        </Router>
      </div>
    </ConfigProvider>
  );
}

export default App;
