import { TabsProps, Tabs } from "antd";
import { FlowerIcon, TableIcon } from "../../../../atoms/CustomIcons";
import FlowerView from "../FlowerView";
import TableView from "../TableView";
import { JobDetailType } from "../../../../organisms/MyJob/ducks/types";

type PropsType = {
  jobDetailData: JobDetailType;
};

export default function JobRoles(props: PropsType) {
  const { jobDetailData } = props;
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Flower view",
      children: <FlowerView jobDetailData={jobDetailData} />,
      icon: <TableIcon />,
    },
    {
      key: "2",
      label: "Table view",
      children: <TableView jobDetailData={jobDetailData} />,
      icon: <FlowerIcon />,
    },
  ];

  return (
    <>
      <h2 style={{ position: "absolute", top: "12px" }}> Job roles </h2>
      <Tabs defaultActiveKey="1" items={items} className="job_roles_tabs" />
    </>
  );
}
