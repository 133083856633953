import { Button, Card, Col, Row, Select } from "antd";
import {
  updateRoleDescriptor,
  updateSessionById,
} from "../../../../../../../organisms/MyJob/ducks/services";
import SessionFlowerFinalized from "../SessionFlowerFinalized";
import { SessionTypeResponse } from "../../../../../../../organisms/MyJob/ducks/types";
import { getFlowerDataBySessionID } from "../../../../../../../organisms/MyJob/ducks/actions";
import { useAppDispatch } from "../../../../../../../config/hooks";

type PropsType = {
  data: any;
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  activeData?: any;
  activeNumber: number;
  petalData: any;
  sessionId: string;
  petalTeams: any;
  sessionData: SessionTypeResponse;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function SelectFpSessionCategory(props: PropsType) {
  const {
    data,
    activeTab,
    setActiveTab,
    activeData,
    setCurrent,
    activeNumber,
    sessionId,
    petalTeams,
    sessionData,
    setLoading,
  } = props;
  const dispatch = useAppDispatch();
  console.log("activeData", activeData);

  const submitSFNames = () => {
    setLoading(true);
    const payloadScreen = {
      last_session_screen: 8,
    };
    updateSessionById(payloadScreen, sessionId).then(() => {
      setLoading(false);
      setCurrent(8);
    });
  };

  const selectDescriptor = (value: string, text: string, id: string) => {
    const payload = {
      id: id,
      descriptor: text,
      category: value,
    };
    updateRoleDescriptor(payload).then(() => {
      dispatch(getFlowerDataBySessionID(sessionId));
    });
  };
  return (
    <Row>
      <Col span={13}>
        <div className="session_flower finalizedFlower">
          <div
            className="flower_wrapper"
            style={{ height: "calc(100vh - 130px)" }}
          >
            <SessionFlowerFinalized
              data={data}
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              setCurrent={setCurrent}
              activeData={activeData}
              petalTeams={petalTeams}
              categorized={true}
            />
            <div className="flower_position">{sessionData?.job?.name}</div>
          </div>
        </div>
      </Col>
      <Col span={11}>
        <Card className="session_fp_card fp_session_role session_inner_tab">
          <h4> Categorize role descriptors </h4>
          <p>
            Select whether its a Responsibility or a Procedure/Task. Optional
            step.
          </p>
          <div className="content_section">
            <div className="active_state" style={{ marginBottom: "16px" }}>
              <h6 className={`tagNumber_${activeNumber}`}> {activeNumber} </h6>
              {activeData?.finalized_name}
            </div>

            <div className="role_description">
              <h4> Role descriptors: </h4>
              <ul>
                {activeData?.descriptors?.map(
                  (e: { descriptor: string; id: string; category: string }) => (
                    <li>
                      <div className="descriptors_text">{e?.descriptor}</div>
                      <Select
                        defaultValue={
                          e?.category ? e?.category : "UNCATEGORIZED"
                        }
                        onChange={(value) =>
                          selectDescriptor(value, e.descriptor, e.id)
                        }
                        options={[
                          { value: "UNCATEGORIZED", label: "Uncategorized" },
                          { value: "PROCEDURE_TASK", label: "Procedure/Task" },
                          { value: "RESPONSIBILITY", label: "Responsibility" },
                        ]}
                      />
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>

          <div className="move_buttons">
            <Button
              htmlType="button"
              className="icon_grey_btn"
              type="primary"
              size="large"
              disabled={activeTab === "1"}
              onClick={() => {
                setActiveTab((+activeTab - 1).toString());
              }}
            >
              Previous Role
            </Button>
            <Button
              htmlType="button"
              className="icon_grey_btn"
              type="primary"
              size="large"
              disabled={+activeTab === data?.length}
              onClick={() => {
                setActiveTab((+activeTab + 1).toString());
              }}
            >
              Next Role
            </Button>
          </div>
          <div className="steps_footer">
            <div className="steps_btn_wrapper">
              <Button
                htmlType="button"
                className="icon_grey_btn"
                type="primary"
                size="large"
                onClick={() => setCurrent(6)}
              >
                Back
              </Button>
              <Button
                htmlType="button"
                className="icon_dark_btn"
                type="primary"
                size="large"
                onClick={submitSFNames}
              >
                Continue
              </Button>
            </div>
          </div>
        </Card>
      </Col>
    </Row>
  );
}
