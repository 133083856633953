import { Row, Col, Button, Card, Form, Checkbox, Image } from "antd";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import FullPageLayout from "../../../../templates/FullPageLayout";
import { CloseIcon, DropdownIcon } from "../../../../atoms/CustomIcons";
import TextEditor from "../../../../molecules/FormItems/TextEditor";
import { useCallback, useEffect, useState } from "react";
import { getUserDetails } from "../../../MyAccount/ducks/actions";
import { useAppDispatch, useAppSelector } from "../../../../config/hooks";
import SelectField from "../../../../molecules/FormItems/SelectField";
import {
  getCompanyFieldsByIdDrop,
  getDropdownsByCompany,
  getRoleDetails,
} from "../../ducks/actions";
import { updateRoleById } from "../../ducks/services";
import { UpdateRole } from "../../ducks/types";
import SuccessMessage from "../../../../atoms/Toasts/SuccessMessage";
import { AxiosError } from "axios";
import ErrorMessage from "../../../../atoms/Toasts/ErrorMessage";

export default function EditRoles() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const url = useLocation();
  const roleId = url?.pathname.split("/")[2];
  const companyId = url?.pathname.split("/")[3];
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<UpdateRole>();
  const [loading, setLoading] = useState(false);
  const settingData = useAppSelector(
    (state) => state?.companyDetails?.fieldsDropdown
  );
  const dropdownData = useAppSelector(
    (state) => state?.companyDetails?.roleDropdown
  );

  const roleData = useAppSelector(
    (state) => state?.companyDetails?.roleDetails
  );

  const optionsForDropdown = [
    { value: "Option 1", label: "Option 1" },
    { value: "Option 2", label: "Option 2" },
    { value: "Option 3", label: "Option 3" },
  ];

  const onSubmit = (val: UpdateRole) => {
    setLoading(false);
    const payload = {
      name: roleData?.details?.name,
      jobs: val?.jobs,
      purpose_statement: val?.purpose_statement,
      objectives: val?.objectives,
      key_results: val?.key_results,
      procedures: val?.procedures,
      workflows: val?.workflows,
      skills: val?.skills,
      technologies: val?.technologies,
      teams: val?.teams,
      entities: val?.entities,
      meetings: val?.meetings,
      company_id: companyId,
      coached_by: val?.coached_by,
      department: val?.department,
    };

    updateRoleById(payload, roleId)
      .then(() => {
        setLoading(false);
        SuccessMessage("Role updated.");
        navigate(`/company-detail/roles/${companyId}`);
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  useEffect(() => {
    if (companyId) {
      const payload = {
        companyId: companyId,
      };
      const dropdownPayload = {
        companyId: companyId,
        sendRoles: true,
        sendUsers: true,
        sendJobs: true,
      };
      dispatch(getCompanyFieldsByIdDrop(payload));
      dispatch(getDropdownsByCompany(dropdownPayload));
    }
  }, [dispatch, companyId]);

  useEffect(() => {
    dispatch(getUserDetails());
  }, [dispatch]);

  const arrayDropdown = useCallback(
    (moduleName: string) => {
      const filteredArray = settingData?.filter(
        (e: { module: string; name: string; value: string }) =>
          e?.module === moduleName
      );
      return filteredArray?.map((e: { name: string; value: string }) => ({
        label: e?.name,
        value: e?.value,
      }));
    },
    [settingData]
  );

  useEffect(() => {
    if (roleId) {
      dispatch(getRoleDetails(roleId));
    }
  }, [roleId]);

  useEffect(() => {
    if (roleData?.details) {
      setValue(
        "jobs",
        roleData?.details?.jobs?.map((e: { id: string }) => e?.id)
      );
      setValue("purpose_statement", roleData?.details?.purpose_statement);
      setValue("objectives", roleData?.details?.objectives);
      setValue("key_results", roleData?.details?.key_results);
      setValue("procedures", roleData?.details?.procedures);
      setValue("workflows", roleData?.details?.workflows);
      setValue("skills", roleData?.details?.skills);
      setValue("technologies", roleData?.details?.technologies);
      setValue("entities", roleData?.details?.entities);
      setValue("teams", roleData?.details?.teams);
      setValue("meetings", roleData?.details?.meetings);
      setValue("coached_by", roleData?.details?.coached_by);
      setValue("department", roleData?.details?.department);
    }
  }, [roleData?.details]);

  console.log("roleData?.details", roleData?.details);

  return (
    <FullPageLayout loading={roleData?.loading || loading}>
      <Card className="fp_layout_title">
        <div className="mainTitle">
          <Button
            className="icon_grey_btn"
            icon={<CloseIcon />}
            onClick={() => navigate(-1)}
          >
            Close
          </Button>
          <h3>Edit Role</h3>
        </div>
      </Card>
      <Card
        className="fp_layout_content"
        style={{ height: "100%", maxWidth: "992px", margin: "0 auto" }}
      >
        <div
          className="cardContent"
          style={{
            maxWidth: "992px",
            paddingLeft: "68px",
            paddingRight: "68px",
            border: "unset",
            marginTop: "0px",
            paddingTop: "0px",
          }}
        >
          <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
            <Row>
              <Col span={24}>
                <div className="mainTitle">
                  <h3> {roleData?.details?.name} </h3>
                </div>
              </Col>
              <Col span={24}>
                <SelectField
                  fieldName="jobs"
                  label="Jobs"
                  control={control}
                  selectOption={dropdownData?.jobs}
                  mode="multiple"
                  iProps={{
                    placeholder: "Select one or multiple jobs",
                    suffixIcon: <DropdownIcon />,
                  }}
                />
              </Col>
              <Col span={24}>
                <h4
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    Role purpose statement <span className="red_color">*</span>
                  </div>
                  <div>
                    <h4 style={{ display: "flex", gap: "6px" }}>
                      <Image
                        src="/images/MagicWand.svg"
                        alt="Generate with AI"
                        width={16}
                        height={16}
                        preview={false}
                      />
                      Generate with AI
                    </h4>
                  </div>
                </h4>
                <TextEditor
                  fieldName="purpose_statement"
                  control={control}
                  initValue=""
                  label=""
                  iProps={{
                    placeholder: "Explain the Role purpose statement",
                  }}
                  isRequired
                  rules={{
                    required: "Job name is required.",
                  }}
                  validate={errors.purpose_statement && "error"}
                  validMessage={
                    errors.purpose_statement && errors.purpose_statement.message
                  }
                />
              </Col>
              <Col span={24}>
                <h4
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    Objectives <span className="red_color">*</span>
                  </div>
                  <div>
                    <h4>
                      <Checkbox style={{ marginRight: "4px" }} /> N/A
                    </h4>
                  </div>
                </h4>
                <TextEditor
                  fieldName="objectives"
                  control={control}
                  initValue=""
                  // label="Objectives"
                  // tooltipTitle=""
                  iProps={{
                    placeholder:
                      "Example: Increase market share by developing and implementing effective sales strategies and fostering strong customer relationships.",
                  }}
                  isRequired
                  rules={{
                    required: "Error message",
                  }}
                />
              </Col>
              <Col span={24}>
                <h4 style={{ position: "absolute", right: "0", top: "-2px" }}>
                  <Checkbox style={{ marginRight: "4px" }} /> N/A
                </h4>

                <SelectField
                  fieldName="key_results"
                  label="Key Results"
                  control={control}
                  selectOption={arrayDropdown("KEY_RESULT")}
                  className="fp_select"
                  mode="multiple"
                  iProps={{
                    placeholder: "Select key results",
                    suffixIcon: <DropdownIcon />,
                  }}
                />
              </Col>
              <Col flex="0 0 992px">
                <h4
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    Procedures/WIs/Policies <span className="red_color">*</span>
                  </div>
                  <div>
                    <h4>
                      <Checkbox style={{ marginRight: "4px" }} /> N/A
                    </h4>
                  </div>
                </h4>
                <TextEditor
                  fieldName="procedures"
                  control={control}
                  initValue=""
                  // label="Procedures"
                  // tooltipTitle=""
                  iProps={{
                    placeholder:
                      "Example: Develop detailed project schedules with tasks, Establish milestones and deliverable dates, Allocate necessary resources.",
                  }}
                  isRequired
                  rules={{
                    required: "Error message",
                  }}
                />
              </Col>
              <Col flex="0 0 992px">
                <h4 style={{ position: "absolute", right: "0", top: "-2px" }}>
                  <Checkbox style={{ marginRight: "4px" }} /> N/A
                </h4>

                <SelectField
                  fieldName="workflows"
                  label="Workflows"
                  control={control}
                  selectOption={optionsForDropdown}
                  mode="multiple"
                  iProps={{
                    placeholder: "Select workflows",
                    suffixIcon: <DropdownIcon />,
                  }}
                />
              </Col>
              <Col flex="0 0 992px">
                <h4 style={{ position: "absolute", right: "0", top: "-2px" }}>
                  <Checkbox style={{ marginRight: "4px" }} /> N/A
                </h4>

                <SelectField
                  fieldName="skills"
                  label="Skills"
                  control={control}
                  selectOption={arrayDropdown("SKILL")}
                  mode="multiple"
                  iProps={{
                    placeholder: "Select skills",
                    suffixIcon: <DropdownIcon />,
                  }}
                />
              </Col>
              <Col flex="0 0 992px">
                <h4 style={{ position: "absolute", right: "0", top: "-2px" }}>
                  <Checkbox style={{ marginRight: "4px" }} /> N/A
                </h4>

                <SelectField
                  fieldName="technologies"
                  label="Technologies"
                  control={control}
                  selectOption={arrayDropdown("TECHNOLOGIES")}
                  mode="multiple"
                  iProps={{
                    placeholder: "Select Technologies",
                    suffixIcon: <DropdownIcon />,
                  }}
                />
              </Col>
              <Col flex="0 0 992px">
                <h4 style={{ position: "absolute", right: "0", top: "-2px" }}>
                  <Checkbox style={{ marginRight: "4px" }} /> N/A
                </h4>

                <SelectField
                  fieldName="teams"
                  label="Teams"
                  control={control}
                  selectOption={arrayDropdown("TEAMS")}
                  mode="multiple"
                  iProps={{
                    placeholder: "Select teams",
                    suffixIcon: <DropdownIcon />,
                  }}
                />
              </Col>
              <Col flex="0 0 992px">
                <h4
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    Entities <span className="red_color">*</span>
                  </div>
                  <h4>
                    <Checkbox style={{ marginRight: "4px" }} /> N/A
                  </h4>
                </h4>
                <TextEditor
                  fieldName="entities"
                  control={control}
                  initValue=""
                  // label="Entities"
                  // tooltipTitle=""
                  iProps={{
                    placeholder:
                      "Example: Parents, Vendors, Hiring Coordinator",
                  }}
                  isRequired
                  rules={{
                    required: "Error message",
                  }}
                />
              </Col>
              <Col flex="0 0 992px">
                <h4 style={{ position: "absolute", right: "0", top: "-2px" }}>
                  <Checkbox style={{ marginRight: "4px" }} /> N/A
                </h4>

                <SelectField
                  fieldName="meetings"
                  label="Meetings"
                  control={control}
                  selectOption={arrayDropdown("MEETINGS")}
                  mode="multiple"
                  iProps={{
                    placeholder: "Select meetings",
                    suffixIcon: <DropdownIcon />,
                  }}
                />
              </Col>
            </Row>
            <Row
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Col flex="0 0 395px">
                <h4 style={{ position: "absolute", right: "0", top: "-2px" }}>
                  <Checkbox style={{ marginRight: "4px" }} /> N/A
                </h4>

                <SelectField
                  fieldName="coached_by"
                  label="Coached by"
                  control={control}
                  selectOption={dropdownData?.roles}
                  iProps={{
                    placeholder: "Coached by",
                    suffixIcon: <DropdownIcon />,
                  }}
                />
              </Col>
              <Col flex="0 0 395px">
                <SelectField
                  fieldName="department"
                  label="Department"
                  control={control}
                  selectOption={arrayDropdown("DEPARTMENT")}
                  iProps={{
                    placeholder: "Select department",
                    suffixIcon: <DropdownIcon />,
                  }}
                />
              </Col>
              <Col
                className="fp_footer"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <div>
                  <Button
                    htmlType="submit"
                    type="primary"
                    className="icon_dark_btn"
                  >
                    Update Role
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </Card>
    </FullPageLayout>
  );
}
