import { Fragment, useState } from "react";
import { Row, Col, Button, Tag, Card, Badge, Avatar } from "antd";
import { ToggleDownIcon, ToggleUpIcon } from "../../../../atoms/CustomIcons";
import { JobDetailType } from "../../../../organisms/MyJob/ducks/types";

type PropsType = {
  jobDetailData: JobDetailType;
};

export default function JobSummary(props: PropsType) {
  const { jobDetailData } = props;
  const [showFullInfo, setShowFullInfo] = useState(false);
  const [isApproved, setIsApproved] = useState(true);

  const toggleStatus = () => {
    setIsApproved(!isApproved);
  };

  const createMarkup = (text: string) => {
    return { __html: text };
  };

  return (
    <Card className="my_job">
      <div className="my_job_summary">
        <h3>Job detail summary</h3>
        <Button
          className="toggle_info"
          onClick={() => setShowFullInfo(!showFullInfo)}
        >
          {showFullInfo ? (
            <>
              Hide info <ToggleUpIcon />
            </>
          ) : (
            <>
              View full info <ToggleDownIcon />
            </>
          )}
        </Button>
      </div>

      <Row className="job_summary_thumbs">
        <Col flex="0 0 37%">
          <div style={{ marginBottom: "24px" }}>
            <h4>Purpose statement</h4>
            <div
              dangerouslySetInnerHTML={createMarkup(
                jobDetailData?.purpose_statement as string
              )}
            />
          </div>

          {showFullInfo && (
            <div className="show_full_info">
              <div className="full_info_wrap">
                <h4> Key results </h4>
                <div className="large_tags">
                  {jobDetailData?.key_results?.length > 0
                    ? jobDetailData?.key_results?.map(
                        (e: string, i: number) => (
                          <Fragment key={i}>
                            <Tag>{e}</Tag>
                          </Fragment>
                        )
                      )
                    : "N/A"}
                </div>
              </div>

              <div className="full_info_wrap">
                <h4>Workflows</h4>
                <div className="large_tags">
                  {jobDetailData?.workflows?.length > 0
                    ? jobDetailData?.workflows?.map((e: string, i: number) => (
                        <Fragment key={i}>
                          <Tag>{e}</Tag>
                        </Fragment>
                      ))
                    : "N/A"}
                </div>
              </div>

              <div className="full_info_wrap">
                <h4>Skills</h4>
                <div className="large_tags">
                  {jobDetailData?.skills?.length > 0
                    ? jobDetailData?.skills?.map((e: string, i: number) => (
                        <Fragment key={i}>
                          <Tag>{e}</Tag>
                        </Fragment>
                      ))
                    : "N/A"}
                </div>
              </div>

              <div className="full_info_wrap">
                <h4>Teams</h4>
                <div className="large_tags">
                  {jobDetailData?.teams?.length > 0
                    ? jobDetailData?.teams?.map((e: string, i: number) => (
                        <Fragment key={i}>
                          <Tag>{e}</Tag>
                        </Fragment>
                      ))
                    : "N/A"}
                </div>
              </div>
            </div>
          )}
        </Col>
        <Col flex="0 0 37%">
          <div style={{ marginBottom: "24px" }}>
            <h4>Objectives</h4>
            <div
              dangerouslySetInnerHTML={createMarkup(
                jobDetailData?.objectives as string
              )}
            />
          </div>

          {showFullInfo && (
            <div className="show_full_info">
              <div className="full_info_wrap">
                <h4>Entities</h4>
                <div
                  dangerouslySetInnerHTML={createMarkup(
                    jobDetailData?.entities as string
                  )}
                />
              </div>

              <div className="full_info_wrap">
                <h4>Procedures/WIs/Policies</h4>
                <div
                  dangerouslySetInnerHTML={createMarkup(
                    jobDetailData?.procedures as string
                  )}
                />
              </div>

              <div className="full_info_wrap">
                <h4>Technologies</h4>
                <div className="large_tags">
                  {jobDetailData?.technologies?.length > 0
                    ? jobDetailData?.technologies?.map(
                        (e: string, i: number) => (
                          <Fragment key={i}>
                            <Tag>{e}</Tag>
                          </Fragment>
                        )
                      )
                    : "N/A"}
                </div>
              </div>

              <div className="full_info_wrap">
                <h4>Meetings</h4>
                <div className="large_tags">
                  {jobDetailData?.meetings?.length > 0
                    ? jobDetailData?.meetings?.map((e: string, i: number) => (
                        <Fragment key={i}>
                          <Tag>{e}</Tag>
                        </Fragment>
                      ))
                    : "N/A"}
                </div>
              </div>
            </div>
          )}
        </Col>
        <Col flex="0 0 23%">
          <div style={{ height: "225px" }}>
            <h4>Status</h4>
            <div className="status_column">
              <Button
                className={`current_status ${
                  isApproved ? "approved" : "completed"
                }`}
              >
                {isApproved ? (
                  <Badge className="green_badge" />
                ) : (
                  <Badge className="blue_badge" />
                )}
                {isApproved ? "Approved" : "Completed"}
              </Button>
              <Button onClick={toggleStatus} className="statusUpdateBtn">
                {isApproved ? <ToggleDownIcon /> : <ToggleUpIcon />}
              </Button>
            </div>

            <div
              className="card_role"
              style={{
                width: "50%",
                backgroundColor: "#fff",
                border: "unset",
                marginBottom: "28px",
              }}
            >
              <p className="role_title">Reports to</p>
              <div
                className="role_name"
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                Project owner <img src="/images/visit_icon.svg" />
                <div>
                  <Avatar.Group
                    className="avatarGroup"
                    size="small"
                    max={{
                      count: 3,
                      style: {
                        color: "#161616",
                        backgroundColor: "#fff",
                        cursor: "pointer",
                        fontSize: "10px",
                        fontWeight: "500",
                      },
                    }}
                  >
                    <Avatar>AR</Avatar>
                    <Avatar src="/images/avatar_1.jpg" />
                    <Avatar>SN</Avatar>
                  </Avatar.Group>
                </div>
              </div>
            </div>
            {showFullInfo && (
              <div
                className="card_role"
                style={{
                  width: "100%",
                  backgroundColor: "#fff",
                  border: "unset",
                }}
              >
                <p className="role_title">Mentored by</p>
                <div
                  className="role_name"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "8px",
                    marginBottom: "28px",
                  }}
                >
                  <div>
                    <Avatar.Group
                      className="avatarGroup"
                      size="small"
                      max={{
                        count: 1,
                        style: {
                          color: "#161616",
                          backgroundColor: "#fff",
                          cursor: "pointer",
                          fontSize: "10px",
                          fontWeight: "500",
                        },
                      }}
                    >
                      <Avatar>MB</Avatar>
                    </Avatar.Group>
                  </div>
                  Mike B.
                  <span className="ant-tag defaultTag white_tag">
                    Project Owner
                  </span>
                </div>
                <p className="role_title">Last updated</p>
                <p style={{ fontSize: "14px", fontWeight: "600" }}>
                  Jun 12, 2024
                </p>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Card>
  );
}
