import { Avatar, Tag } from "antd";
import { JobDetailType } from "../../../../organisms/MyJob/ducks/types";

type PropsType = {
  jobDetailData: JobDetailType;
};

export default function TableView(props: PropsType) {
  const { jobDetailData } = props;
  return (
    <div className="job_roles_table">
      <div className="table_inner">
        <div className="table_header">
          <div className="top_role">{jobDetailData?.name}</div>
          <div className="header_cols">
            <div className="header_label">
              <div
                className="role_color"
                style={{ background: "rgb(63, 140, 178)" }}
              >
                {" "}
              </div>
              Leadership Training
            </div>
          </div>
          <div className="header_cols">
            <div className="header_label">
              <div
                className="role_color"
                style={{ background: "rgb(71, 209, 209)" }}
              >
                {" "}
              </div>
              LMA GM's
            </div>
          </div>
          <div className="header_cols">
            <div className="header_label">
              <div
                className="role_color"
                style={{ background: "rgb(149, 128, 255)" }}
              >
                {" "}
              </div>
              School Presence
            </div>
          </div>
          <div className="header_cols">
            <div className="header_label">
              <div
                className="role_color"
                style={{ background: "rgb(149, 128, 255)" }}
              >
                {" "}
              </div>
              Interim GM
            </div>
          </div>
          <div className="header_cols">
            <div className="header_label">
              <div
                className="role_color"
                style={{ background: "rgb(149, 128, 255)" }}
              >
                {" "}
              </div>
              Continued Educations
            </div>
          </div>
          <div className="header_cols">
            <div className="header_label">
              <div
                className="role_color"
                style={{ background: "rgb(149, 128, 255)" }}
              >
                {" "}
              </div>
              Cross Check/Data collection
            </div>
          </div>
          <div className="header_cols">
            <div className="header_label">
              <div
                className="role_color"
                style={{ background: "rgb(149, 128, 255)" }}
              >
                {" "}
              </div>
              Hiring
            </div>
          </div>
          <div className="header_cols">
            <div className="header_label">
              <div
                className="role_color"
                style={{ background: "rgb(149, 128, 255)" }}
              >
                {" "}
              </div>
              Drive Performance
            </div>
          </div>
        </div>
        <div className="table_body">
          <div className="body_inner">
            <div className="table_rows">
              <div className="rows_head">
                <div className="ant-typography">Purpose statement</div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  The purpose of the Leadership Training role is to achieve
                  organizational growth and improved team performance by
                  developing leadership skills, providing mentorship, conducting
                  training programs, and fostering a culture of continuous
                  improvement and innovation.
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  The purpose of the LMA GM's role is to achieve business
                  success and operational excellence by overseeing daily
                  operations, implementing strategic initiatives, managing team
                  performance, ensuring customer satisfaction, and driving
                  continuous improvement.
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  The purpose of the School Presence role is to achieve a
                  positive and supportive school environment by fostering
                  student engagement, promoting school activities, building
                  strong relationships with parents and the community, and
                  ensuring a safe and inclusive atmosphere for all s...
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  The purpose of the Interim GM role is to achieve stability and
                  continuity in operations by overseeing daily business
                  activities, managing team performance, implementing strategic
                  plans, addressing immediate challenges, and ensuring a smooth
                  transition to p...
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  The purpose of the Continued Education role is to achieve
                  lifelong learning and professional development by designing
                  and delivering educational programs, assessing learning needs,
                  providing resources and support, and promoting a culture of
                  continuous improvement and kn...
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  The purpose of the Leadership Training role is to achieve
                  organizational growth and improved team performance by
                  developing leadership skills, providing mentorship, conducting
                  training programs, and fostering a culture of continuous
                  improvement and innovation.
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  The purpose of the Cross Check/Data Collection role is to
                  achieve accurate and reliable data insights by gathering,
                  verifying, and analyzing data, maintaining data integrity, and
                  ensuring consistency across various data sources.
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  The purpose of the Cross Check/Data Collection role is to
                  achieve accurate and reliable data insights by gathering,
                  verifying, and analyzing data, maintaining data integrity, and
                  ensuring consistency across various data sources.
                </div>
              </div>
            </div>

            <div className="table_rows">
              <div className="rows_head">
                <div className="ant-typography">Objectives</div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>
                      Following an extensive outline with supporting documents
                    </li>
                    <li>Engaging in role-playing exercises</li>
                    <li>Offering ongoing coaching</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <p> Same as job </p>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Engaging in role-playing exercises</li>
                    <li>Offering ongoing coaching</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>
                      Following an extensive outline with supporting documents
                    </li>
                    <li>Offering ongoing coaching</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Engaging in role-playing exercises</li>
                    <li>Offering ongoing coaching</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Engaging in role-playing exercises</li>
                    <li>Offering ongoing coaching</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>
                      Following an extensive outline with supporting documents
                    </li>
                    <li>Engaging in role-playing exercises</li>
                    <li>Offering ongoing coaching</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>
                      Following an extensive outline with supporting documents
                    </li>
                    <li>Engaging in role-playing exercises</li>
                    <li>Offering ongoing coaching</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="table_rows">
              <div className="rows_head">
                <div className="ant-typography">Key results</div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Knowledgable and independent GM's</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Knowledgable and independent GM's</li>
                    <li>Knowledgable and independent GM's</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>NPS</li>
                    <li>Tenured Staff</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Knowledgable and independent GM's</li>
                    <li>Knowledgable and independent GM's</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Knowledgable and independent GM's</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Knowledgable and independent GM's</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Knowledgable and independent GM's</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Knowledgable and independent GM's</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="table_rows">
              <div className="rows_head">
                <div className="ant-typography">Workflows</div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">
                    Initiation and Scope Definition
                  </Tag>
                  <Tag className="ant-tag defaultTag white_tag">
                    Project Planning Workflow
                  </Tag>
                  <Tag className="ant-tag defaultTag white_tag">
                    Risk Management Workflow
                  </Tag>
                  <Tag className="ant-tag defaultTag white_tag">
                    Monitoring and Controlling Wor...
                  </Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">Evaluation</Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">
                    Project Planning Workflow
                  </Tag>
                  <Tag className="ant-tag defaultTag white_tag">
                    Risk Management Workflow
                  </Tag>
                  <Tag className="ant-tag defaultTag white_tag">
                    Monitoring and Controlling Wor...
                  </Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">
                    Initiation and Scope Definition
                  </Tag>
                  <Tag className="ant-tag defaultTag white_tag">
                    Project Planning Workflow
                  </Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">
                    Project Planning Workflow
                  </Tag>
                  <Tag className="ant-tag defaultTag white_tag">
                    Risk Management Workflow
                  </Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">
                    Project Planning Workflow
                  </Tag>
                  <Tag className="ant-tag defaultTag white_tag">
                    Risk Management Workflow
                  </Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">
                    Project Planning Workflow
                  </Tag>
                  <Tag className="ant-tag defaultTag white_tag">
                    Risk Management Workflow
                  </Tag>
                </div>
              </div>
            </div>

            <div className="table_rows">
              <div className="rows_head">
                <div className="ant-typography">Procedures/WIs/Policies</div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Hiring</li>
                    <li>Onboarding</li>
                    <li>Training</li>
                    <li>Tracking</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Hiring</li>
                    <li>Onboarding</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Hiring</li>
                    <li>Tracking</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Hiring</li>
                    <li>Onboarding</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Hiring</li>
                    <li>Onboarding</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Hiring</li>
                    <li>Onboarding</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Hiring</li>
                    <li>Onboarding</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="table_rows">
              <div className="rows_head">
                <div className="ant-typography">Entities</div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Community</li>
                    <li>Vendors</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Community</li>
                    <li>Vendors</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Community</li>
                    <li>Vendors</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Community</li>
                    <li>Vendors</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Community</li>
                    <li>Vendors</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Community</li>
                    <li>Vendors</li>
                  </ul>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <ul>
                    <li>Community</li>
                    <li>Vendors</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="table_rows">
              <div className="rows_head">
                <div className="ant-typography">Skills</div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag"> GM</Tag>
                  <Tag className="ant-tag defaultTag white_tag">AM</Tag>
                  <Tag className="ant-tag defaultTag white_tag">OM</Tag>
                  <Tag className="ant-tag defaultTag white_tag">
                    All Frontline Positions
                  </Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag"> GM</Tag>
                  <Tag className="ant-tag defaultTag white_tag">AM</Tag>
                  <Tag className="ant-tag defaultTag white_tag">OM</Tag>
                  <Tag className="ant-tag defaultTag white_tag">
                    All Frontline Positions
                  </Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag"> GM</Tag>
                  <Tag className="ant-tag defaultTag white_tag">AM</Tag>
                  <Tag className="ant-tag defaultTag white_tag">OM</Tag>
                  <Tag className="ant-tag defaultTag white_tag">
                    All Frontline Positions
                  </Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag"> GM</Tag>
                  <Tag className="ant-tag defaultTag white_tag">AM</Tag>
                  <Tag className="ant-tag defaultTag white_tag">OM</Tag>
                  <Tag className="ant-tag defaultTag white_tag">
                    All Frontline Positions
                  </Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag"> GM</Tag>
                  <Tag className="ant-tag defaultTag white_tag">AM</Tag>
                  <Tag className="ant-tag defaultTag white_tag">OM</Tag>
                  <Tag className="ant-tag defaultTag white_tag">
                    All Frontline Positions
                  </Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag"> GM</Tag>
                  <Tag className="ant-tag defaultTag white_tag">AM</Tag>
                  <Tag className="ant-tag defaultTag white_tag">OM</Tag>
                  <Tag className="ant-tag defaultTag white_tag">
                    All Frontline Positions
                  </Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag"> GM</Tag>
                  <Tag className="ant-tag defaultTag white_tag">AM</Tag>
                  <Tag className="ant-tag defaultTag white_tag">OM</Tag>
                  <Tag className="ant-tag defaultTag white_tag">
                    All Frontline Positions
                  </Tag>
                </div>
              </div>
            </div>

            <div className="table_rows">
              <div className="rows_head">
                <div className="ant-typography">Technologies</div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">
                    Google Drive
                  </Tag>
                  <Tag className="ant-tag defaultTag white_tag">Pike13</Tag>
                  <Tag className="ant-tag defaultTag white_tag">ATU</Tag>
                  <Tag className="ant-tag defaultTag white_tag">HUB</Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">
                    Google Drive
                  </Tag>
                  <Tag className="ant-tag defaultTag white_tag">Pike13</Tag>
                  <Tag className="ant-tag defaultTag white_tag">ATU</Tag>
                  <Tag className="ant-tag defaultTag white_tag">HUB</Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">
                    Google Drive
                  </Tag>
                  <Tag className="ant-tag defaultTag white_tag">Pike13</Tag>
                  <Tag className="ant-tag defaultTag white_tag">ATU</Tag>
                  <Tag className="ant-tag defaultTag white_tag">HUB</Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">
                    Google Drive
                  </Tag>
                  <Tag className="ant-tag defaultTag white_tag">Pike13</Tag>
                  <Tag className="ant-tag defaultTag white_tag">ATU</Tag>
                  <Tag className="ant-tag defaultTag white_tag">HUB</Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">
                    Google Drive
                  </Tag>
                  <Tag className="ant-tag defaultTag white_tag">Pike13</Tag>
                  <Tag className="ant-tag defaultTag white_tag">ATU</Tag>
                  <Tag className="ant-tag defaultTag white_tag">HUB</Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">
                    Google Drive
                  </Tag>
                  <Tag className="ant-tag defaultTag white_tag">Pike13</Tag>
                  <Tag className="ant-tag defaultTag white_tag">ATU</Tag>
                  <Tag className="ant-tag defaultTag white_tag">HUB</Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">
                    Google Drive
                  </Tag>
                  <Tag className="ant-tag defaultTag white_tag">Pike13</Tag>
                  <Tag className="ant-tag defaultTag white_tag">ATU</Tag>
                  <Tag className="ant-tag defaultTag white_tag">HUB</Tag>
                </div>
              </div>
            </div>

            <div className="table_rows">
              <div className="rows_head">
                <div className="ant-typography">Teams</div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">
                    GM All Call{" "}
                  </Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">
                    GM All Call{" "}
                  </Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">
                    GM All Call{" "}
                  </Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">
                    GM All Call{" "}
                  </Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">
                    GM All Call{" "}
                  </Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">
                    GM All Call{" "}
                  </Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">
                    GM All Call{" "}
                  </Tag>
                </div>
              </div>
            </div>

            <div className="table_rows">
              <div className="rows_head">
                <div className="ant-typography">Meetings</div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">Hiring</Tag>
                  <Tag className="ant-tag defaultTag white_tag">Ops</Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">Hiring</Tag>
                  <Tag className="ant-tag defaultTag white_tag">Ops</Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">Hiring</Tag>
                  <Tag className="ant-tag defaultTag white_tag">Ops</Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">Hiring</Tag>
                  <Tag className="ant-tag defaultTag white_tag">Ops</Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">Hiring</Tag>
                  <Tag className="ant-tag defaultTag white_tag">Ops</Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">Hiring</Tag>
                  <Tag className="ant-tag defaultTag white_tag">Ops</Tag>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <Tag className="ant-tag defaultTag white_tag">Hiring</Tag>
                  <Tag className="ant-tag defaultTag white_tag">Ops</Tag>
                </div>
              </div>
            </div>

            <div className="table_rows">
              <div className="rows_head">
                <div className="ant-typography">Department</div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">Sales</div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">Sales</div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">Sales</div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">Sales</div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">Sales</div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">Sales</div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">Sales</div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">Sales</div>
              </div>
            </div>

            <div className="table_rows">
              <div className="rows_head">
                <div className="ant-typography">Coached By</div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <div className="coach_avatar">
                    <div className="coach_text">Executive coach</div>
                    <div className="coach_avatar">
                      <Avatar.Group
                        className="avatarGroup"
                        size="small"
                        max={{
                          count: 3,
                          style: {
                            color: "#161616",
                            backgroundColor: "#fff",
                            cursor: "pointer",
                            fontSize: "10px",
                            fontWeight: "500",
                          },
                        }}
                      >
                        <Avatar>AR</Avatar>
                        <Avatar src="/images/avatar_1.jpg" />
                        <Avatar>SN</Avatar>
                      </Avatar.Group>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <div className="coach_avatar">
                    <div className="coach_text">Executive coach</div>
                    <div className="coach_avatar">
                      <Avatar.Group
                        className="avatarGroup"
                        size="small"
                        max={{
                          count: 3,
                          style: {
                            color: "#161616",
                            backgroundColor: "#fff",
                            cursor: "pointer",
                            fontSize: "10px",
                            fontWeight: "500",
                          },
                        }}
                      >
                        <Avatar>AR</Avatar>
                        <Avatar src="/images/avatar_1.jpg" />
                        <Avatar>SN</Avatar>
                      </Avatar.Group>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <div className="coach_avatar">
                    <div className="coach_text">Executive coach</div>
                    <div className="coach_avatar">
                      <Avatar.Group
                        className="avatarGroup"
                        size="small"
                        max={{
                          count: 3,
                          style: {
                            color: "#161616",
                            backgroundColor: "#fff",
                            cursor: "pointer",
                            fontSize: "10px",
                            fontWeight: "500",
                          },
                        }}
                      >
                        <Avatar>AR</Avatar>
                        <Avatar src="/images/avatar_1.jpg" />
                        <Avatar>SN</Avatar>
                      </Avatar.Group>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <div className="coach_avatar">
                    <div className="coach_text">Executive coach</div>
                    <div className="coach_avatar">
                      <Avatar.Group
                        className="avatarGroup"
                        size="small"
                        max={{
                          count: 3,
                          style: {
                            color: "#161616",
                            backgroundColor: "#fff",
                            cursor: "pointer",
                            fontSize: "10px",
                            fontWeight: "500",
                          },
                        }}
                      >
                        <Avatar>AR</Avatar>
                        <Avatar src="/images/avatar_1.jpg" />
                        <Avatar>SN</Avatar>
                      </Avatar.Group>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <div className="coach_avatar">
                    <div className="coach_text">Executive coach</div>
                    <div className="coach_avatar">
                      <Avatar.Group
                        className="avatarGroup"
                        size="small"
                        max={{
                          count: 3,
                          style: {
                            color: "#161616",
                            backgroundColor: "#fff",
                            cursor: "pointer",
                            fontSize: "10px",
                            fontWeight: "500",
                          },
                        }}
                      >
                        <Avatar>AR</Avatar>
                        <Avatar src="/images/avatar_1.jpg" />
                        <Avatar>SN</Avatar>
                      </Avatar.Group>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <div className="coach_avatar">
                    <div className="coach_text">Executive coach</div>
                    <div className="coach_avatar">
                      <Avatar.Group
                        className="avatarGroup"
                        size="small"
                        max={{
                          count: 3,
                          style: {
                            color: "#161616",
                            backgroundColor: "#fff",
                            cursor: "pointer",
                            fontSize: "10px",
                            fontWeight: "500",
                          },
                        }}
                      >
                        <Avatar>AR</Avatar>
                        <Avatar src="/images/avatar_1.jpg" />
                        <Avatar>SN</Avatar>
                      </Avatar.Group>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rows_cols">
                <div className="rows_para">
                  <div className="coach_avatar">
                    <div className="coach_text">Executive coach</div>
                    <div className="coach_avatar">
                      <Avatar.Group
                        className="avatarGroup"
                        size="small"
                        max={{
                          count: 3,
                          style: {
                            color: "#161616",
                            backgroundColor: "#fff",
                            cursor: "pointer",
                            fontSize: "10px",
                            fontWeight: "500",
                          },
                        }}
                      >
                        <Avatar>AR</Avatar>
                        <Avatar src="/images/avatar_1.jpg" />
                        <Avatar>SN</Avatar>
                      </Avatar.Group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
