import { BASE_URLS, ENVIRONMENT_MODE } from "../../../config/constants";
import { AxiosResponse } from "axios";
import axios from "../../../config/axiosInterceptor";
import {
  CreateJob,
  CreateRole,
  CreateUserType,
  UpdateJob,
  UpdateRole,
  UpdateUserType,
} from "./types";

const baseURL = BASE_URLS[ENVIRONMENT_MODE].REACT_APP_API_BASE_URL;

export const createJobByCompanyId = (
  payload: CreateJob
): Promise<AxiosResponse<{ message: string; success: boolean }>> => {
  return axios.post(`${baseURL}createJobByCompanyId`, payload);
};

export const createRole = (
  payload: CreateRole
): Promise<AxiosResponse<{ message: string; success: boolean }>> => {
  return axios.post(`${baseURL}createRole`, payload);
};

export const updateRoleById = (
  payload: UpdateRole,
  roleId: string
): Promise<AxiosResponse<{ message: string; success: boolean }>> => {
  return axios.patch(`${baseURL}updateRoleById/${roleId}`, payload);
};

export const updateJobById = (
  payload: UpdateJob,
  jobId: string
): Promise<AxiosResponse<{ message: string; success: boolean }>> => {
  return axios.patch(`${baseURL}updateJobById/${jobId}`, payload);
};

export const createUser = (
  payload: CreateUserType
): Promise<AxiosResponse<{ message: string; success: boolean }>> => {
  return axios.post(`${baseURL}createUser`, payload);
};

export const updateEmployeeById = (
  payload: UpdateUserType,
  id: string
): Promise<AxiosResponse<{ message: string; success: boolean }>> => {
  return axios.patch(`${baseURL}updateEmployeeById/${id}`, payload);
};

export const addExistingRolesToJob = (payload: {
  job_id: string;
  roles: string[];
}): Promise<AxiosResponse<{ message: string; success: boolean }>> => {
  return axios.post(`${baseURL}addExistingRolesToJob`, payload);
};

export const deleteJob = (jobId: string) => {
  return axios.delete(`${baseURL}deleteJob/${jobId}`);
};

export const deleteRole = (jobId: string) => {
  return axios.delete(`${baseURL}deleteRole/${jobId}`);
};

export const deleteUserById = (empId: string) => {
  return axios.delete(`${baseURL}deleteUserById/${empId}`);
};
