import { Button, Image, Spin, Upload } from "antd";
import { useEffect, useCallback } from "react";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import Dragger from "antd/lib/upload/Dragger";
import ErrorMessage from "../Toasts/ErrorMessage";

const antIcon = <Loading3QuartersOutlined spin />;

export default (props: any) => {
  const {
    onClose,
    title,
    loading,
    checklistFile,
    modalPreviewImage,
    setChecklistFile,
    uploadedImageURL,
    setPreviewImage,
    setModalPreviewImage,
    saveUpload,
    imageUploaded,
  } = props;

  const handleFileChange = useCallback((e: any) => {
    setChecklistFile(e);
    const isIMG = e.type === "image/png" || e.type === "image/jpeg";
    if (!isIMG) {
      ErrorMessage(`${e.name} is not a image file`);
    } else {
      const reader = new FileReader();
      const file = e;
      reader.onloadend = () => {
        setModalPreviewImage({
          file,
          imagePreviewUrl: reader.result,
        });
      };
      reader.readAsDataURL(file);
    }
    return isIMG || Upload.LIST_IGNORE;
  }, []);

  const onCloseHandler = () => {
    setModalPreviewImage({
      file: "",
      imagePreviewUrl: null,
    });
    setPreviewImage({
      file: "",
      imagePreviewUrl: null,
    });
    setChecklistFile({});
    onClose();
  };

  useEffect(() => {
    if (uploadedImageURL) {
      setModalPreviewImage({
        imagePreviewUrl: uploadedImageURL,
      });
    }
  }, [uploadedImageURL]);

  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <div className="newDailyChecklistForm photoUploader eTableModal">
        <div className="modal_header">
          <div className="title"> {title} </div>
          <div className="close_icon">
            <Button
              size="large"
              type="link"
              className="p-0 m-20 close_popup"
              onClick={() => onCloseHandler()}
            >
              <Image src="/images/close-icon.svg" preview={false} width={10} />
            </Button>
          </div>
        </div>
        <div className="modal_content">
          <Dragger
            showUploadList={false}
            fileList={[]}
            name="file"
            beforeUpload={handleFileChange}
            customRequest={() => null}
            maxCount={1}
            accept="image/png, image/jpeg"
          >
            {imageUploaded || checklistFile?.name ? (
              <div className="upload_modal_preview">
                <div className="photo_wrapper">
                  <Image
                    src={modalPreviewImage?.imagePreviewUrl}
                    preview={false}
                    width={172}
                    height={172}
                  />
                  {checklistFile?.name && (
                    <Button
                      className="delete_photo"
                      onClick={(e) => {
                        e.stopPropagation();
                        setChecklistFile({});
                        setPreviewImage({
                          file: "",
                          imagePreviewUrl: null,
                        });
                        setModalPreviewImage({
                          file: "",
                          imagePreviewUrl: null,
                        });
                        if (uploadedImageURL) {
                          setModalPreviewImage({
                            imagePreviewUrl: uploadedImageURL,
                          });
                        }
                      }}
                    >
                      <Image src="/images/delete-photo.svg" preview={false} />
                    </Button>
                  )}
                </div>
              </div>
            ) : (
              <>
                <p className="ant-upload-drag-icon">
                  <Image
                    src="/images/upload-icon.svg"
                    preview={false}
                    style={{ height: "40px", width: "40px" }}
                  />
                </p>
                <p className="ant-upload-text">
                  {" "}
                  Drag & drop or{" "}
                  <span style={{ color: "#A25EDC" }}>browse</span> to choose a
                  photo.{" "}
                </p>

                <p className="ant-upload-hint"> JPG, PNG, SVG. 2 Mb max. </p>
              </>
            )}
          </Dragger>
        </div>
        <div className="modal_footer">
          <Button
            htmlType="button"
            className="cancelBtn defaultBtnColor"
            type="default"
            size="large"
            onClick={() => onCloseHandler()}
          >
            Cancel
          </Button>

          <Button
            disabled={!checklistFile?.name}
            htmlType="button"
            className="icon_dark_btn"
            type="primary"
            size="large"
            onClick={saveUpload}
          >
            Save Photo
          </Button>
        </div>
      </div>
    </Spin>
  );
};
