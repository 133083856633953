import { useState } from "react";
import { Row, Col, Card, Tag, Breadcrumb, Image, Badge } from "antd";
import { useLocation } from "react-router-dom";
import DetailTop from "./Components/DetailTop";
import JobSummary from "./Components/JobSummary";
import JobRoles from "./Components/JobRoles";
import { JobDetailType } from "../../organisms/MyJob/ducks/types";
import EmptySessionCard from "../../molecules/EmptySessionCard";
import { Popup } from "../Popup";
import SelectParticipants from "./Components/SelectParticipants";
import { ArrowIconRight } from "../CustomIcons";

type PropsType = {
  jobDetailData: JobDetailType;
};

export default function JobDetails(props: PropsType) {
  const { jobDetailData } = props;
  const [selectParticipant, showSelectParticipant] = useState(false);
  const [selectWSParticipant, showSelectWSParticipant] = useState(false);
  const url = useLocation();
  const jobId = url?.pathname.split("/")[2];
  const companyId = url?.pathname.split("/")[3];

  console.log("jobDetailData", jobDetailData);

  const participantPopUp = {
    visibility: selectParticipant,
    class: "delete_confirmation_modal",
    content: (
      <SelectParticipants
        title="Start Flower Power session"
        onClose={() => showSelectParticipant(false)}
        btnText="Start session"
        jobId={jobId}
        companyId={companyId}
      />
    ),
    width: 600,
    onCancel: () => showSelectParticipant(false),
  };

  const WSParticipantPopUp = {
    visibility: selectWSParticipant,
    class: "delete_confirmation_modal",
    content: (
      <SelectParticipants
        title="Session participants"
        onClose={() => showSelectWSParticipant(false)}
        btnText="Start session"
        jobId={jobId}
        companyId={companyId}
        paragraph="Here is everyone who participated in the Flower Power session. It's recommended to perform this session with the same people."
      />
    ),
    width: 600,
    onCancel: () => showSelectWSParticipant(false),
  };

  return (
    <>
      <Row className="secondary_header">
        <Col span={24}>
          <Breadcrumb
            separator={<ArrowIconRight />}
            items={[
              {
                href: "/",
                title: (
                  <>
                    <Image
                      src="/images/breadcrumb_rightarrow.svg"
                      preview={false}
                      width={16}
                      height={16}
                      style={{ marginRight: "14px" }}
                    />
                    My company
                  </>
                ),
              },
              {
                title: "Director of Ops",
              },
            ]}
          />
        </Col>
        <Col span={24}>
          <DetailTop
            jobDetailData={jobDetailData}
            jobId={jobId}
            companyId={companyId}
          />
        </Col>
      </Row>
      {jobDetailData?.status === "pending_fp_session" && (
        <Card className="session_card fp_session_area">
          <div className="session_card_top">
            <h3>Job detail summary</h3>
            <div className="job_status_tag">
              <Tag className="defaultTag blue_tag">
                <Badge className="default_badge" />
                Pending FP Session
              </Tag>
            </div>
          </div>
          <EmptySessionCard
            image="/images/empty/session-icon.svg"
            title="Perform Flower Power session"
            description={
              <>
                Gather people who hold this position or have an understanding
                about what the people who do hold this position are thinking
                about
                <br /> and doing. Start the Flower Power session by clicking the
                button below.
              </>
            }
            buttonImage="/images/flower-icon.svg"
            buttonText={
              jobDetailData?.session?.id
                ? "Continue Flower Power session"
                : "Start Flower Power session"
            }
            showSelectParticipant={showSelectParticipant}
            sessionId={jobDetailData?.session?.id}
            jobId={jobId}
            companyId={companyId}
          />
        </Card>
      )}

      {jobDetailData?.status === "pending_ws_session" && (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Card className="session_card ws_session_area">
              <div className="session_card_top">
                <h3>Job detail summary</h3>
                <div className="job_status_tag">
                  <Tag className="defaultTag blue_tag">
                    <Badge className="default_badge" />
                    Pending WS Session
                  </Tag>
                </div>
              </div>
              <EmptySessionCard
                image="/images/empty/Perform_WS_session.svg"
                title="Perform the Worksheet session"
                description={
                  <>
                    Gather everyone who holds this position one more time and
                    start the session by clicking the button below
                  </>
                }
                buttonImage="/images/worksheet_session.svg"
                buttonText="Start worksheet session"
                showSelectParticipant={showSelectWSParticipant}
                jobId={jobId}
                companyId={companyId}
              />
            </Card>
          </Col>

          <Col span={24}>
            <JobRoles jobDetailData={jobDetailData} />
          </Col>
        </Row>
      )}

      {jobDetailData?.status === "pending_approval" && (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <JobSummary jobDetailData={jobDetailData} />
          </Col>

          <Col span={24}>
            <JobRoles jobDetailData={jobDetailData} />
          </Col>
        </Row>
      )}
      <Popup {...participantPopUp} />
      <Popup {...WSParticipantPopUp} />
    </>
  );
}
