import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const PrevSvg = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00117 2.77838C8.33067 3.10789 8.33067 3.64212 8.00116 3.97163L4.28701 7.68573H15.75C16.216 7.68573 16.5938 8.06349 16.5938 8.52948C16.5938 8.99547 16.216 9.37323 15.75 9.37323H4.28697L8.00117 13.0875C8.33067 13.417 8.33067 13.9512 8.00116 14.2807C7.67166 14.6102 7.13742 14.6102 6.80792 14.2807L1.65337 9.12609C1.49514 8.96786 1.40625 8.75325 1.40625 8.52947C1.40625 8.30569 1.49515 8.09108 1.65338 7.93285L6.80793 2.77837C7.13743 2.44887 7.67167 2.44888 8.00117 2.77838Z"
      fill="#070707"
    />
  </svg>
);

const PrevIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={PrevSvg} {...props} />
);

export default PrevIcon;
