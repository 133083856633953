import { useEffect, useState } from "react";
import { Button, Image, Dropdown, Card, Avatar } from "antd";
import { useNavigate } from "react-router-dom";
import {
  ArrowIcon,
  EmployeesIcon,
  JobsIcon,
  RolesIcon,
  EditIcon,
} from "../../../../atoms/CustomIcons";
import { useAppDispatch, useAppSelector } from "../../../../config/hooks";
import { getCompanyDetails } from "../../../CompanySettings/ducks/actions";
import CompanyDelete from "../../../../atoms/CompanyDelete";
import { Popup } from "../../../../atoms/Popup";
import SuccessMessage from "../../../../atoms/Toasts/SuccessMessage";
import { AxiosError } from "axios";
import ErrorMessage from "../../../../atoms/Toasts/ErrorMessage";
import { deleteCompany } from "../../../Companies/ducks/services";
import CreateJob from "../../../../atoms/CreateJob";

const { Meta } = Card;
type PropsType = {
  companyId: string;
  updateApi?: () => void;
};

export default function Header(props: PropsType) {
  const { companyId, updateApi } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [jobCreate, setJobCreate] = useState(false);
  const companyData = useAppSelector((state) => state?.settings?.companyDetail);
  const accountData = useAppSelector((state) => state?.account?.accountData);

  const handleDeleteCompany = async () => {
    setDeleteLoading(true);
    deleteCompany(companyId as string)
      .then(() => {
        SuccessMessage("company deleted.");
        setIsDeleteModalOpen(false);
        setDeleteLoading(false);
        navigate("/companies");
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setDeleteLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setDeleteLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  const deleteConfirmationPopup = {
    visibility: isDeleteModalOpen,
    class: "delete_confirmation_modal",
    content: (
      <CompanyDelete
        title="Confirm Deletion"
        heading="Delete company?"
        paragraph_1="This action will permanently delete all data for this company and cannot be undone."
        paragraph_2="Confirm this operation by typing the company name below."
        onClose={() => setIsDeleteModalOpen(false)}
        btnText="Delete company"
        btnApi={handleDeleteCompany}
        loading={deleteLoading}
        companyName={companyData?.name}
      />
    ),
    width: 520,
    onCancel: () => setIsDeleteModalOpen(false),
  };

  const items = [
    {
      key: "1",
      label: (
        <Button
          className="noBorder"
          style={{ padding: "0px", width: "100%" }}
          onClick={() => navigate(`/company-settings/${companyId}`)}
        >
          Edit
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Button
          onClick={() => setIsDeleteModalOpen(true)}
          className="noBorder"
          style={{ padding: "0px", width: "100%" }}
        >
          Delete company
        </Button>
      ),
    },
  ];

  const categories = [
    {
      key: "1",
      label: (
        <Button onClick={() => setJobCreate(true)} className="noBorder">
          <JobsIcon className="darkIcon" />
          Job
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Button
          onClick={() => navigate(`/create-role/${companyId}`)}
          className="noBorder"
        >
          <RolesIcon className="darkIcon" />
          Role
        </Button>
      ),
    },
    {
      key: "3",
      label: (
        <Button
          onClick={() => navigate(`/add-employees/${companyId}`)}
          className="noBorder"
        >
          <EmployeesIcon className="darkIcon" />
          Employee
        </Button>
      ),
    },
  ];

  useEffect(() => {
    if (companyId) {
      dispatch(getCompanyDetails(companyId));
    }
  }, [dispatch, companyId]);

  const createJob = {
    visibility: jobCreate,
    class: "upload_photo_modal",
    content: (
      <CreateJob
        title="Create Job"
        onClose={() => setJobCreate(false)}
        btnText="Create Job"
        companyId={companyId}
        updateApi={updateApi}
      />
    ),
    width: 600,
    onCancel: () => setJobCreate(false),
  };

  return (
    <div className="fp_main_details">
      <div className="companyInfo">
        <Meta
          avatar={
            companyData?.image?.url ? (
              <Image
                src={companyData?.image?.url}
                width={68}
                height={68}
                preview={false}
              />
            ) : (
              <Avatar size={68} shape="square" />
            )
          }
          title={companyData?.name}
          description={companyData?.location}
          className="headingH2"
        />
      </div>
      <div className="actionBtn">
        <Dropdown menu={{ items: categories }} placement="bottomLeft">
          <Button className="icon_dark_btn">
            Create
            <ArrowIcon className="angleIcon" />
          </Button>
        </Dropdown>
        {accountData?.type === "SUPER_ADMIN" ? (
          <Dropdown
            menu={{ items }}
            trigger={["click"]}
            placement="bottomRight"
            className="actionButton companyDeleteBtn"
          >
            <Button>
              <Image
                src="/images/vertical_dots.svg"
                width={3}
                height={15}
                alt="vertical dots"
                preview={false}
              />
            </Button>
          </Dropdown>
        ) : (
          <Button
            className="icon_grey_btn"
            icon={<EditIcon />}
            onClick={() => navigate(`/company-settings/${companyId}`)}
          >
            Edit company info
          </Button>
        )}
      </div>

      <Popup {...deleteConfirmationPopup} />
      <Popup {...createJob} />
    </div>
  );
}
