import { useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { HolderOutlined } from "@ant-design/icons";
import { Role } from "../../types";
import { Button, Image, Input } from "antd";
import { updateRoleDescriptor } from "../../../../organisms/MyJob/ducks/services";

const { TextArea } = Input;
interface PropsType {
  role: Role;
  deleteRole: (id: string | number) => void;
  updateRole?: (id: string | number, descriptor: string) => void;
  roleToUpdate?: any;
}

export default function RoleCard(props: PropsType) {
  const { role, deleteRole, updateRole, roleToUpdate } = props;
  const [editMode, setEditMode] = useState(false);

  const toggleEditMode = () => {
    if (editMode) {
      const payload = {
        id: roleToUpdate?.id,
        descriptor: roleToUpdate?.descriptor,
      };
      updateRoleDescriptor(payload).then(() => {
        setEditMode(!editMode);
      });
    } else {
      setEditMode(!editMode);
    }
  };

  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: role.id,
    data: {
      type: "Role",
      role,
    },
  });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  if (isDragging) {
    return (
      <div
        className="role_wrapper is_dragging"
        ref={setNodeRef}
        style={style}
      />
    );
  }

  if (editMode) {
    return (
      <div className="role_wrapper">
        <div className="role_dragger" {...listeners} {...attributes}>
          <HolderOutlined />
        </div>
        <div className="role_content">
          <TextArea
            defaultValue={role?.descriptor}
            onBlur={toggleEditMode}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                toggleEditMode();
              }
            }}
            onChange={(e) => updateRole && updateRole(role.id, e.target.value)}
          />
        </div>
        <Button className="role_btn" onClick={() => deleteRole(role.id)}>
          <Image src="/images/close-icon.svg" preview={false} width={8} />
        </Button>
      </div>
    );
  }
  return (
    <div
      ref={setNodeRef}
      style={style}
      className="role_wrapper"
      onClick={toggleEditMode}
    >
      <div className="role_dragger" {...listeners} {...attributes}>
        <HolderOutlined />
      </div>
      <div className="role_content">{role?.descriptor}</div>
      <Button className="role_btn" onClick={() => deleteRole(role.id)}>
        <Image src="/images/close-icon.svg" preview={false} width={8} />
      </Button>
    </div>
  );
}
