import { Tabs } from "antd";
import {
  RoleType,
  SessionTypeResponse,
} from "../../../../../../organisms/MyJob/ducks/types";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../config/hooks";
import { getFlowerDataBySessionID } from "../../../../../../organisms/MyJob/ducks/actions";
import FpSessionCompleted from "../Components/FpSessionCompleted";

type PropsType = {
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  sessionData: SessionTypeResponse;
  sessionId: string;
};

export default function Step10(props: PropsType) {
  const { setCurrent, sessionId, sessionData } = props;
  const [activeTab, setActiveTab] = useState("1");

  const dispatch = useAppDispatch();
  const flowerData = useAppSelector(
    (state) => state?.jobDetail?.sessionFlowerData
  );
  const petalData = useAppSelector((state) => state?.jobDetail?.petalData);

  console.log("flowerData", flowerData);

  useEffect(() => {
    if (sessionId) {
      dispatch(getFlowerDataBySessionID(sessionId));
    }
  }, [sessionId]);

  const onTabChange = (key: string) => {
    setActiveTab(key);
  };

  return (
    <div className="session_flower">
      <Tabs
        activeKey={activeTab}
        onChange={onTabChange}
        destroyInactiveTabPane={true}
        items={flowerData?.sessionPetals?.map((e: RoleType, i: number) => ({
          key: `${i + 1}`,
          label: (
            <div className={`tab-label fp-tabs-${i + 1}`}>
              <span className="tab-number">{i + 1}</span> {e?.name}
            </div>
          ),
          children: (
            <FpSessionCompleted
              data={flowerData?.sessionPetals}
              petalTeams={flowerData?.petalTeams}
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              setCurrent={setCurrent}
              activeData={e}
              activeNumber={i + 1}
              petalData={petalData}
              sessionId={sessionId}
              sessionData={sessionData}
            />
          ),
        }))}
      />
    </div>
  );
}
