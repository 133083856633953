import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const Leaf10 = () => (
  <svg
    width="143"
    height="317"
    viewBox="0 0 143 317"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.925 107.859L71.229 315.164L138.894 108.232C146.573 84.7467 140.523 58.9391 123.205 41.3141L92.3232 9.8838C80.5984 -2.04914 61.3454 -1.97919 49.7077 10.0386L19.3211 41.4173C2.28688 59.0078 -3.63632 84.5693 3.925 107.859Z"
      fill="currentColor"
      stroke="#ECEDF3"
    />
  </svg>
);

const LeafIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={Leaf10} {...props} />
);

export default LeafIcon;
