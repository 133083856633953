import { Image, Spin } from "antd";
import { useEffect } from "react";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { isLoggedIn } from "../../config/utils";
import { useNavigate } from "react-router-dom";

const antIcon = <Loading3QuartersOutlined spin />;

type loginProps = {
  loading: boolean;
  children: React.ReactNode;
};

const LoginTemplate = (props: loginProps) => {
  const { loading, children } = props;
  const auth = isLoggedIn();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth) {
      navigate("/");
    }
  }, [auth, navigate]);

  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <main className="mainLogin">
        <div className="twoColumn">
          <div className="leftLogin">
            <div className="loginWrapper">{children}</div>
          </div>
          <div className="rightLogin">
            <Image
              src="/images/login/illustration.png"
              alt="Login Hero"
              preview={false}
            />
          </div>
        </div>
      </main>
    </Spin>
  );
};

export default LoginTemplate;
