import { Action, Dispatch } from "redux";
import axios from "../../../config/axiosInterceptor";
import { BASE_URLS, ENVIRONMENT_MODE } from "../../../config/constants";
import * as action_types from "./constants";
import { CompanyList } from "./types";

const baseURL = BASE_URLS[ENVIRONMENT_MODE].REACT_APP_API_BASE_URL;

export const getUsersListing = (payload: CompanyList) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const { data } = await axios.post(`${baseURL}getUsersListing`, payload);
      dispatch({
        type: action_types.USERS_LIST,
        data: { ...data, loading: false },
      });
    } catch (error) {
      dispatch({
        type: action_types.USERS_LIST,
        data: { loading: false },
      });
    }
  };
};

export const getEmployeeDetails = (employeeId: string) => {
  return async (dispatch: Dispatch<Action>) => {
    const { data } = await axios.get(
      `${baseURL}getEmployeeDetails/${employeeId}`
    );
    dispatch({
      type: action_types.EMPLOYEE_DETAILS,
      data,
    });
  };
};
