import React, { useCallback, useEffect, useState } from "react";
import DashboardTemplate from "../../templates/Dashboard";
import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Row,
  Table,
  Image,
  TableColumnsType,
  Tag,
  Badge,
} from "antd";
import { PlusIcon, SortIcon } from "../../atoms/CustomIcons";
import { useNavigate } from "react-router-dom";
import EmptyCard from "../../molecules/EmptyCard";
import { Popup } from "../../atoms/Popup";
import ConfirmDelete from "../../atoms/ConfirmDelete";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { getUsersListing } from "./ducks/actions";
import { USER_TYPE } from "../../config/constants";

interface DataType {
  key: React.Key;
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  type: string;
  status: string;
  image: { url: string };
}

export default function SystemSettings() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [pagination, setPagination] = useState(1);
  const [limit, setLimit] = useState(10);
  const [tableRecord, setTableRecord] = useState<DataType>();
  const usersList = useAppSelector((state) => state?.system?.usersList);

  const items = [
    {
      key: "1",
      label: (
        <Button
          className="noBorder"
          style={{ padding: "0px" }}
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/edit-user/${tableRecord?.id}`);
          }}
        >
          Edit details
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Button className="noBorder" style={{ padding: "0px" }}>
          Deactivate
        </Button>
      ),
    },
    {
      key: "3",
      label: (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setIsDeleteModalOpen(true);
          }}
          className="noBorder"
          style={{ padding: "0px" }}
        >
          Delete
        </Button>
      ),
    },
  ];

  const columns: TableColumnsType<DataType> = [
    {
      title: "Name",
      dataIndex: "first_name",
      render: (text, record) => (
        <div className="table_with_image">
          {record?.image?.url ? (
            <Image
              src={record?.image?.url}
              width={28}
              height={28}
              alt="Quote"
              preview={false}
              style={{ borderRadius: "100px" }}
            />
          ) : (
            <Avatar size={28} />
          )}
          <div className="table_text">
            <span className="main_text">{`${text} ${record?.last_name}`}</span>
          </div>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a?.email?.localeCompare(b?.email),
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Access level",
      dataIndex: "type",
      sorter: (a, b) => a?.type?.localeCompare(b?.type),
      sortIcon: () => <SortIcon />,
      render: (text: keyof typeof USER_TYPE) => (text ? USER_TYPE[text] : "-"),
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
      sortIcon: () => <SortIcon />,
      render: (text) => {
        let className = "";
        if (text?.includes("active")) {
          className = "green_tag";
        } else if (text?.includes("deactivated")) {
          className = "grey_tag";
        } else {
          className = "defaultTag";
        }
        return (
          <Tag className={`defaultTag ${className}`}>
            <Badge className={`default_badge green_badge`} /> {text}
          </Tag>
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      width: 52,
      render: (_text, record) => (
        <Dropdown
          menu={{ items }}
          trigger={["click"]}
          placement="bottomRight"
          className="actionButton"
        >
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setTableRecord(record);
            }}
          >
            <Image
              src="/images/vertical_dots.svg"
              width={3}
              height={15}
              alt="vertical dots"
              preview={false}
            />
          </Button>
        </Dropdown>
      ),
    },
  ];

  const handleDeleteCompany = async () => {
    setDeleteLoading(true);
    setTimeout(() => {
      setIsDeleteModalOpen(false);
      setDeleteLoading(false);
    }, 1000);
  };

  const deleteConfirmationPopup = {
    visibility: isDeleteModalOpen,
    class: "delete_confirmation_modal",
    content: (
      <ConfirmDelete
        title="Confirm deletion"
        heading="Delete James George?"
        paragraph_1="This user will be deleted. This action can't be undone."
        // paragraph_2="Confirm this operation by typing the company name below."
        onClose={() => setIsDeleteModalOpen(false)}
        btnText="Delete user"
        btnApi={handleDeleteCompany}
        loading={deleteLoading}
      />
    ),
    width: 520,
    onCancel: () => setIsDeleteModalOpen(false),
  };

  useEffect(() => {
    const payload = {
      offset: pagination > 1 ? limit * (pagination - 1) : pagination - 1,
      limit: limit,
      userType: "SUPER_ADMIN",
    };
    dispatch(getUsersListing(payload));
  }, [pagination, limit, dispatch]);

  const onPageSize = useCallback((currentPage: number, pageSize: number) => {
    setPagination(currentPage);
    setLimit(pageSize);
  }, []);

  const onPageChange = useCallback((pageNumber: number, pageSize: number) => {
    setPagination(pageNumber);
    setLimit(pageSize);
  }, []);

  // const onClickRow = (record: DataType) => {
  //   return {
  //     onClick: () => {
  //       navigate(`/edit-user/${record?.id}`);
  //     },
  //   };
  // };

  return (
    <DashboardTemplate loading={false}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <div className="fp_main_details">
            <div className="companyInfo">
              <h1>System settings </h1>
            </div>
            <div className="actionBtn">
              <Button
                className="icon_dark_btn"
                icon={<PlusIcon />}
                onClick={() => navigate("/add-user")}
              >
                Create user
              </Button>
            </div>
          </div>
        </Col>
        <Col span={24}>
          {usersList?.data?.length > 0 && (
            <Table
              className="dashboard_table"
              columns={columns}
              dataSource={usersList?.data}
              pagination={{
                defaultPageSize: limit,
                total: usersList?.count,
                current: pagination,
                onChange: onPageChange,
                onShowSizeChange: onPageSize,
              }}
              rowKey="id"
              // onRow={onClickRow}
            />
          )}

          {usersList?.data?.length === 0 && (
            <EmptyCard
              image="/images/empty/no-results.svg"
              title="There are no user"
              description='Click the "Create user" button to add a new user.'
              buttonText="Create user"
              navigateTo="/add-user"
            />
          )}
        </Col>
      </Row>

      <Popup {...deleteConfirmationPopup} />
    </DashboardTemplate>
  );
}
